import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { SpinnerService } from '@core/services/spinner.service';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { AdaptedNppSearchResult } from '@core/typings/organization.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { AllowedLocation, Program } from '@core/typings/program.typing';
import { AddOrgUI } from '@core/typings/ui/add-org.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { YcFile } from '@yourcause/common/files';
import { EmailExtensionValidator } from '@yourcause/common/form-control-validation';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
import { AddOrganizationService } from '../add-organization.service';
export interface AddressFormGroup {
  address1: string;
  address2: string;
  countryCode: string;
  city: string;
  stateProvRegCode: string;
  postalCode: string;
}
export interface DetailsFormGroup {
  name: string;
  website: string;
  contactFullName: string;
  contactEmail: string;
  image: YcFile[];
}
export interface LookupFormGroup {
  country: string;
  id: string;
}
export interface ContactFormGroup {
  contactFullName: string;
  contactEmail: string;
  contactWebsite: string;
}

@Component({
  selector: 'gc-add-org-modal',
  templateUrl: './add-org-modal.component.html',
  styleUrls: ['./add-org-modal.component.scss']
})
export class AddOrgModalComponent extends YCModalComponent<{
  selectionType: AddOrgUI.SelectionType;
  searchResult: AdaptedNppSearchResult;
  isInvalidOrg: boolean;
}> implements OnInit {
  @Input() processorType: ProcessingTypes;
  @Input() addingOrg = false;
  @Input() vettingOrg = false;
  @Input() isManager = false;
  @Input() allowedOrgLocations: AllowedLocation[];

  addressFormGroup: TypeSafeFormGroup<AddressFormGroup>;
  detailsFormGroup: TypeSafeFormGroup<DetailsFormGroup>;
  lookupFormGroup: TypeSafeFormGroup<LookupFormGroup>;
  nonprofitContactGroup: TypeSafeFormGroup<ContactFormGroup>;
  cancelText = this.i18n.translate('common:btnCancel', {}, 'Cancel');
  backText = this.i18n.translate('common:btnBack', {}, 'Back');
  primaryDisabled$ = this.addOrgService.changesTo$('primaryDisabled');
  modalHeader$ = this.addOrgService.changesTo$('modalHeader');
  AddOrgModalPages = AddOrgUI.AddOrgModalPages;
  currentPage$ = this.addOrgService.changesTo$('currentPage');
  program: Program;
  disableCountrySelect = false;
  disableSave = false;
  addOrganizationsHelpLinkApplicant = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-applicants-faq-how-to-add-organizations.html';
  addOrganizationsHelpLinkManager = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-applications-add-new-organizations.html';
  vetOrganizationsHelpLinkApplicant = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-applicants-faq-why-is-vetting-required-for-organizations.html';
  vetOrganizationsHelpLinkManager = 'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-applications-vetting-organizations.html';
  captureVetting = false;

  get hasInternational () {
    return this.clientSettingsService.doesClientHaveClientFeature(APIAdminClient.ClientFeatureTypes.HasInternational);
  }

  get clientCountry () {
    return this.clientSettingsService.clientSettings.country || 'US';
  }

  constructor (
    private i18n: I18nService,
    private addOrgService: AddOrganizationService,
    private formBuilder: TypeSafeFormBuilder,
    private clientSettingsService: ClientSettingsService,
    private analyticsService: AnalyticsService,
    private spinnerService: SpinnerService
  ) {
    super();
  }

  ngOnInit () {
    this.captureVetting = this.processorType === ProcessingTypes.YourCause;
    this.addOrgService.registerModal(this.closeModal);
    this.addressFormGroup = this.formBuilder.group({
      address1: ['', Validators.required],
      address2: '',
      city: ['', Validators.required],
      stateProvRegCode: ['', Validators.required],
      countryCode: ['', Validators.required],
      postalCode: ['', Validators.required]
    });
    this.detailsFormGroup = this.formBuilder.group<DetailsFormGroup>({
      name: ['', Validators.required],
      website: [''],
      contactFullName: this.captureVetting ? ['', Validators.required] : '',
      contactEmail: this.captureVetting ? ['', [
        Validators.required,
        EmailExtensionValidator
      ]] : '',
      image: [[]]
    });
    this.lookupFormGroup = this.formBuilder.group({
      country: ['', Validators.required],
      id: ['', Validators.required]
    });
    this.nonprofitContactGroup = this.formBuilder.group({
      contactFullName: ['', Validators.required],
      contactEmail: ['', [Validators.required, EmailExtensionValidator]],
      contactWebsite: ''
    });
    if (!this.hasInternational) {
      this.lookupFormGroup.get('country').setValue(this.clientCountry);
      this.addOrgService.setAddOrgAttr('country', this.clientCountry);
      this.disableCountrySelect = true;
    }
  }

  async primaryClick () {
    this.spinnerService.startSpinner();
    this.disableSave = true;
    await this.addOrgService.handlePrimaryButtonClick();
    this.disableSave = false;
    this.spinnerService.stopSpinner();
    this.analyticsService.emitEvent({
      eventName: 'Add org modal submit',
      eventType: EventType.Click,
      extras: null
    });
  }

  tertiaryClick () {
    this.addOrgService.handleTertiaryButtonClick();
  }

  cancelClick () {
    this.addOrgService.handleCancelClick();
  }
}
