import { Injectable } from '@angular/core';
import { InsertTokenService } from '@core/services/insert-token.service';
import { PolicyService } from '@core/services/policy.service';
import { SpinnerService } from '@core/services/spinner.service';
import { AdHocReportingAPI } from '@core/typings/api/ad-hoc-reporting.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { environment } from '@environment';
import { AudienceService } from '@features/audience/audience.service';
import { AudienceMember } from '@features/audience/audience.typing';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { FormFieldCategoryService } from '@features/form-fields/services/form-field-category.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { BucketComp } from '@features/forms/form-builder/form-builder.typing';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { AdvancedFilterGroup, APIResult, BetweenFilterOption, Column, ColumnFilterRow, DragAndDropItem, FilterColumn, FilterHelpersService, FilterModalTypes, MultiValueListOptions, OneOfFilterOption, PaginatedResponse, PaginationOptions, SimpleNumberMap } from '@yourcause/common';
import { SelectOption, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { DateService } from '@yourcause/common/date';
import { FileService, TableDataDownloadFormat } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { PanelSection } from '@yourcause/common/panel';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { SwitchState } from '@yourcause/common/switch';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { isUndefined, uniq } from 'lodash';
import { ManageReportFormData } from '../pages/manage-ad-hoc-report-modal/manage-ad-hoc-report-modal.component';
import { AdHocReportingDefinitions, RelatedObjectNames, RootObjectNames } from './ad-hoc-reporting-definitions.service';
import { AdHocReportingMappingService } from './ad-hoc-reporting-mapping.service';
import { AdHocReportingState } from './ad-hoc-reporting.state';
import { ReportingResources } from './reporting.resources';

@Injectable({ providedIn: 'root' })
@AttachYCState(AdHocReportingState)
export class AdHocReportingService extends BaseYCService<AdHocReportingState> {

   constructor (
    private logger: LogService,
    private fileService: FileService,
    private i18n: I18nService,
    private ahs: ArrayHelpersService,
    private formService: FormsService,
    private reportingResources: ReportingResources,
    private adHocReportingMapper: AdHocReportingMappingService,
    private adHocReportingDefinitions: AdHocReportingDefinitions,
    private notifier: NotifierService,
    private dateService: DateService,
    private customDataTableService: CustomDataTablesService,
    private policyService: PolicyService,
    private filterHelperService: FilterHelpersService,
    private componentHelper: ComponentHelperService,
    private formFieldCategoryService: FormFieldCategoryService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private formFieldHelperService: FormFieldHelperService,
    private arrayHelper: ArrayHelpersService,
    private insertTokenService: InsertTokenService,
    private spinnerService: SpinnerService,
    private confirmAndTakeActionService: ConfirmAndTakeActionService,
    private audienceService: AudienceService
  ) {
    super();
  }

  get reports () {
    return this.get('reports');
  }

  get MAX_COLUMNS () {
    return environment.maxAdHocReportColumns;
  }

  get reportDetails () {
    return this.get('reportDetails');
  }

  get currentReportDetails () {
    return this.get('currentReportDetails');
  }

  get currentReportColumns () {
    return this.get('currentReportColumns');
  }

  get formComponentMap () {
    return this.get('formComponentMap');
  }

  get tableComponentMap () {
    return this.get('tableComponentMap');
  }

  get reportSchedulesMap () {
    return this.get('reportSchedulesMap');
  }

  get reportUsersMap () {
    return this.get('reportUsersMap');
  }

  async prepareAdHocReportDetail (
    reportId: number,
    standardReportTemplate?: AdHocReportingAPI.UserSavedReportForUi
  ) {
    await this.resolveModalDeps();
    await this.fetchReportById(reportId, standardReportTemplate);
    const reportDetails = this.getReportById(reportId);
    this.setCurrentReportDetails(reportDetails);
    const recordIds = this.getRecordIdsFromReportId(reportId);
    await this.resolveFormInfo(
      recordIds,
      this.adHocReportingDefinitions[this.getReportById(reportId).object]
    );
  }

  setCurrentReportDetails (reportDetails: ManageReportFormData) {
    this.set('currentReportDetails', reportDetails);
  }

  setCurrentReportColumns (columns: AdHocReportingUI.ColumnImplementation[]) {
    this.set('currentReportColumns', columns);
  }

  clearCurrentReportDetails () {
    this.set('currentReportDetails', undefined);
  }

  clearCurrentReportColumns () {
    this.set('currentReportColumns', undefined);
  }

  adaptAdvancedAPIFiltersToUIGroups (
    filterGroups: AdHocReportingAPI.AdvancedUserSavedFilterColumn[][],
    columns: AdHocReportingUI.ColumnDefinition[]
  ): AdvancedFilterGroup[] {
    const columnDefMap: Record<string, AdHocReportingUI.ColumnDefinition> = {};
    const adaptedFilterGroups = filterGroups.map((filterGroup) => {
      return filterGroup.map((filter) => {
        const columnName = this.adHocReportingMapper.adaptFormColumnNameForView(
          filter.columnName
        );
        if (columnName) {
          return {
            ...filter,
            columnName
          };
        } else {
          return undefined;
        }
      }).filter((item) => !!item);
    });

    return adaptedFilterGroups.map<AdvancedFilterGroup>((filterGroup) => {
      // group filters so that we can combine "One of" filters below
      const subChunked = filterGroup.reduce<
        AdHocReportingAPI.AdvancedUserSavedFilterColumn[][]
      >((acc, filter, index) => {
        const foundColumn = columns.find((def) => {
          const potentialColumn = this.adHocReportingMapper.getColumnIdentifier(def);

          return potentialColumn === filter.columnName;
        });
        if (foundColumn) {
          columnDefMap[filter.columnName] = foundColumn;

          const previousFilter = filterGroup[index - 1];

          if (previousFilter?.columnName === filter.columnName) {
            // if the previous filter in the sequence is for the same column
            // is a logical "OR"
            // and is an equals
            // and supports "One of"
            // we assume it's part of the same "One of" filter
            if (
              previousFilter.filterType === FilterModalTypes.equals &&
              OneOfFilterOption.types.includes(foundColumn.type)
            ) {
              let previousChunk = acc.pop(); // removes the last item from "acc" and returns it

              previousChunk = [
                ...previousChunk,
                {
                  ...filter
                }
              ];

              return [
                ...acc,
                previousChunk
              ];
            }

            // if the previous filter in the sequence is an "AND"
            // and is a greater than
            // and supports between
            // and this filter is a less than
            // we assume they are both part of the same between filter
            if (
              previousFilter.useLogicalOperatorAnd &&
              (previousFilter.filterType === FilterModalTypes.greaterThan) &&
              BetweenFilterOption.types.includes(foundColumn.type)
            ) {
              let previousChunk = acc.pop(); // removes the last item from "acc" and returns it

              previousChunk = [
                ...previousChunk,
                {
                  ...filter
                }
              ];

              return [
                ...acc,
                previousChunk
              ];
            }

            const isMultiFilter = MultiValueListOptions.some(option => {
              return option.types.includes(foundColumn.type);
            });

            // if this filter is a "multi" filter
            // and the previous chunk is of the same type
            // AND is either
            // includes with a previous of AND
            // or a not includes with a previous AND
            // or is equal/not equal TODO: make these work
            // combine them into one UI filter
            const isIncludes = filter.filterType === FilterModalTypes.multiValueIncludes;
            const isNotIncludes = filter.filterType === FilterModalTypes.multiValueNotIncludes;

            const shouldCombine = ((isIncludes || isNotIncludes) && previousFilter.useLogicalOperatorAnd) ||
              (!isNotIncludes && !isIncludes);

            if (
              isMultiFilter &&
              shouldCombine &&
              previousFilter.filterType === filter.filterType
            ) {
              let previousChunk = acc.pop(); // removes the last item from "acc" and returns it

              previousChunk = [
                ...previousChunk,
                {
                  ...filter
                }
              ];

              return [
                ...acc,
                previousChunk
              ];
            }
          }

          return [
            ...acc,
            [filter]
          ];
        }

        return [
          ...acc
        ];
      }, []);

      return {
        useAnd: this.everyConditionIsOR(filterGroup) ?
          SwitchState.Untoggled :
          SwitchState.Toggled,
        filters: subChunked.map<ColumnFilterRow>((filterChunk) => {
          const [filter] = filterChunk;
          const foundColumn = columnDefMap[filter.columnName];
          const label = this.adHocReportingMapper.getColumnLabel(foundColumn);

          const groupFilter: Column = {
            label: this.adHocReportingMapper.getColumnLabel(foundColumn),
            columnName: this.adHocReportingMapper.getColumnIdentifier(foundColumn),
            i18nKey: label, // this is on purpose, the builder handles the translation
            visible: true,
            type: null,
            filterOnly: false
          };

          // find the multi option that was selected for this filter
          const selectedMultiOption = MultiValueListOptions.find(option => {
            return option.types.includes(foundColumn.type) &&
              option.api === filter.filterType;
          });

          // if this filter has a multi option
          if (selectedMultiOption) {
            // default to the raw values (for text fields)
            let values = filter.filterValues;

            // use type assertion ('filterOptions' in foundColumn)
            // get the options selected and filter out any that are no longer relevant
            if ('filterOptions' in foundColumn) {
              const options = foundColumn.filterOptions.filter((option) => {
                return filterChunk.some((chunk) => {
                  return chunk.filterValues.includes('' + option.value);
                });
              });

              values = options.map(option => option.value);
            }

            return {
              column: groupFilter,
              filter: selectedMultiOption,
              value: values
            };
          }

          const isOneOf = OneOfFilterOption.types.includes(foundColumn.type) &&
            filter.filterType === FilterModalTypes.equals;

          if (isOneOf && 'filterOptions' in foundColumn) {
            const options = foundColumn.filterOptions.filter(option => {
              return filterChunk.some(filterValue => {
                return ('' + filterValue.filterValue) === ('' + option.value);
              });
            });

            return {
              column: groupFilter,
              filter: OneOfFilterOption,
              value: options.map(option => option.value)
            };
          }

          if (filter.filterType === 'bt') {
            return {
              column: groupFilter,
              filter: BetweenFilterOption,
              value: ('' + filter.filterValue)
                .split('<=>').map(subValue => new Date(subValue))
            };
          }

          // we assume it's an "in between" filter if
          // there are two filters in this chunk
          // the first filter is a greater than
          // and the second filter is a less than
          const isBetween = filterChunk.length === 2 &&
            filterChunk[0].filterType === FilterModalTypes.greaterThan &&
            filterChunk[1].filterType === FilterModalTypes.lessThan;

          if (isBetween) {
            let values = [
              filterChunk[0].filterValue,
              filterChunk[1].filterValue
            ];

            if (foundColumn.type === 'date') {
              values = [
                new Date(values[0] as any),
                new Date(values[1] as any)
              ];
            }

            return {
              column: groupFilter,
              filter: BetweenFilterOption,
              value: values
            };
          }

          const {
            value,
            filterOption
          } = this.filterHelperService.getFilterOptionAndValue(
            filter,
            foundColumn.type
          );

          return {
            column: groupFilter,
            filter: filterOption,
            value
          };
        })
      };
    });
  }

  everyConditionIsOR (conditions: any[]) {
    return conditions.every((condition) => {
      return !condition.useLogicalOperatorAnd;
    });
  }

  adaptAdvancedUIFiltersToPaginationAPI (
    groups: AdvancedFilterGroup[]
  ): AdHocReportingAPI.PaginationAdvancedFilterColumnModel[][] {
    return groups.map((group) => {
      return group.filters.reduce<AdHocReportingAPI.PaginationAdvancedFilterColumnModel[]>((acc, filter) => {
        return [
          ...acc,
          ...this.adaptAdvancedFiltersToAPI(
            filter,
            group
          ).map<AdHocReportingAPI.PaginationAdvancedFilterColumnModel>(advancedFilter => {
            return {
              columnName: advancedFilter.columnName,
              filter: {
                filterType: advancedFilter.filterType,
                filterValue: advancedFilter.filterValue,
                filterValues: advancedFilter.filterValues,
                useLogicalOperatorAnd: advancedFilter.useLogicalOperatorAnd
              }
            };
          })
        ];
      }, []);
    });
  }

  adaptAdvancedUIFiltersToReportAPI (
    groups: AdvancedFilterGroup[]
  ): AdHocReportingAPI.AdvancedUserSavedFilterColumn[][] {
    return groups.map((group) => {
      return group.filters.reduce<AdHocReportingAPI.AdvancedUserSavedFilterColumn[]>((acc, filter) => {
        return [
          ...acc,
          ...this.adaptAdvancedFiltersToAPI(filter, group)
        ];
      }, []);
    });
  }

  adaptAdvancedFiltersToAPI (
    filter: ColumnFilterRow<any>,
    group: AdvancedFilterGroup
  ): AdHocReportingAPI.AdvancedUserSavedFilterColumn[] {
    const returnVal: AdHocReportingAPI.AdvancedUserSavedFilterColumn = {
      columnName: this.adHocReportingMapper.adaptFormColumnNameForApi(filter.column.columnName ?? filter.column.prop),
      filterType: filter.filter.api,
      filterValue: filter.value ?? '',
      useLogicalOperatorAnd: group.useAnd === SwitchState.Toggled,
      filterValues: []
    };

    if (MultiValueListOptions.includes(filter.filter)) {
      const filterValues: string[] = filter.value instanceof Array ?
        filter.value.map((value) => '' + value) :
        ['' + filter.value];

      return [{
        ...returnVal,
        filterValue: null,
        filterValues
      }];
    } else if (filter.filter === OneOfFilterOption) {
      const filterValue: any[] = filter.value;

      return filterValue.map((value, index) => {
        return {
          ...returnVal,
          filterValue: value,
          useLogicalOperatorAnd: index === (filterValue.length - 1) ?
            returnVal.useLogicalOperatorAnd :
            false
        };
      });
    } else if (filter.filter === BetweenFilterOption) {
      const filterValue: [Date, Date]|[number, number] = filter.value;

      let values: [string, string];

      if (filterValue[0] instanceof Date && filterValue[1] instanceof Date) {
        values = [
          filterValue[0].toISOString(),
          filterValue[1].toISOString()
        ];
      } else {
        values = [
          '' + filterValue[0],
          '' + filterValue[1]
        ];
      }

      // between translates to greater than the first value
      // "AND" less than the second value
      return [
        {
          columnName: returnVal.columnName,
          filterType: FilterModalTypes.greaterThan,
          filterValue: values[0],
          filterValues: [],
          useLogicalOperatorAnd: true
        }, {
          columnName: returnVal.columnName,
          filterType: FilterModalTypes.lessThan,
          filterValue: values[1],
          filterValues: [],
          useLogicalOperatorAnd: returnVal.useLogicalOperatorAnd
        }
      ];
    }

    return [returnVal];
  }

  resetReportDetails (id: number) {
    return this.set('reportDetails', {
      ...this.reportDetails,
      [id]: undefined
    });
  }

  /**
   *
   * @param report the modal response that comes back from ManageAdHocReportModal
   * @returns the recordIds associated with this report
   */
  getRecordIdsFromModalResponse (report: ManageReportFormData) {
    const formIds = this.getFormIdsForAPI(report.forms, report.primaryFormId);
    let recordIds = formIds;
    if (report.object === 'table') {
      const tableField = this.formFieldHelperService.referenceFieldMap[
        report.referenceFieldTableKey
      ];
      recordIds = tableField ? [tableField.referenceFieldId] : [];
    }

    return recordIds;
  }

  /**
   *
   * @param reportId reportId
   * @returns the recordIds associated with this report
   */
  getRecordIdsFromReportId (reportId: number): number[] {
    const detail = this.reportDetails[reportId];
    const isTable = detail?.reportModelType === AdHocReportingAPI.AdHocReportModelType.Table;
    let recordIds: number[] = [];
    if (isTable) {
      const tableField = this.formFieldHelperService.referenceFieldMap[
        detail.referenceFieldTableKey
      ];
      recordIds = tableField ? [tableField.referenceFieldId] : [];
    } else {
      recordIds = detail?.forms.map((form) => form.id) ?? [];
    }

    return recordIds;
  }

  getComponentMap (property: RootObjectNames) {
    const isTableReport = property === 'table';

    return isTableReport ? this.tableComponentMap : this.formComponentMap;
  }

/**
 *
 * @param property Root object name, the property that the report is based on
 * @param recordIds This will be either form IDs or a referenceFieldTableId for fetching table components
 * @param usedColumns Columns already in use on report
 * @param usage Whether this is Ad Hoc, Charts, or Tokens
 */
  getBuckets (
    property: RootObjectNames,
    recordIds: number[],
    usedColumns?: AdHocReportingUI.ColumnImplementation[],
    usage = AdHocReportingUI.Usage.AD_HOC_BUILDER
  ): AdHocReportingUI.ColumnBucket[] {
    // if table report, we need to send those components instead
    const rootObject = this.adHocReportingDefinitions[property];
    const categoryBuckets = this.adHocReportingMapper.getCategoryBuckets(
      recordIds,
      this.getComponentMap(property),
      usage,
      rootObject
    );
    const related = rootObject.relatedObjects
      .map(key => key as RelatedObjectNames)
      .map((obj: RelatedObjectNames) => {
        return this.adHocReportingDefinitions[obj];
      }).concat(
        rootObject.omitRootObject ? [] : [rootObject]
      ).concat(
        categoryBuckets
      );
    const relatedObjects = related.filter((obj) => {
      if (usage !== AdHocReportingUI.Usage.TOKENS) {
        return !obj.tokenInsertOnly;
      }

      return true;
    });
    const returnVal = relatedObjects.map((relatedObj) => {
      const columns = relatedObj.columns.filter((column) => {
        if (column.dashboardOnly) {
          return usage === AdHocReportingUI.Usage.DASHBOARDS;
        }

        return true;
      }).map<AdHocReportingUI.ColumnDefinition>((column) => {
        column.parentBucketName = column.parentBucketName ||
          (relatedObj.i18nKey ?
            this.i18n.translate(relatedObj.i18nKey, {}, relatedObj.display) :
            relatedObj.display);
        column.parentBucket = column.customParentBucket || relatedObj.property;
        column.display = column.i18nKey ?
          this.i18n.translate(column.i18nKey, {}, column.display) :
          column.display;

        return column;
      }).map<AdHocReportingUI.ColumnImplementation>((definition) => {
        return {
          sortType: AdHocReportingAPI.SortTypes.NoSort,
          visibleInReport: true,
          filterColumn: this.adHocReportingMapper.getBlankFilter(definition),
          columnNameOverride: '',
          definition
        };
      });

      return {
        property: relatedObj.property,
        display: relatedObj.display,
        i18nKey: relatedObj.i18nKey,
        allColumns: columns,
        columns: this.ahs.sort(
          columns.filter(column => {
            return usedColumns ?
              !usedColumns.some(imp => imp.definition === column.definition) :
              true;
          }),
          ['definition', 'display']
        ).map((column) => {
          const definition = column.definition;
          if ('filterOptions' in  definition) {
            <SelectOption[]>(definition.filterOptions || []).map<TypeaheadSelectOption>((option: any) => {
              return {
                label: option.label || option.display,
                value: option.value,
                hidden: option.hidden
              };
            });
          }

          return column;
        })
      };
    });

    return this.ahs.sort(returnVal
      .filter((val, index) => returnVal
        .findIndex(ret => ret.property === val.property) === index
      ), 'display'
    ); // remove dupes
  }

  async saveReportDetails (
    report: ManageReportFormData,
    columns?: AdHocReportingUI.ColumnImplementation[],
    groups?: AdvancedFilterGroup[],
    useAnd?: SwitchState,
    id?: number
  ) {
    try {
      const formIds = this.getFormIdsForAPI(report.forms, report.primaryFormId);
      await this.resolveFormInfo(
        this.getRecordIdsFromModalResponse(report),
        this.adHocReportingDefinitions[report.object]
      );
      let referenceFieldTableId: number;
      if (report.object === 'table') {
        referenceFieldTableId = this.formFieldHelperService.referenceFieldMap[
          report.referenceFieldTableKey
        ]?.referenceFieldTableId;
      }
      const adapted: AdHocReportingAPI.CreateReportPayload|AdHocReportingAPI.UpdateReportPayload = {
        id,
        formIds,
        primaryFormId: report.primaryFormId,
        name: report.reportName,
        description: report.reportDescription,
        userSavedReportColumns: this.adHocReportingMapper.mapColumnsToApi(
          columns || []
        ),
        referenceFieldTableId,
        reportType: AdHocReportingAPI.AdHocReportType.AdHoc,
        reportModelType: this.adHocReportingDefinitions[report.object].type,
        reportUsers: [],
        advancedFilterColumns: this.adaptAdvancedUIFiltersToReportAPI(groups ?? []),
        useLogicalOperatorAnd: useAnd === SwitchState.Toggled
      };
      id = await this.reportingResources[id ? 'updateReport' : 'createReport'](
        adapted
      );
      await this.fetchReportById(id);

      await this.fetchReports();
      this.notifier.success(this.i18n.translate(
        'common:notificationSavedAdHocReport',
        {},
        'Successfully updated this report'
      ));

      return id;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:notificationErrorUpdatingTheReport',
        {},
        'There was an error updating the report'
      ));

      return null;
    }
  }

  getFormIdsForAPI (
    formIds: number[] = [],
    primaryFormId: number
  ): number[] {
    if (primaryFormId) {
      // Make sure primary form gets into formIds array
      formIds = [
        ...formIds,
        primaryFormId
      ];
    }

    return formIds;
  }

  /**
   * @param recordIds Can be formId or tableId based on scenario
   * @param rootObject Object that report is based on
   */
  async resolveFormInfo (
    recordIds: number[],
    rootObject: AdHocReportingUI.RootObject<string>,
    usage = AdHocReportingUI.Usage.AD_HOC_BUILDER
  ) {
    let componentMap: SimpleNumberMap<AdHocReportingUI.FormComponentSummary[]> = {};
    if (recordIds.length > 0) {
      if (rootObject.property === 'table') {
        await this.fetchTableComponents(recordIds[0]);
        componentMap = this.tableComponentMap;
      } else {
        await Promise.all(recordIds.map((recordId) => {
          return this.fetchFormComponents(recordId);
        }));
        componentMap = this.formComponentMap;
      }
      const categoryMap = this.formFieldCategoryService.getCategoryMapFromFormIds(
        recordIds,
        componentMap,
        this.formFieldTableAndSubsetService.tableColumnsMap,
        usage,
        rootObject
      );
      await this.customDataTableService.setOptionsListFromCategoryMap(
        categoryMap,
        recordIds,
        rootObject
      );

      return categoryMap;
    }

    return null;
  }

  async handleCreateReportFromTemplate (
    report: ManageReportFormData
  ): Promise<number> {
    try {
      const formIds = this.getFormIdsForAPI(report.forms, report.primaryFormId);
      const recordIds = this.getRecordIdsFromModalResponse(report);
      await this.resolveFormInfo(
        recordIds,
        this.adHocReportingDefinitions[report.object]
      );
      const template = report.startFromTemplate;
      const copy: AdHocReportingAPI.CreateReportPayload = {
        formIds,
        userSavedReportColumns: template.userSavedReportColumns,
        description: report.reportDescription,
        name: report.reportName,
        othersMayModify: template.othersMayModify,
        othersMayView: template.othersMayView,
        reportType: AdHocReportingAPI.AdHocReportType.AdHoc,
        reportModelType: this.adHocReportingDefinitions[report.object].type,
        reportUsers: [],
        referenceFieldTableId: this.formFieldHelperService.referenceFieldMap[
          report.referenceFieldTableKey
        ]?.referenceFieldTableId,
        advancedFilterColumns: template.advancedFilterColumns,
        useLogicalOperatorAnd: template.useLogicalOperatorAnd,
        primaryFormId: report.primaryFormId
      };
      const reportId = await this.reportingResources.createReport(copy);
      await this.fetchReports();
      this.notifier.success(this.i18n.translate(
        'common:textSuccessCreatingReport',
        {},
        'Successfully created the report'
      ));

      return reportId;

    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorCreatingReport',
        {},
        'There was an error creating the report'
      ));

      return null;
    }
  }

  async handleCopyReport (id: number) {
    try {
      const copyText = this.i18n.translate(
        'common:textCopy',
        {},
        'Copy'
      );
      const report = await this.reportingResources.getReportById(id);
      const reportUsers = await this.reportingResources.getSharedUsersForReport(id);
      const copy: AdHocReportingAPI.CreateReportPayload = {
        formIds: report.forms.map(form => form.id),
        userSavedReportColumns: report.userSavedReportColumns,
        description: report.description,
        name: `${report.name} - ${copyText}`,
        othersMayModify: report.othersMayModify,
        othersMayView: report.othersMayView,
        reportType: AdHocReportingAPI.AdHocReportType.AdHoc,
        reportModelType: report.reportModelType,
        reportUsers,
        referenceFieldTableId: report.referenceFieldTableId,
        advancedFilterColumns: report.advancedFilterColumns,
        useLogicalOperatorAnd: report.useLogicalOperatorAnd,
        primaryFormId: report.primaryFormId
      };
      const reportId = await this.reportingResources.createReport(copy);
      if (report.numberOfSchedules > 0) {
        const schedules = await this.reportingResources.getSchedulesForReport(id);
        for (const schedule of schedules) {
          await this.handleScheduleReport(
            {
              showMaskedData: schedule.showMaskedData,
              reportId,
              sftpId: schedule.sftpId,
              audienceId: schedule.audienceId,
              oneOffUsers: [],
              frequencyType: schedule.frequencyType,
              hour: schedule.hour,
              day: schedule.day,
              frequencyValue: schedule.frequencyValue,
              expirationHours: schedule.expirationHours,
              failureDeliveryUsers: schedule.failureDeliveryUsers,
              exportFileTypeId: schedule.exportFileTypeId,
              includeBom: schedule.includeBom,
              delimiter: schedule.delimiter
            },
            false,
            true
          );
        }
      }
      await this.fetchReports();
      this.notifier.success(this.i18n.translate(
        'reporting:notificationSuccessReportCopy',
        {
          name: report.name
        },
        'Successfully copied report __name__'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'reporting:notificationErrorReportCopy',
        {},
        'There was an error copying the report'
      ));
    }
  }

  async fetchReports () {
    const reports = await this.reportingResources.getReports();
    this.set('reports', reports
      .filter(report => report.reportType === AdHocReportingAPI.AdHocReportType.AdHoc
    ));
  }

  getSchedulesForReportFromMap (reportId: number) {
    return this.reportSchedulesMap[reportId];
  }

  getUsersForReportFromMap (reportId: number) {
    return this.reportUsersMap[reportId];
  }

  async setReportSchedules (reportId: number) {
    if (!this.getSchedulesForReportFromMap(reportId)) {
      const schedules = await this.reportingResources.getSchedulesForReport(reportId);
      this.set('reportSchedulesMap', {
        ...this.reportSchedulesMap,
        [reportId]: this.adaptSchedulesForUI(schedules)
      });
    }
  }

  async setReportUsers (reportId: number) {
    if (!this.getUsersForReportFromMap(reportId)) {
      const users = await this.reportingResources.getSharedUsersForReport(reportId);
      this.set('reportUsersMap', {
        ...this.reportUsersMap,
        [reportId]: users
      });
    }
  }

  resetSchedulesOnState (reportId: number) {
    this.set('reportSchedulesMap', {
      ...this.reportSchedulesMap,
      [reportId]: undefined
    });
  }

  async resetSchedules (reportId: number) {
    this.resetSchedulesOnState(reportId);
    await this.setReportSchedules(reportId);
  }

  resetUsersOnState (reportId: number) {
    this.set('reportUsersMap', {
      ...this.reportUsersMap,
      [reportId]: undefined
    });
  }

  async resetUsers (reportId: number) {
    this.resetUsersOnState(reportId);
    await this.setReportUsers(reportId);
  }

  async fetchReportById (
    id: number,
    standardReportTemplate?: AdHocReportingAPI.UserSavedReportForUi
  ) {
    try {
      let report: AdHocReportingAPI.UserSavedReport;
      if (!standardReportTemplate) {
        report = await this.reportingResources.getReportById(+id);
      } else {
        report = standardReportTemplate;
      }
      this.set('reportDetails', {
        ...this.reportDetails,
        [id]: {
          ...report
        }
      });

      return true;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'reporting:textErrorFetchingReportDetails',
        {},
        'There was an error fetching report details'
      ));

      return false;
    }
  }

  adaptSchedulesForUI (
    schedules: AdHocReportingAPI.ReportScheduleFromApi[]
  ): AdHocReportingUI.AdHocReportSchedule[] {
    return schedules.map((schedule) => {
      return {
        scheduleId: schedule.scheduleId,
        sftpId: schedule.sftpId,
        isDataFeed: !!schedule.sftpId,
        audienceId: schedule.audienceId,
        users: schedule.audienceUsers.concat(schedule.oneOffUsers),
        frequency: schedule.frequencyType,
        hours: this.dateService.convert24HourTimeToHour(schedule.hour),
        week: schedule.frequencyValue,
        month: schedule.frequencyValue,
        dayOfMonth: schedule.day,
        dayOfWeek: schedule.day,
        showMaskedData: schedule.showMaskedData,
        isAM: schedule.hour <= 11,
        expirationHours: schedule.expirationHours,
        exportFileTypeId: schedule.exportFileTypeId,
        includeBom: schedule.includeBom,
        delimiter: schedule.delimiter,
        createdBy: schedule.createdBy,
        updatedBy: schedule.updatedBy,
        createdDate: schedule.createdDate,
        updatedDate: schedule.updatedDate
      };
    });
  }

  async saveSharedUsersForReport (
    id: number,
    users: AudienceMember[]
  ) {
    try {
      await this.reportingResources.saveSharedUsersForReport({
        id,
        users
      });
      await this.fetchReports();
      this.resetReportDetails(id);
      this.notifier.success(this.i18n.translate(
        'reporting:textSuccessfullySavedUsersForReport',
        {},
        'Successfully updated user permissions for report'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'reporting:textErrorSavingUsersForReport',
        {},
        'There was an error updating user permissions for report'
      ));
    }
  }

  async deleteReport (id: number) {
    await this.reportingResources.removeReportById(id);
    await this.fetchReports();
  }

  async resolveModalDeps () {
    await this.formService.prepareForms();
  }

  getEndpointByObjectName (
    property: RootObjectNames,
    attr: 'readOnlyEndpoint'|'editEndpoint'|'chartEndpoint'
  ) {
    return this.adHocReportingDefinitions[property][attr];
  }

  getReportById (id: number): ManageReportFormData {
    const existingReport = this.reportDetails[id];

    return {
      reportDescription: existingReport.description,
      reportName: existingReport.name,
      forms: existingReport.forms.map(form => form.id),
      program: null,
      object: this.adHocReportingMapper.getObjectByReportModelType(existingReport.reportModelType),
      primaryFormId: existingReport.primaryFormId,
      referenceFieldTableKey: existingReport.referenceFieldTableKey,
      startFromTemplate: null
    };
  }

  getReportColumnsById (
    id: number,
    existingReport: AdHocReportingAPI.UserSavedReport = this.reportDetails[id]
  ) {
    const property: RootObjectNames = this.adHocReportingMapper.getObjectByReportModelType(
      existingReport.reportModelType
    );
    const recordIds = this.getRecordIdsFromReportId(id);
    const componentMap = this.getComponentMap(property);
    const buckets = this.getBuckets(property, recordIds);
    const rootObject = this.adHocReportingDefinitions[property];
    const categoryBuckets = this.adHocReportingMapper.getCategoryBuckets(
      recordIds,
      componentMap,
      AdHocReportingUI.Usage.AD_HOC_BUILDER,
      rootObject
    );
    const relatedObjects = rootObject.relatedObjects
      .map(objName => objName as RelatedObjectNames)
      .map((obj: RelatedObjectNames) => this.adHocReportingDefinitions[obj])
      .concat(categoryBuckets);


    return this.adHocReportingMapper.mapReportColumnToColumnImplementation(
      existingReport.userSavedReportColumns,
      rootObject,
      relatedObjects,
      buckets
    );
  }

  prepareAndGetReportRows (
    reportId: number,
    paginationOptions: PaginationOptions<any>,
    columns: AdHocReportingUI.ColumnImplementation[],
    objectName: RootObjectNames,
    filterGroups: AdvancedFilterGroup[],
    filtersUsingAnd: SwitchState,
    formIds: number[],
    primaryFormId: number,
    isViewMode = false,
    referenceFieldTableId: number
  ): Promise<APIResult<any>> {
    paginationOptions = this.adHocReportingMapper.mapColumnsForPagination(
      columns,
      paginationOptions
    );
    const endpoint = this.getEndpointByObjectName(
      objectName,
      isViewMode ? 'readOnlyEndpoint' : 'editEndpoint'
    );
    const {
      referenceFieldIds
    } = this.extractRefFieldIds(columns);
    const columnsForApi  = this.adHocReportingMapper.mapColumnsToApi(columns);
    const advancedFilters = this.adaptAdvancedUIFiltersToPaginationAPI(filterGroups);

    const advancedPaginationOptions: AdHocReportingAPI.AdvancedPaginationOptionsModel<any> = {
      ...paginationOptions,
      filterColumns: [],
      advancedFilterColumns: advancedFilters,
      useLogicalOperatorAnd: filtersUsingAnd === SwitchState.Toggled
    };

    return this.getReportRows(
      advancedPaginationOptions,
      endpoint,
      formIds,
      [],
      columnsForApi,
      isViewMode ? reportId : null,
      primaryFormId,
      referenceFieldIds,
      referenceFieldTableId
    );
  }

  async downloadAdHocReport (
    paginationOptions: PaginationOptions<any>,
    objectName: RootObjectNames,
    reportIdForViewOnly: number,
    columns: AdHocReportingUI.ColumnImplementation[],
    filterGroups: AdvancedFilterGroup[],
    filtersUsingAnd: SwitchState,
    reportName: string,
    format: TableDataDownloadFormat,
    includeBom: boolean,
    delimiter: string
  ) {
    if (paginationOptions) {
      paginationOptions = this.adHocReportingMapper.mapColumnsForPagination(
        columns,
        paginationOptions
      );
    }
    const advancedFilters = this.adaptAdvancedUIFiltersToPaginationAPI(filterGroups);
    const advancedPaginationOptions: AdHocReportingAPI.AdvancedPaginationOptionsModel<any> = {
      ...paginationOptions,
      filterColumns: [],
      advancedFilterColumns: advancedFilters,
      useLogicalOperatorAnd: filtersUsingAnd === SwitchState.Toggled
    };
    const endpoint = this.getEndpointByObjectName(objectName, 'readOnlyEndpoint');
    const data = await this.getReadOnlyReport(
      advancedPaginationOptions,
      endpoint,
      reportIdForViewOnly,
      format,
      includeBom,
      delimiter
    );
    const extension = format === TableDataDownloadFormat.XLSX ? '.xlsx' : 'csv';
    await this.fileService.downloadUrlAs(data, `${reportName}.${extension}`);
  }

  async getReadOnlyReport (
    paginationOptions: AdHocReportingAPI.AdvancedPaginationOptionsModel<any>,
    endpoint: string,
    reportIdForViewOnly: number,
    fileExportTypeId: TableDataDownloadFormat,
    includeBom: boolean,
    delimiter: string
  ) {
    try {
      const data = await this.reportingResources.getReadOnlyReport(
        paginationOptions,
        endpoint,
        reportIdForViewOnly,
        fileExportTypeId,
        includeBom,
        delimiter
      );

      return data;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(
        this.i18n.translate(
          'common:textErrorGettingReportData',
          {},
          'There was an error getting report data'
        )
      );
    }
  }

  async getReportRows (
    paginationOptions: AdHocReportingAPI.AdvancedPaginationOptionsModel<any>,
    endpoint: string,
    formIds: number[],
    formDataFields: string[],
    columnsForApi: AdHocReportingAPI.UserSavedReportColumn[],
    reportIdForViewOnly: number, // only pass if view only
    baseFormId: number,
    referenceFieldIds: number[] = [],
    referenceFieldTableId: number
  ) {
    const data = await this.reportingResources.getReportRows(
      paginationOptions,
      endpoint,
      formIds,
      formDataFields,
      baseFormId,
      columnsForApi,
      reportIdForViewOnly,
      referenceFieldIds,
      referenceFieldTableId,
      null
    );

    return {
      success: true,
      data
    } as APIResult<any>;
  }

  processCsv (
    data: PaginatedResponse<any>,
    columns: AdHocReportingUI.ColumnImplementation[],
    isMasked: boolean
  ) {
    const visibleColumns = columns.filter((column) => {
      return column.visibleInReport;
    });
    let records = data.records;
    if (records.length === 0) {
      records = [{}];
    }
    const adapted = records.map((record) => {
      const returnVal = visibleColumns.reduce((acc, column) => {
        let header = column.columnNameOverride || `${column.definition.display} (${
          column.definition.parentBucketName
        })`;
        if (!isUndefined((acc as any)[header])) {
          let isOverride = true;
          let count = 0;
          while (isOverride) {
            count++;
            header = header + ' (' + count + ')';
            if (!(acc as any)[header]) {
              isOverride = false;
            }
          }
        }

        return {
          ...acc,
          [header]: this.adHocReportingMapper.getValueForColumnFromRow(
            column.definition,
            record,
            isMasked
          )
        };
      }, {});

      return returnVal;
    });

    return this.fileService.convertObjectArrayToCSVString(adapted);
  }

  async fetchTableComponents (referenceFieldTableId: number) {
    const tableColumns = await this.formFieldTableAndSubsetService.setColumnsForTable(
      referenceFieldTableId
    );

    const tableComps = tableColumns.map((column) => {
      const refField = this.formFieldHelperService.referenceFieldMapById[
        column.referenceFieldId
      ];

      return {
        key: refField.key,
        type: refField.type,
        label: refField.name,
        referenceFieldKey: refField.key
      };
    });

    this.set('tableComponentMap', {
      ...this.tableComponentMap,
      [referenceFieldTableId]: tableComps
    });
  }

  async handleSubsetComponents (
    components: AdHocReportingAPI.FormComponentSummary[]
  ) {
    const dataPointComps: AdHocReportingUI.FormComponentSummary[] = [];
    await Promise.all(
      components.map(async (component) => {
        // get the ref field related to each comp
        const refField = this.formFieldHelperService.getReferenceFieldFromCompType(component.type);
        // if it's a subset field get the subset rows
        if (refField?.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset) {
          const subsetRows = await this.formFieldTableAndSubsetService.setDataPointsForSubset(
            refField.referenceFieldId
          );
          // for each of the rows, push component info to list of comps being added to form comp map
          subsetRows.forEach((row) => {
            const comp: AdHocReportingUI.FormComponentSummary = {
              key: 'referenceFields-' + row.referenceField.key,
              type: row.referenceField.type,
              label: row.referenceField.name,
              referenceFieldKey: row.referenceField.key,
              referenceFieldTableId: refField.referenceFieldTableId
            };
            dataPointComps.push(comp);
          });
        }
      })
    );

    return dataPointComps;
  }

  async fetchFormComponents (formId: number) {
    const components = await this.reportingResources.getFormComponents(formId);
    const dataPointComps = await this.handleSubsetComponents(components);
    const compsToAdd = [
      ...components,
      ...dataPointComps
    ];
    const formComponentMap = {
      ...this.get('formComponentMap'),
      [formId]: compsToAdd.filter((comp) => {
        return !this.componentHelper.isLayoutComponent(comp.type);
      })
    };
    this.setFormComponentMap(formComponentMap);
  }

  setFormComponentMap (
    formComponentMap: SimpleNumberMap<AdHocReportingUI.FormComponentSummary[]>
  ) {
    this.set('formComponentMap', formComponentMap);
  }

  resetFormComponentMap (formId: number) {
    this.set('formComponentMap', {
      ...this.get('formComponentMap'),
      [formId]: undefined
    });
  }

  extractRefFieldIds (
    columns: AdHocReportingUI.ColumnImplementation[],
    filterColumns: FilterColumn<any>[] = [],
    userSavedReportColumns: AdHocReportingAPI.UserSavedReportColumn[] = []
  ): {
    referenceFieldIds: number[];
    referenceFieldTableIds: number[];
  } {
    const columnRefFields = columns.filter((col) => {
      return this.adHocReportingMapper.isRefFieldColumn(col.definition.parentBucket);
    }).map(col => {
      return col.definition.column;
    });
    const filterRefFields = filterColumns.filter((col) => {
      return this.adHocReportingMapper.isRefFieldColumn(col.columnName);
    }).map((col) => {
      const split = col.columnName.split('.');

      return split[split.length - 1];
    });
    let referenceFieldTableIds: number[] = [];
    const userSavedRefFields = userSavedReportColumns.filter((col) => {
      return this.adHocReportingMapper.isRefFieldColumn(col.columnName);
    }).map((col) => {
      if (
        col.referenceFieldTableId &&
        !referenceFieldTableIds.includes(col.referenceFieldTableId)
      ) {
        referenceFieldTableIds.push(col.referenceFieldTableId);
      }
      const split = col.columnName.split('.');

      return split[split.length - 1];
    });
    const keys = uniq([
      ...columnRefFields,
      ...filterRefFields,
      ...userSavedRefFields
    ]);

    return {
      referenceFieldIds: this.formFieldHelperService.convertRefFieldKeysToIds(keys),
      referenceFieldTableIds
    };
  }

  async handleSendReport (payload: AdHocReportingAPI.SendReportPayload) {
    try {
      await this.reportingResources.sendReport(payload);
      this.notifier.success(this.i18n.translate(
        'reporting:textSuccessSendingReport',
        {},
        'Successfully sent the report'
      ));
      await this.fetchReports();
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'reporting:textErrorSendingReport',
        {},
        'There was an error sending the report'
      ));
    }
  }

  async doDeleteSchedule (
    scheduleId: number,
    reportId: number
  ) {
    await this.reportingResources.deleteScheduledReport(scheduleId);
    await Promise.all([
      this.fetchReports(),
      this.resetSchedules(reportId)
    ]);
  }

  async handleDeleteSchedule (
    scheduleId: number,
    reportName: string,
    reportId: number
  ) {
    await this.confirmAndTakeActionService.genericConfirmAndTakeAction(
      () => this.doDeleteSchedule(scheduleId, reportId),
      this.i18n.translate(
        'common:textDeleteScheduleHeader',
        {},
        'Delete Schedule'
      ),
      reportName,
      this.i18n.translate(
        'reporting:textDeleteScheduleDesc',
        {},
        'Deleting the schedule will prevent it from being sent on the specific timetable. Are you sure you want to delete the schedule?'
      ),
      this.i18n.translate(
        'common:textDeleteSchedule',
        {},
        'Delete schedule'
      ),
      this.i18n.translate(
        'reporting:textSuccessfullyDeletedReportSchedule',
        {},
        'Successfully deleted report schedule'
      ),
      this.i18n.translate(
        'reporting:textUnableToDeleteReportSchedule',
        {},
        'There was an error deleting this report schedule'
      )
    );
  }

  async doScheduleReport (
    payload: AdHocReportingAPI.ScheduleReportPayload,
    isUpdate = true
  ) {
    const endpoint = isUpdate ? 'updateScheduledReport' : 'addScheduledReport';
    await this.reportingResources[endpoint](payload);
    await Promise.all([
      this.fetchReports(),
      this.resetSchedules(payload.reportId),
      this.audienceService.resetAllAudiences()
    ]);
    this.audienceService.resetAudienceDetailMap();
  }

  async handleScheduleReport (
    payload: AdHocReportingAPI.ScheduleReportPayload,
    isUpdate = true,
    skipToastr = false
  ) {
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doScheduleReport(payload, isUpdate),
      skipToastr ? '' : this.i18n.translate(
        isUpdate ?
          'reporting:textSuccessUpdateScheduledReport' :
          'reporting:textSuccessCreateScheduledReport',
        {},
        isUpdate ?
          'Successfully updated the scheduled report' :
          'Successfully created the scheduled report'
      ),
      this.i18n.translate(
        isUpdate ?
          'reporting:textErrorUpdateScheduledReport' :
          'reporting:textErrorCreateScheduledReport',
        {},
        isUpdate ?
          'There was an error updating the scheduled report' :
          'There was an error creating the scheduled report'
      )
    );
  }

  adaptBucketsToPanelSections (
    property: RootObjectNames
  ): PanelSection<never, BucketComp[]>[] {
    const standardPanelSections = this.getStandardTokenGroups(property);
    const formFieldMap = this.formFieldCategoryService.getFormFieldCategoryMapByUsage(
      AdHocReportingUI.Usage.TOKENS
    );
    const formFieldPanelSection = this.formFieldCategoryService.formFieldMapToDragAndDropItem(
      formFieldMap,
      null,
      true
    );
    const allSections = [
      formFieldPanelSection,
      ...standardPanelSections
    ];

    return allSections;
  }

  getStandardTokenGroups (
    property: RootObjectNames
  ) {
    const buckets = this.getBuckets(property, [], [], AdHocReportingUI.Usage.TOKENS);

    return buckets.map<PanelSection<never, BucketComp[]>>((bucket) => {
      return {
        name: bucket.display,
        panels: [{
          name: '',
          description: '',
          icon: '',
          iconClass: '',
          context: this.arrayHelper.sort(bucket.columns.map((col) => {
            const def = col.definition;
            const key = this.adHocReportingMapper.getColumnIdentifier(def);

            return {
              key,
              label: def.display,
              name: def.display,
              type: null,
              icon: '',
              tooltip: '',
              fieldAudience: null,
              isReferenceField: false,
              notDraggable: true,
              markAsRequired: false,
              categoryId: null,
              actions: [{
                icon: 'plus',
                tooltip: this.i18n.translate(
                  'common:textInsert',
                  {},
                  'Insert'
                ),
                onClick: (item: DragAndDropItem) => {
                  this.insertTokenService.setTokenToInsert(item.key);
                }
              }]
            };
          }), 'name'),
          uniqueId: bucket.property
        }]
      };
    });
  }

  getDetailLinkInfo (objectName: RootObjectNames) {
    let showBudgetDetailLink = false;
    let showFsDetailLink = false;
    let detailLinkTooltipText = '';
    if (objectName === 'budgets') {
      showBudgetDetailLink = this.policyService.system.canManageBudgets();
      detailLinkTooltipText = this.i18n.translate(
        'BUDGET:textViewBudget',
        {},
        'View budget'
      );
    } else if (objectName === 'fundingSources') {
      showFsDetailLink = this.policyService.system.canManageFundingSources();
      detailLinkTooltipText = this.i18n.translate(
        'BUDGET:textViewFundingSource',
        {},
        'View funding source'
      );
    } else {
      detailLinkTooltipText = this.i18n.translate(
        'common:textViewApplication',
        {},
        'View application'
      );
    }

    return {
      showBudgetDetailLink,
      showFsDetailLink,
      detailLinkTooltipText
    };
  }

  /**
   * Handle Saving the Report Configuration
   *
   * @param newDetail: Updated Report Detail
   * @param reportId: Report ID
   * @param columns: Report Columns
   * @param filterGroups: Filter Groups
   * @param filtersUsingAnd: Filters using and?
   * @param exceedsMaxColumns: Exceeds max columns
   * @returns the new detail, columns, and if passed
   */
  async handleReportConfiguration (
    newDetail: ManageReportFormData,
    reportId: number,
    columns: AdHocReportingUI.ColumnImplementation[],
    filterGroups: AdvancedFilterGroup[],
    filtersUsingAnd: SwitchState,
    exceedsMaxColumns: boolean
  ) {
    const formIds = [
      ...newDetail.forms,
      newDetail.primaryFormId
    ].filter((form) => !!form);
    await this.resolveFormInfo(
      this.getRecordIdsFromModalResponse(newDetail),
      this.adHocReportingDefinitions[newDetail.object]
    );
    const updatedColumns = (columns || []).filter(({ definition }) => {
      // If form field column, only keep it if form still exists
      // Allow all standard fields
      if (
        !definition.isStandardField &&
        definition.parentBucket.includes('category.')
      ) {
        const formStillExists = formIds.some(formId => {
          return definition.formIds.includes(formId);
        });

        return formStillExists;
      }

      return true;
    });

    const passed = await this.handleSaveReport(
      reportId,
      newDetail,
      updatedColumns,
      filterGroups,
      filtersUsingAnd,
      exceedsMaxColumns,
      true
    );

    return {
      passed,
      detail: newDetail,
      columns: updatedColumns
    };
  }

  /**
   * Handles saving a Report
   *
   * @param reportId: Report ID
   * @param detail: Report Detail
   * @param columns: Report Columns
   * @param filterGroups: Filters
   * @param filtersUsingAnd: Use And Switch State
   * @param exceedsMaxColumns: Exceeds Max Columns
   * @param skipSpinner: Skip the spinner
   * @returns if the report was saved
   */
  async handleSaveReport (
    reportId: number,
    detail: ManageReportFormData,
    columns: AdHocReportingUI.ColumnImplementation[],
    filterGroups: AdvancedFilterGroup[],
    filtersUsingAnd: SwitchState,
    exceedsMaxColumns: boolean,
    skipSpinner: boolean
  ) {
    if (exceedsMaxColumns) {
      return false;
    }
    const invalidColumnHeaders  = this.adHocReportingMapper.getInvalidColumnHeaders(columns);
    if (invalidColumnHeaders.length > 0) {
      await this.adHocReportingMapper.openInvalidColumnHeadersModal(invalidColumnHeaders);

      return false;
    }
    if (!skipSpinner) {
      this.spinnerService.startSpinner();
    }
    const id = await this.saveReportDetails(
      detail,
      columns,
      filterGroups,
      filtersUsingAnd,
      reportId
    );
    if (!skipSpinner) {
      this.spinnerService.stopSpinner();
    }

    return !!id;
  }
}

