<div [ycHidden]="!showErrorSummary">
  <gc-form-error-summary
    [labelMap]="labelMap"
    [translations]="translations"
    [forceUpdate]="forceErrorSummaryUpdate"
    [tabFormGroup]="tabFormGroup"
    [hiddenCompKeys]="hiddenCompKeys"
    (onErrorsChange)="errorMessages = $event">
  </gc-form-error-summary>
</div>

<div #instanceDropList="cdkDropList"
  cdkDropList
  [class.editable]="editable"
  class="drop-container"
  [cdkDropListDisabled]="!editable"
  [cdkDropListSortingDisabled]="false"
  [cdkDropListData]="formDefinition.components"
  [cdkDropListConnectedTo]="dropLists | ycPluck:'list' | ycAs:$dropLists"
  (cdkDropListDropped)="handleComponentDropped($event)">

  <!-- No components -->
  <gc-form-builder-empty-container 
    *ngIf="editable && !formDefinition.components?.length">
  </gc-form-builder-empty-container>

  <div *ngFor="let component of formDefinition | gcFlattenFormDefinition: shouldFlatten;
    trackBy: trackBy;
    let last = last"
    cdkDrag
    [cdkDragData]="component">
    <gc-form-component-display
      [depth]="1"
      [formId]="formId"
      [formGroup]="tabFormGroup"
      [lastCompOnPage]="last"
      [isFormBuilderView]="isFormBuilderView"
      [refIdsChanged]="refIdsChanged"
      [standardFieldsChanged]="standardFieldsChanged"
      [masked]="masked"
      [notAutoSave]="notAutoSave"
      [disabledOverride]="readOnly ? true : undefined"
      [hidden]="component.hidden"
      [showErrorSummary]="showErrorSummary"
      [errorMessages]="errorMessages"
      [value]="component.value"
      [parentFields]="parentFields"
      [isManagerForm]="isManagerForm"
      [translations]="translations"
      [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
      [component]="component | ycAs: $baseComponent"
      [editable]="editable"
      (onValueChange)="valueChanged($event)"
      (onValidChange)="validChanged()"
      (componentDropped)="handleComponentDropped($event)"
      (componentActionClick)="componentActionClick.emit($event)">
    </gc-form-component-display>
  </div>
</div>
