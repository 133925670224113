import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BBIDService } from '@core/services/bbid.service';
import { Applicant } from '@core/typings/applicant.typing';
import { User } from '@core/typings/client-user.typing';
import { LoginMethod } from '@core/typings/login-method.typing';
import { BlackbaudSsoError, SSOToken, TokenContent, TokenResponse, Token_Refreshing_Storage_Key } from '@core/typings/token.typing';
import { environment } from '@environment';
import { ImpersonationService } from '@features/impersonation/impersonation.service';
import { UserService } from '@features/users/user.service';
import { HeapService, TrackingEventNames, TrackingPropertyNames } from '@yourcause/common/heap';
import { LogService } from '@yourcause/common/logging';
import { ModalFactory } from '@yourcause/common/modals';
import { AppInsightsService } from '@yourcause/common/utils';
import { DeepLinkingService } from '../deep-linking.service';
import { MixpanelService } from '../mixpanel.service';
import { PortalDeterminationService } from '../portal-determination.service';
import { SSOService } from '../sso.service';
import { TokenRefreshResources } from './token-refresh.resources';
import { TokenRetrievalResources } from './token-retrieval.resources';
import { TokenRevocationResources } from './token-revocation.resources';
import { TokenStorageService } from './token-storage.service';
import { TokenTimeoutService } from './token-timeout.service';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private permittedPlatformTransferRedirects = [
    'localhost:51851',
    'dev-grantsconnect-ui.azurewebsites.net'
  ];

  logoutTriggered = false;
  private latestProm: Promise<string|TokenResponse>;
  private refreshOffset = 1 /* minute(s) */ * 60 /* seconds */ * 1000 /* milliseconds */;

  constructor (
    private mixpanel: MixpanelService,
    private logger: LogService,
    private userService: UserService,
    private ssoService: SSOService,
    private modalFactory: ModalFactory,
    private deepLinkingService: DeepLinkingService,
    private portal: PortalDeterminationService,
    private revocation: TokenRevocationResources,
    private refresh: TokenRefreshResources,
    private retrieval: TokenRetrievalResources,
    private storage: TokenStorageService,
    private timeout: TokenTimeoutService,
    private appInsights: AppInsightsService,
    private impersonationService: ImpersonationService,
    private bbidService: BBIDService,
    private heapService: HeapService
  ) { }

  set isRefreshingToken (isRefreshing: boolean) {
    localStorage.setItem(Token_Refreshing_Storage_Key, '' + isRefreshing);
  }

  get isRefreshingToken () {
    const isRefreshing = localStorage.getItem(Token_Refreshing_Storage_Key);
    if (!isRefreshing || isRefreshing === 'undefined') {
      return false;
    }

    try {
      return JSON.parse(isRefreshing) as boolean;
    } catch (e) {
      this.logger.error(e, {
        message: 'Error parsing is refreshing ' + isRefreshing
      });
    }

    return false;
  }

  get isBbgm () {
    return this.storage.jwt?.isBbgm;
  }

  get isBbid () {
    return this.storage.jwt?.bbidToken;
  }

  get clientId () {
    return this.storage.jwt?.clientId;
  }

  get bbidSvcId () {
    return this.storage.jwt?.svcId;
  }

  get bbidEnvId () {
    return this.storage.jwt?.envId;
  }

  getPathname () {
    return location.pathname;
  }

  setAttemptedRoute () {
    const pathname = this.getPathname();
    if (
      pathname !== '/' &&
      pathname !== `/${this.portal.routeBase}/` &&
      pathname !== '/' + this.portal.routeBase &&
      !pathname.includes('apply/applications') &&
      !pathname.includes('management/home/my-workspace') &&
      !pathname.endsWith('/sso_redirect') &&
      !pathname.endsWith('/ssologout')
    ) {
      const route = location.href?.split(new RegExp(location.hostname + '\:?\\d*')).pop();
      if (!!route) {
        this.deepLinkingService.setAttemptedRoute(route);
      }

      return true;
    }

    return false;
  }

  hasCurrentValidToken () {
    const currentToken = this.storage.jwt;
    // apply an offset to ensure we always have an up to date token
    const now = new Date(Date.now() + this.refreshOffset);

    // make sure the JWT is intact and that the token's expiration is in the future
    return this.isBbid ||
      (currentToken && !!this.parseJwt() && (new Date(currentToken.expiration) > now));
  }


  hasFutureValidToken () {
    const currentToken = this.storage.jwt;
    const now = new Date();

    // we have an intact JWT
    return this.isBbid ||
      (currentToken &&
        !!this.parseJwt() &&
        // and will be valid in the future if the token is expired
        !this.hasCurrentValidToken() &&
        // but the refreshToken is not
        (new Date(currentToken.refreshTokenExpiration) > now));
  }

  hasImpersonationToken () {
    const parsed = this.parseJwt();

    return !!parsed?.impersonated_by_user_id ?? false;
  }

  getIsLoggedIn () {
    return this.hasCurrentValidToken() || this.hasFutureValidToken();
  }

  getLatestToken (returnFullToken = false) {
    if (!this.latestProm || returnFullToken) {
      this.latestProm = new Promise<string|TokenResponse>(async (resolve) => {
        // pull the current token
        if (this.isBbid) {
          await this.setBbidJwt(this.bbidSvcId, this.bbidEnvId, true, this.isBbgm, this.clientId);
        }
        const currentToken = this.storage.jwt;
        // if we need to refresh, kick that off
        if (this.hasFutureValidToken() && !this.isBbid) {
          await this.doRefresh();
        }
        // if we have a valid token, return that
        if (this.isBbid || this.hasCurrentValidToken()) {
          return resolve(returnFullToken ? this.storage.jwt : this.storage.jwt.token);
        } else if (currentToken) {
          // if current token but it's not valid, we can remove it
          this.storage.revoke();
        }
        // otherwise we assume they never tried to log in and don't have a token
        resolve(null);
      }).then((val) => {
        this.latestProm = null;

        return val;
      }).catch(e => {
        this.logger.error(e, {
          message: 'Error logging out'
        });
        this.logout(true);

        throw e;
      });
    }

    return this.latestProm;
  }

  updateClientId (clientId: number) {
    if (!!this.storage.jwt) {
      this.storage.jwt = {
        ...this.storage.jwt,
        clientId
      };
    }
  }

  /**
   * Gets and sets the BBID Token
   *
   * @param svcId: Service ID from Blackbaud
   * @param envId: Environment ID from Blackabaud
   */
  async setBbidJwt (
    svcId: string,
    envId: string,
    revokeIfFailed: boolean,
    isBbgm: boolean,
    clientId: number
  ) {
    const token = await this.bbidService.getToken();
    if (!!token) {
      const jwt: TokenResponse = {
        token,
        isBbgm,
        bbidToken: true,
        expiration: '',
        refreshToken: '',
        refreshTokenExpiration: '',
        svcId: svcId || this.storage.jwt?.svcId,
        envId: envId || this.storage.jwt?.envId,
        clientId
      };

      this.storage.jwt = jwt;

      return true;
    } else {
      if (revokeIfFailed) {
        this.storage.revoke();
      }

      return false;
    }
  }

  handleExpiredSession () {
    setTimeout(() => {
      return this.logout(true);
    }, 1000);
  }

  async ssoExchange (
    getUserFunc: () => Promise<User|Applicant>,
    clientId: number
  ) {
    // unpick the embedded URL content from the sso exchange or *subdomain redirect*
    const token = this.extractTokenFromLocationAttribute(location.hash);

    const {
      id_token,
      access_token
    } = token;

    const clientIdentifier = this.storage.clientIdentifier;

    // do the exchange
    const response = await this.retrieval.getTokenFromSSO(
      id_token,
      access_token,
      clientIdentifier
    );
    if (!!clientId) {
      response.clientId = clientId;
    }

    // set the client identifier
    this.storage.overrideClientIdentifier(clientIdentifier);

    // store the sso token for when we sign out
    this.ssoService.setIdToken(token.id_token);
    // using the exchanged token, store that for use in the app
    this.tokenSignin(response);
    
    // track initial login
    const jwt = this.parseJwt(response.token);
    this.trackInitialLoginData(
      LoginMethod.SSO,
      true,
      jwt?.UserId,
      jwt?.email,
      response.clientId);
    
    await getUserFunc();
  }

  extractTokenFromLocationAttribute (value: string) {
    return (value || '')
      .slice(1)
      .split('&')
      .reduce((obj, key) => ({
        ...obj,
        [key.split('=')[0]]: key.split('=')[1]
      }), {} as SSOToken);
  }

  tokenSignin (token: TokenResponse) {
    this.storage.jwt = token;
    const jwt = this.parseJwt(token.token);
    if (!!jwt) {
      const isLocal = environment.actualLocationBase === 'localhost';
      if (!isLocal) {
        this.appInsights.setAuthenticatedUserContext(jwt.UserId, jwt.client_id, true);
      }
    }
    this.mixpanel.track('Login', {});
  }

  logout = async (
    doRedirect = true,
    attemptRevoke = true,
    logoutOfBbid = true
  ) => {
    if (!this.logoutTriggered) {
      const isBbid = this.isBbid;
      this.logoutTriggered = true;
      this.mixpanel.track('Logout', {});
      this.heapService.resetIdentity();
      this.mixpanel.reset();

      this.timeout.stop();
      const isImpersonating = this.hasImpersonationToken();
      if (attemptRevoke && this.hasCurrentValidToken()) {
        await this.revocation.revokeToken();
      } else {
        this.storage.revoke();
      }
      this.userService.setUser(null);
      this.modalFactory.dismissAllOpen();
      this.appInsights.clearAuthenticatedUserContext();
      if (isBbid && logoutOfBbid) {
        this.bbidService.logoutOfBbid();
      } else {
        let doHrefChange = false;
        let url = this.portal.isPlatform ?
          `/platform/auth/logout` :
          `/${this.portal.routeBase}/auth/signin`;
        if (this.ssoService.getIdToken()) {
          url = this.ssoService.logout() || url;
          doRedirect = true;
          doHrefChange = true;
        }
        if (isImpersonating) {
          this.impersonationService.handleEndImpersonationSession();
        } else if (doRedirect) {
          location[doHrefChange ? 'href' : 'pathname'] = url;
        }
      }
    }

    return '';
  };

  parseJwt (token = this.storage.jwt?.token) {
    if (!!token) {
      try {
        return JSON.parse(atob(token.split('.')[1]
          .replace(/-/g, '+')
          .replace(/_/g, '/'))
        ) as TokenContent;
      } catch (e) {
        console.warn('Failed to parse JWT', e, 'got:', this.storage.jwt);
      }
    }

    return null;
  }

  castTokenContentToUser (content: TokenContent): User {
    return {
      firstName: content.given_name,
      lastName: content.family_name,
      email: content.email,
      id: +content.sub,
      userId: Number(content.UserId),
      active: true,
      culture: null,
      isNewUser: null,
      isRootUser: null,
      jobTitle: null,
      roles: null,
      workFlowLevels: null,
      acceptedTermsOfService: null,
      clientFeatures: null,
      isInNominationWorkFlow: null,
      isIntegratedWithCsrZone: null,
      workflows: [],
      isSso: false,
      hasGenAIEntitlement: false
    };
  }

  async doRefresh () {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      const parsedToken = this.parseJwt();
      const identifier = this.storage.clientIdentifier;

      const result = await this.refresh.refreshToken(
        this.storage.jwt.refreshToken,
        +parsedToken.UserId,
        identifier,
        this.clientId
      );
      this.isRefreshingToken = false;
      if (!!result) {
        result.clientId = this.clientId;
        this.storage.jwt = result;
      } else {
        this.appInsights.trackEvent('Refresh token failed', {
          event: 'REFRESH_FAILED_CATCH',
          identifier,
          userId: parsedToken?.UserId,
          token: this.storage.jwt?.refreshToken,
          email: parsedToken?.email,
          refreshExpiration: this.storage.jwt?.refreshTokenExpiration,
          currentDateTime: Date.now().toString()
        });
        this.handleExpiredSession();
      }
    } else {
      // Potential timing issue where refresh is called at the same time across multiple tabs
      // We need to wait for refreshing to be false, and use the latest result
      await new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!this.isRefreshingToken) {
            clearInterval(interval);
            resolve(this.isRefreshingToken);
          }
        }, 100);
      });
    }
  }

  handlePlatformDomainTransfer () {
    const platformHostRename = sessionStorage.getItem('platformHostRename');
    // Check to see if returning to local environment
    if (!!platformHostRename) {
      sessionStorage.removeItem('platformHostRename');
      const newHost = decodeURIComponent(platformHostRename);

      if (!this.permittedPlatformTransferRedirects.includes(newHost)) {
        return false;
      }

      location.hostname = newHost;

      return true;
    }

    return false;
  }

  async platformAdminSsoExchange (): Promise<BlackbaudSsoError> {
    const token = this.extractTokenFromLocationAttribute(location.search);
    let response: TokenResponse = null;
    let error: BlackbaudSsoError = null;
    try {
      const response = await this.retrieval.getPlatformAdminToken(
        token.code,
        this.storage.clientIdentifier
      );
      const clientIdentifier = this.storage.clientIdentifier;
      this.storage.overrideClientIdentifier(clientIdentifier);
      this.tokenSignin(response);
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.logger.error(e);
      if (e?.error?.message === 'User does not have an account') {
        error = BlackbaudSsoError.NoPlatformAccount;
      } else {
        error = BlackbaudSsoError.Unknown;
      }
    }
    
    // track initial login
    const jwt = this.parseJwt(response?.token);
    this.trackInitialLoginData(
      LoginMethod.SSO, 
      error === null,
      jwt?.UserId,
      jwt?.email);
    
    return error;
  }

  trackInitialLoginData (
    method: string,
    isSuccessful: boolean,
    userId: string = null,
    email: string = '',
    clientId?: number|null
  ) {
    if (!!this.heapService.getHeap()) {
      let userType = this.portal.getUserType();

      // only call identify on users that are known!
      if (!!userId) {
        this.heapService.identify(userId);
      }

      let userTypeString = '';
      switch (userType) {
        case 1:
          userTypeString = 'Manager';
          break;
        case 2:
          userTypeString = 'Applicant';
          break;
        case 3:
          userTypeString = 'Admin';
          break;
        default:
          userTypeString = '';
      }
      
      this.heapService.addUserProperties(
        {
          [TrackingPropertyNames.Email]: email?.toLowerCase(),
          [TrackingPropertyNames.UserType]: userTypeString
        });

      // ensure event properties are cleared before adding new
      this.heapService.clearEventProperties();

      // only add clientId if provided
      if (!!clientId && clientId !== 0) {
        this.heapService.addEventProperties(
          {
            [TrackingPropertyNames.ClientId]: clientId
          });
      }

      if (method === LoginMethod.Impersonate) {
        this.heapService.addEventProperties(
          {
            [TrackingPropertyNames.IsImpersonated]: String(true)
          });
      }

      this.heapService.track(
        TrackingEventNames.Login,
        {
          [TrackingPropertyNames.Method]: method,
          [TrackingPropertyNames.Success]: String(isSuccessful)
        });
    }
  }
}
