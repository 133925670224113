import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { ProgramDetailFromApi } from '@core/typings/program.typing';
import { BulkDownloadGroup } from '@features/configure-forms/form.typing';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { YCModalComponent } from '@yourcause/common/modals';
import { uniq } from 'lodash';
import { ApplicationDownloadService } from '../application-download.service';
export interface AppDownloadBulkModalResponse {
  isMasked: boolean;
  groups: BulkDownloadGroup[];
  program: ProgramDetailFromApi;
  formIds: number[];
}

@Component({
  selector: 'gc-bulk-download-application-modal',
  templateUrl: './bulk-download-application-modal.component.html',
  styleUrls: ['./bulk-download-application-modal.component.scss']
})
export class BulkDownloadApplicationModalComponent extends YCModalComponent<AppDownloadBulkModalResponse> implements OnInit {
  @Input() applicationIds: number[];
  @Input() programId: number;
  @Input() workflowId: number;
  @Input() isNomination = false;

  bulkDownloadGroups: BulkDownloadGroup[];
  valid = false;
  program: ProgramDetailFromApi;
  notMasked = false;

  constructor (
    private applicationDownloadService: ApplicationDownloadService,
    private analyticsService: AnalyticsService,
    private spinnerService: SpinnerService
  ) {
    super();
  }

  async ngOnInit () {
    this.spinnerService.startSpinner();
    const response = await this.applicationDownloadService.prepareBulkDownloadModal(
      this.programId,
      this.workflowId
    );
    this.program = response.program;
    this.spinnerService.stopSpinner();

    this.bulkDownloadGroups = response.bulkDownloadGroups;

    this.handleCheckboxChange();
  }

  handleCheckboxChange () {
    let hasSelected = false;
    this.bulkDownloadGroups.forEach((item) => {
      item.forms.forEach((form) => {
        if (form.selected) {
          hasSelected = true;
        }
      });
    });

    this.valid = hasSelected;
  }

  getSelected () {
    return this.bulkDownloadGroups.filter((item) => {
      item.forms = item.forms.filter((form) => {
        return form.selected;
      });

      return item.forms.length > 0;
    });
  }

  onDownload () {
    let formIds: number[] = [];
    const groups = this.getSelected();
    groups.forEach((group) => {
      group.forms.forEach((form) => {
        if (!formIds.includes(form.formId)) {
          formIds.push(form.formId);
        }
      });
    });
    formIds = uniq(formIds);
    this.closeModal.emit({
      groups,
      isMasked: !this.notMasked,
      program: this.program,
      formIds
    });
    this.analyticsService.emitEvent({
      eventName: 'Bulk download applications',
      eventType: EventType.Click,
      extras: null
    });
  }
}
