import { AbstractControl } from '@angular/forms';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { ValidatorMessageObj } from '@yourcause/common/form-control-validation';


export function DataSetPercentageSumValidator (
  componentHelper: ComponentHelperService
) {
  return (control: AbstractControl) => {
    const val = control.value as ReferenceFieldsUI.TableResponseRowForUi[];
    const errorObj: ValidatorMessageObj = {
      dataSetMinMaxError: {
        i18nKey: 'common:textMustAddUpTo100',
        defaultValue: 'Must add up to 100%',
        errorMessage: 'Must add up to 100%'
      }
    };
    const sumOfFields = componentHelper.getFieldGroupTotal(val);

    const valid = sumOfFields === 100;

    return valid ? null : errorObj;
  };

}
