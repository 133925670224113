import { BasicFormForUi, FormData, ProgramFormForUi, ProgramFormFromApi, WorkflowLevelFormApi } from '@features/configure-forms/form.typing';
import { EmailNotificationType } from '@features/system-emails/email.typing';
import { CyclesAPI } from './api/cycles.typing';
import { PaymentStatus } from './status.typing';
import { Translation } from './translation.typing';
import { CyclesUI } from './ui/cycles.typing';
import { Workflow } from './workflow.typing';

export enum NomProgramSteps {
  Details = 0,
  Settings = 1,
  Cycles = 2,
  Forms = 3,
  Workflow = 4,
  GrantProgram = 5,
  Communications = 6
}

export enum GrantProgramSteps {
  Details = 0,
  Settings = 1,
  Cycles = 2,
  Forms = 3,
  Workflow = 4,
  Communications = 5
}

export enum ProgramApplicantType {
  INDIVIDUAL = 1,
  ORGS = 2,
  ORGS_WITH_BUCKET = 3
}

export enum CanOrgSubmitMultipleAppsOptions {
  Yes = 0,
  CanApplyOncePerProgram = 1,
  CanApplyOncePerCycle = 2,
  CanApplyIfPreviousCycleNotAwarded = 3
}

export enum ProgramTypes {
  GRANT = 1,
  NOMINATION = 2
}

export interface ProgramForUi extends Program {
  alwaysOpen: boolean;
}

export interface Program {
  grantProgramId: string;
  grantProgramGuid: string;
  grantProgramName: string;
  grantProgramDescription: string;
  startDate: string;
  endDate: string;
  active: boolean;
  isDraft: boolean;
  timezoneId: string;
  programApplicantType: ProgramApplicantType;
  charityBucketId: string;
  allowCollaboration: boolean;
  notifyNominators: boolean;
  allowAddOrg: boolean;
  maskApplicantInfo: boolean;
  programType: ProgramTypes;
  isTiedToNominationPrograms: boolean;
  defaultLanguageId: string;
  isArchived: boolean;
  numberOfGrantProgramCycles: number;
  senderDisplayName: string;
  daysBeforeEndDateReminders: number;
  grantProgramCycles: CyclesAPI.BaseProgramCycle[];
  numberOfPayments: number;
  paymentsTotal: number;
  inviteOnly: boolean;
  complete: boolean;
  defaultFormId: number;
  workflowId: number;
  defaultWorkflowLevelId: number;
  clientId: number;
  reserveFunds: boolean;
  autoDeclineBudgetAssignment: boolean;
  numberOfApplications: number;
  requestAmount: number;
  numberOfAwards: number;
  awardsTotal: number;
  multipleApplicationsLimitType: CanOrgSubmitMultipleAppsOptions;
  autoDeclineBudgetAssignmentSendEmail: boolean;
  autoDeclineBudgetAssignmentDeclinationComment: string;
  allowApplicationCopyForApplicant: boolean;
}

export interface ProgramForDashboard {
  grantProgramId: number;
  grantProgramGuid: string;
  grantProgramName: string;
  grantProgramDescription: string;
  startDate: string;
  endDate: string;
  imageName: string;
  active: boolean;
  isDraft: boolean;
  clientId: number;
  workflowId: number;
  defaultWorkflowLevelId: number;
  programApplicantType: ProgramApplicantType;
  allowCollaboration: boolean;
  sendEmailsToCollaborators: boolean;
  nofityNominators: boolean;
  allowAddOrg: boolean;
  programType: ProgramTypes;
  isTiedToNominationPrograms: boolean;
  maskApplicantInfo: boolean;
  logoName: string;
  numberOfApplications: number;
  numberOfAwards: number;
  numberOfPayments: number;
  awardTotal: number;
  paymentsTotal: number;
  requestAmount: number;
  isArchived: boolean;
  numberOfGrantProgramCycles: number;
  timezoneId: string;
}

export interface ProgramDetailBase {
  hasDraftApplication: boolean;
  grantProgramId: number;
  grantProgramName: string;
  grantProgramDescription: string;
  grantProgramGuidelines: string;
  charityBucketId: string;
  charityBucketDescription: string;
  startDate: string;
  endDate: string;
  timezoneId: string;
  active: boolean;
  imageUrl: string;
  clientName: string;
  clientId: number;
  clientLogoUrl: string;
  programApplicantType: ProgramApplicantType;
  eligibilityForm: ProgramFormFromApi;
  eligibilityPassMessage: string;
  eligibilityFailMessage: string;
  notifyNominators: boolean;
  allowAddOrg: boolean;
  logoUrl: string;
  nameLanguageTranslations: Translation[];
  descriptionLanguageTranslations: Translation[];
  guidelinesLanguageTranslations: Translation[];
  isArchived: boolean;
  cycles: CyclesAPI.BaseProgramCycle[];
  hideCycleDatesInApplicantPortal: boolean;
  inviteOnly: boolean;
  multipleApplicationsLimitType: CanOrgSubmitMultipleAppsOptions;
  multipleApplicationsLimitMessage: string;
  allowApplicationCopyForApplicant: boolean;
}

export interface ProgramDetailApi extends ProgramDetailBase {
  form: ProgramFormFromApi;
}

export interface ProgramDetail extends ProgramDetailBase {
  form: ProgramFormForUi;
  allowedOrgLocations: AllowedLocation[];
}

export interface ProgramDeadline {
  id?: number;
  name: string;
  date: string;
  description: string;
  createdDate?: string;
  programName?: string;
}

export interface ProgramDeadlineForApi {
  id?: number;
  title: string;
  date: string;
  notes: string;
}

export interface ConfigureProgramMap {
  [x: string]: ConfigureProgram;
}

export interface ConfigureProgram {
  id?: number;
  name: string;
  description: string;
  guidelines: string;
  imageName: string;
  logoName: string;
  image: any;
  logo: any;
  imageUrlOrBase64: string;
  logoUrlOrBase64: string;
  timezoneId: string;
  charityBucketId?: string;
  charityBucketDescriptions?: string;
  eligibilityForm?: number;
  masterResponse?: MasterResponse;
  defaultForm: number;
  workflow: number;
  defaultLevel: number;
  workflowFormMap?: WorkflowFormMap;
  applicantType: ProgramApplicantType;
  allowCollaboration: boolean;
  deadlines: ProgramDeadline[];
  successMessage: string;
  failMessage: string;
  hasApplications: boolean;
  canChangeDefaultForm: boolean;
  maskApplicantInfo: boolean;
  clientTemplates?: EmailTemplate[];
  emailNotificationTypesPreferences?: ProgramEmailPreference[];
  useProgramLogo?: boolean;
  senderDisplayName: string;
  defaultLanguage: string;
  daysBeforeEndDateReminders: number;
  isArchived: boolean;
  hideCycleDatesInApplicantPortal?: boolean;
  originalCycles: CyclesAPI.ConfigureProgramCycle[];
  cycles: CyclesAPI.ConfigureProgramCycle[];
  inviteOnly: boolean;
  isPublished: boolean;
  draftValidityAlert?: string;
  publishedValidityAlert?: string;
  reserveFunds: boolean;
  autoDeclineBudgetAssignment: boolean;
  autoDeclineBudgetAssignmentSendEmail: boolean;
  autoDeclineBudgetAssignmentDeclinationComment: string;
  hidePaymentStatus: boolean;
  alternatePaymentStatusText: string;
  multipleApplicationsLimitType: CanOrgSubmitMultipleAppsOptions;
  multipleApplicationsLimitMessage: string;
  allowedOrgLocations: AllowedLocation[];
  // For nominations
  grantPrograms?: number[];
  notifyNominators?: boolean;
  allowAddOrg?: boolean;
  formDueReminderFrequencyForApplicant?: number;
  formDueReminderFrequencyForReviewer?: number;
  formOverDueReminderFrequencyForApplicant?: number;
  formOverDueReminderFrequencyForReviewer?: number;
  // When autosaving, if they clear the name on a published program,
  // we can't save in that invalid state
  // so send the last valid program name
  lastValidProgramName?: string;
  allowApplicationCopyForApplicant: boolean;
}

export interface WorkflowFormMap {
 [x: string]: WorkflowLevelFormApi[];
}

export interface MasterResponse {
  grantProgramId: number;
  formRevisionId: number;
  masterResponse: FormData;
}

export interface MasterFormResponse {
  formRevisionId: number;
  masterResponse: any;
}

export interface CreateEditProgramApi {
  id?: number;
  name: string;
  description: string;
  guidelines: string;
  imageName: string;
  workflow: number;
  defaultWorkflowLevel: number;
  defaultFormId: number;
  deadlines: ProgramDeadlineForApi[];
  programApplicantType: number;
  allowCollaboration: boolean;
  useProgramLogo: boolean;
  sendEmailsToCollaborators?: boolean;
  charityBucketId?: string;
  charityBucketDescription?: string;
  programType?: ProgramTypes;
  grantProgramsForNominationProgram?: number[];
  nofityNominators?: boolean;
  allowAddOrg?: boolean;
  clientEmailTemplates?: EmailTemplate[];
  maskApplicantInfo?: boolean;
  logoName: string;
  emailNotificationTypesPreferences?: ProgramEmailPreference[];
  senderDisplayName: string;
  defaultLanguageId: string;
  daysBeforeEndDateReminders: number;
  timezone: string;
  hideCycleDatesInApplicantPortal: boolean;
  inviteOnly: boolean;
  saveAsDraft: boolean;
  formDueReminderFrequencyForApplicant: number;
  formDueReminderFrequencyForReviewer: number;
  formOverDueReminderFrequencyForApplicant: number;
  formOverDueReminderFrequencyForReviewer: number;
  reserveFunds: boolean;
  autoDeclineBudgetAssignment: boolean;
  autoDeclineBudgetAssignmentSendEmail: boolean;
  autoDeclineBudgetAssignmentDeclinationComment: string;
  hidePaymentStatus: boolean;
  alternatePaymentStatusText: string;
  multipleApplicationsLimitType: CanOrgSubmitMultipleAppsOptions;
  multipleApplicationsLimitMessage: string;
  allowApplicationCopyForApplicant: boolean;
  allowedOrgLocations: AllowedLocation[];
}


export interface ProgramEmailPreference {
  id: EmailNotificationType;
  isExcluded: boolean;
}

export interface DisabledProgramEmail {
  id: EmailNotificationType;
  name: string;
  subject: string;
  description: string;
  maxCopies: number;
}

export interface EmailTemplate {
  emailNotificationType: EmailNotificationType;
  templates: ProgramClientEmailTemplate[];
}

export interface ProgramClientEmailTemplate {
  id: number;
  grantProgramId: number;
  clientEmailTemplateId: number;
  active: boolean;
  default: boolean;
}

export interface EligibilityProgramMessage {
  language: string;
  translation: string;
}

export interface CharityBucketDescriptions {
  language: string;
  translation: string;
}

export interface ProgramDetailFromApi {
  id: number;
  name: string;
  description: string;
  guidelines: string;
  charityBucketId?: string;
  charityBucketDescription: string;
  imageName: string;
  logoName: string;
  timezoneId: string;
  maxAwardsAllowed: number;
  maxValueForSingleAward: number;
  defaultFormId: number;
  additionalForms: number[];
  workflowId: number;
  defaultWorkflowLevelId: number;
  deadlines: ProgramDeadline[];
  imageUrl: string;
  logoUrl: string;
  programApplicantType: number;
  allowCollaboration: boolean;
  sendEmailsToCollaborators: boolean;
  eligibilityPassMessage: string;
  eligibilityFailMessage: string;
  workflowLevelForms?: WorkflowLevelFormApi[];
  hasApplications: boolean;
  grantProgramsForNominationProgram: number[];
  notifyNominators: boolean;
  allowAddOrg: boolean;
  canChangeDefaultForm: boolean;
  maskApplicantInfo: boolean;
  useProgramLogo: boolean;
  defaultLanguageId: string;
  daysBeforeEndDateReminders: number;
  isArchived: boolean;
  showCycles?: boolean;
  cycles: CyclesUI.ProgramCycle[];
  inviteOnly: boolean;
  formDueReminderFrequencyForApplicant: number;
  formDueReminderFrequencyForReviewer: number;
  formOverDueReminderFrequencyForApplicant: number;
  formOverDueReminderFrequencyForReviewer: number;
  reserveFunds: boolean;
  autoDeclineBudgetAssignment: boolean;
  autoDeclineBudgetAssignmentSendEmail: boolean;
  autoDeclineBudgetAssignmentDeclinationComment: string;
  hidePaymentStatus: boolean;
  alternatePaymentStatusText: string;
  clientId: number;
  multipleApplicationsLimitType: CanOrgSubmitMultipleAppsOptions;
  multipleApplicationsLimitMessage: string;
  allowApplicationCopyForApplicant: boolean;
}

export interface AllowedLocation {
  countryId: number;
  regionIds: number[];
}

export interface OfflineProgramDetail extends ProgramDetailFromApi {
  clientId: number;
  allowedOrgLocations: AllowedLocation[];
}

export interface OfflineProgramDetailFromApi extends ProgramDetailFromApi {
  clientId: number;
  form: ProgramFormFromApi;
}

export interface SimpleDataHubProgram {
  id: number;
  name: string;
}

export interface ProgramDashboardMap {
  [x: string]: ProgramDashboardDetail;
}

export interface ProgramDashboardDetail {
  detail: ProgramDetailFromApi;
  programStats: ProgramStats;
  paymentStats: PaymentStats;
  stats: ProgramTopLevelStats;
}

export interface ProgramStats {
  amountRequestedInProgressApplications: AmountBreakdown;
  amountFromApprovedApplications: AmountBreakdown;
  amountRequestedDeclinedApplications: AmountBreakdown;
  amountRequestedDraftApplications: AmountBreakdown;
  totalAmountRequestedAllApplications: number;
  totalNumberOfApplications: number;
}

export interface AmountBreakdown {
  count: number;
  amount: number;
}

export interface PaymentStats {
  amountNoAwards: number;
  remainingAward: number;
  pending: number;
  paid: number;
  scheduled: number;
  processing: number;
  outstanding: number;
  voided: number;
}

export interface ProgramExport {
  exportModel: ProgramImport;
  fileUrl: string;
}


export interface ProgramImport {
  grantPrograms: { name: string; id: number }[];
  workFlows: { name: string; id: number }[];
  budgets: { name: string; id: number }[];
  fundingSources: { name: string; id: number }[];
  forms: { name: string; id: number }[];
  formRevision: { formId: number; formDefinition: string }[];
  categories: { name: string; id: number }[];
  referenceFields: { name: string; id: number }[];
  picklists: { name: string; id: number }[];
}

export interface ProgramTopLevelStats {
  numberOfApplications: number;
  requestAmount: number;
  awardsTotal: number;
  paymentsTotal: number;
  numberOfAwards: number;
  numberOfPayments: number;
}

export interface ProgramStatusStat {
  statusName: string;
  requestAmount: number;
  numberOfApplications: number;
}

export interface ProgramStatusStatApi {
  status: number;
  statusInfo: {
    requestAmount: number;
    numberOfApplications: number;
  };
}

export interface PaymentStatusStat {
  statusName: string;
  statusId: PaymentStatus;
  paymentsTotal: number;
  numberOfPayments: number;
}

export interface PaymentStatusStatApi {
  status: number;
  statusInfo: {
    paymentsTotal: number;
    numberOfPayments: number;
  };
}
export interface ArchiveProgramPayload {
  grantProgramId: number;
  archive: boolean;
  archiveReasonCode: number;
  archiveNotes: string;
}
export enum ProgramDropdownOptionsForInsights {
  ALL = 'all',
  ARCHIVED = 'archived',
  ACTIVE = 'active'
}

export interface CreateProgramModalResponse {
  name: string;
  defaultLang: string;
  workflowId: number;
  defaultFormId: number;
}

export interface GrantProgramCycleBudgetFundingSource	{
  grantProgramCycleId: number;
  grantProgramCycleBudgetFundingSources: {
    budgetId: number;
    fundingSourceId: number;
  }[];
}

export enum ProgramChangeTypes {
  Standard = 1,
  Cycle = 2,
  ProgramForm = 3,
  WorkflowForm = 4,
  AllowedOrgLocations = 5
}

export interface ProgramChange {
  prop: keyof ConfigureProgram;
  changeType: ProgramChangeTypes;
  workflowFormAddOrUpdate?: WorkflowLevelFormApi;
  workflowLevelFormToRemove?: WorkflowLevelFormApi;
}

export interface ProgramFormPayload {
  additionalForms: number[];
  defaultFormId: number;
  grantProgramId: number;
  masterFormResponses: MasterFormResponse[];
  eligibilityPassMessage: string;
  eligibilityFailMessage: string;
  defaultLanguageId: string;
}

export interface ProgramCyclesPayload {
  grantProgramCycles: CyclesAPI.ProgramCycleForApi[];
  grantProgramId: number;
  grantProgramDefaultLanguageId: string;
  programType: ProgramTypes;
}

export interface ProgramWorkflowLevelFormPayload {
  workflowId: number;
  grantProgramId: number;
  grantProgramType: ProgramTypes;
  grantProgramWorkflowLevelForm: WorkflowLevelFormApi;
  defaultFormId: number;
}

export interface WorkflowFormSortUpdate {
  id: number;
  adaptedFormsArray: WorkflowLevelFormApi[];
  movedForm1: WorkflowLevelFormApi;
  movedForm2: WorkflowLevelFormApi;
}

export interface SimpleProgramCycle {
  id: number;
  startDate: string;
  endDate: string;
  isArchived: boolean;
  isClientProcessing: boolean;
}

export interface ProgramFlyoutInfo {
  program: Program;
  programDetail: ProgramDetailFromApi;
  workflow: Workflow;
  requestForm: BasicFormForUi;
  eligibilityForm: BasicFormForUi;
}

