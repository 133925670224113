import { FormDefinitionForUi } from '@features/configure-forms/form.typing';
import { AutomationAPI } from '../api/automation.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

export const FOUND_IN_CDT = 'FOUND_IN_CDT';

export const NOT_FOUND_IN_CDT = 'NOT_FOUND_IN_CDT';

export namespace Automation {
  export enum MajorityOptions {
    One = 1,
    Majority = 2,
    All = 3
  }

  export interface FormForRuleObject {
    id: number;
    name: string;
    formDefinition: FormDefinitionForUi[];
  }

  export interface SequenceArg {
    workflowLevelAutomationRuleSetId: number;
    sequence: number;
  }

  export interface RuleSetForDisplay<T> {
    detail: T;
    rules: DisplayRule[];
  }

  export interface DisplayRule {
    column: string;
    value: string;
  }
  
  export interface CriteriaFormState<O = string, C = string> {
    object: ObjectConfig<O>;
    column: ObjectColumnConfig<C>;
    values: CriteriaValueFormState[];
    isRemoved: boolean;
    err?: Error;
    ruleLabel?: string;
  }

  export type NegatedType = boolean|typeof FOUND_IN_CDT|typeof NOT_FOUND_IN_CDT;

  export interface CriteriaValueFormState {
    greaterThanId: number;
    lessThanId: number;
    valueId: number;
    comparisonNegated: NegatedType;
    comparisonLessThan: number|string|Date;
    comparisonGreaterThan: number|string|Date;
    comparisonValue: string|boolean|MajorityOptions|number;
    comparisonType: CriteriaComparisonTypes;
    isRemoved: boolean;
  }
  export enum CriteriaComparisonTypes {
    Default = -1,
    Last365Days = AutomationAPI.AutomationRuleComparisonTypes.Last365Days,
    LastWeek = AutomationAPI.AutomationRuleComparisonTypes.LastWeek,
    LastMonth = AutomationAPI.AutomationRuleComparisonTypes.LastMonth,
    LastYear = AutomationAPI.AutomationRuleComparisonTypes.LastYear,
    ThisMonth = AutomationAPI.AutomationRuleComparisonTypes.ThisMonth,
    ThisWeek = AutomationAPI.AutomationRuleComparisonTypes.ThisWeek,
    ThisYear = AutomationAPI.AutomationRuleComparisonTypes.ThisYear,
    Today = AutomationAPI.AutomationRuleComparisonTypes.Today,
    Tomorrow = AutomationAPI.AutomationRuleComparisonTypes.Tomorrow,
    Yesterday = AutomationAPI.AutomationRuleComparisonTypes.Yesterday
  }

  export enum Comparisons {
    NumberRange,
    DateRange,
    IsSelected,
    IsUploaded,
    SelectIs,
    TextIs,
    Majority,
    NumberIs
  }

  interface BaseConfig<T> {
    label: string;
    /* Used for identifying config for applying custom logic */
    key: T;
  }

  export interface CoreObjectColumnType<T = any> extends BaseConfig<T> {
    removeObjectName?: boolean;
  }

  export interface BaseObjectColumnType<T = any> extends CoreObjectColumnType<T> {
    comparison: Exclude<Comparisons, Comparisons.SelectIs>;
  }

  export interface ObjectColumnSelectType<C = any> extends CoreObjectColumnType<C> {
    comparison: Comparisons.SelectIs;
    options: TypeaheadSelectOption[];
  }

  export interface ObjectColumnIsSelected<C = any> extends CoreObjectColumnType<C> {
    comparison: Comparisons.IsSelected;
    options?: TypeaheadSelectOption[];
  }

  export type ObjectColumnConfig<C = any> = ObjectColumnSelectType<C>|ObjectColumnIsSelected<C>|BaseObjectColumnType<C>;

  export interface ObjectConfig<O = any, C = any> extends BaseConfig<O> {
    columns: ObjectColumnConfig<C>[];
  }

  export type RuleWithValues = AutomationAPI.SaveAutomationRuleSetExpressionModel&{values?: CriteriaValueFormState[]};
}
