import { Component, Input, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { BaseApplication } from '@core/typings/application.typing';
import { FormFieldChangeIndicatorClass, FormFieldChangeIndicatorIcon } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { TypeSafeFormBuilder } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { BaseFormComponent } from '../../base/base.component';

@Component({
  selector: 'gc-form-designation',
  templateUrl: './form-designation.component.html',
  styleUrls: ['form-designation.component.scss']
})
export class FormDesignationComponent extends BaseFormComponent<string> {
  @Input() disabled: boolean;

  customLabelIcon = FormFieldChangeIndicatorIcon;
  customLabelIconTooltip = this.i18n.translate(
    'common:textThisFieldWasUpdatedByTheApplicant',
    {},
    'This field was updated by the applicant'
  );
  customLabelIconClass = FormFieldChangeIndicatorClass;

  constructor (
    public formBuilder: TypeSafeFormBuilder,
    public formBuilderService: FormBuilderService,
    public componentHelper: ComponentHelperService,
    private i18n: I18nService,
    @Optional() formGroupDir: FormGroupDirective,
    @Optional() renderer: FormRendererComponent<BaseApplication>
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  dataChanged (value: string) {
    this.onValueChange.emit({
      value,
      updateFormGroup: false
    });
  }
}
