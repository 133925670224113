<ng-container *ngIf="formComponent">
  <legend >
    {{ translations ?
      translations[formComponent.legend] || formComponent.legend :
      formComponent.legend }}
  </legend>
  <div class="card-body"
    [class.editable]="editable"
    cdkDropList
    [cdkDropListSortingDisabled]="false"
    [cdkDropListDisabled]="!editable"
    [cdkDropListData]="formComponent.components"
    (cdkDropListDropped)="handleDropEvent($event)">

    <!-- No components -->
    <gc-form-builder-empty-container *ngIf="editable && !formComponent.components?.length">
    </gc-form-builder-empty-container>

    <div *ngFor="let component of formComponent.components; trackBy: trackBy"
      cdkDrag
      [cdkDragData]="component"
      [class.mb-4]="!component.isHidden && !component.hiddenFromParent">
      <gc-form-component-display
        [depth]="depth"
        [inConfigModal]="inConfigModal"
        [formGroup]="formGroup"
        [editable]="editable"
        [component]="component | ycAs: $baseComponent"
        [formId]="parentFields.formId"
        [notAutoSave]="notAutoSave"
        [value]="component.value"
        [masked]="masked"
        [translations]="translations"
        [disabledOverride]="disabledOverride"
        [showErrorSummary]="showErrorSummary"
        [errorMessages]="errorMessages"
        [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
        [isManagerForm]="isManagerForm"
        [parentFields]="parentFields"
        [isFormBuilderView]="isFormBuilderView"
        [refIdsChanged]="refIdsChanged"
        [standardFieldsChanged]="standardFieldsChanged"
        (componentDropped)="handleDropEvent($event)"
        (onValueChange)="valueChanged($event, component)"
        (componentActionClick)="componentActionClick.emit($event)">
      </gc-form-component-display>
    </div>
  </div>
</ng-container>
