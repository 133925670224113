<yc-modal 
  [modalHeader]="isNomination ?
    ('common:textDownloadNominations' | ycI18n: 'Download Nominations') :
    ('common:textDownloadApplications' | ycI18n: 'Download Applications')"
  [primaryButtonText]="'common:textDownload' | ycI18n: 'Download'"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  [primaryDisabled]="!valid"
  (onPrimaryClick)="onDownload()"
  (onCancel)="closeModal.emit()">

  <yc-alert
    [message]="isNomination ?
      ('common:textDownloadNomPDFAlert3' | ycI18n: 'Select the nomination data you want to download as a PDF. All form attachments will automatically be included in the download as a zip file.') :
      ('common:textDownloadAppPDFAlert2' | ycI18n: 'Select the application forms for the application you want to download as a PDF. All form attachments will automatically be included in the download as a zip file.')"
    alertClass="info">
  </yc-alert>

  <div *ngFor="let group of bulkDownloadGroups">
    <div class="mt-3">
      <!-- Parent WFL Name -->
      <span *ngIf="group.parentWorkflowLevelName"
        class="d-block">
        {{ group.parentWorkflowLevelName }}
      </span>
      <strong>
        <!-- Workflow Level Name -->
        <ng-container *ngIf="group.workflowLevelName">
          {{ group.workflowLevelName }}
        </ng-container>
      </strong>
    </div>

    <!-- Selectable Forms by Level -->
    <div *ngFor="let form of group.forms" 
      class="d-flex justify-content-between mt-3">
      <div class="d-flex">
        <yc-checkbox [noMargin]="true" 
          [(value)]="form.selected" 
          [optionLabel]="form.formName"
          [optionLabelSrOnly]="true"
          (valueChange)="handleCheckboxChange()">
        </yc-checkbox>
        {{ form.formName }}
      </div>
    </div>
  </div>

  <div class="mt-3">
    <strong>
      <yc-i18n
        key="common:textMasking"
        defaultValue="Masking">
      </yc-i18n>
    </strong>
    <div class="mt-3">
      <yc-checkbox
        name="notMasked"
        [(value)]="notMasked" 
        [label]="'common:textMasking' | ycI18n: 'Masking'"
        [srOnlyLabel]="true"
        [optionLabel]="'common:textIncludeMaskedFormDataInDownload' | ycI18n: 'Include masked form data in download'">
      </yc-checkbox>
    </div>
  </div>
</yc-modal>