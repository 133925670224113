<div class="table-responsive">
  <table class="table">
    <tbody>
      <tr *ngFor="let rowComponents of formComponent.rows">
        <td *ngFor="let componentObj of rowComponents"
          class="grid"
          [class.editable]="editable"
          cdkDropList
          [cdkDropListSortingDisabled]="false"
          [cdkDropListDisabled]="!editable"
          [cdkDropListData]="componentObj.components"
          (cdkDropListDropped)="handleDropEvent($event)">

          <!-- No components -->
          <gc-form-builder-empty-container *ngIf="editable &&
            !componentObj.components?.length">
          </gc-form-builder-empty-container>

          <div *ngFor="let component of componentObj.components trackBy: trackBy"
            cdkDrag
            [cdkDragData]="component">
            <gc-form-component-display
              [depth]="depth"
              [formGroup]="formGroup"
              [editable]="editable"
              [component]="component | ycAs: $baseComponent"
              [notAutoSave]="notAutoSave"
              [formId]="parentFields.formId"
              [value]="component.value"
              [inConfigModal]="inConfigModal"
              [disabledOverride]="disabledOverride"
              [translations]="translations"
              [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
              [isManagerForm]="isManagerForm"
              [masked]="masked"
              [showErrorSummary]="showErrorSummary"
              [errorMessages]="errorMessages"
              [refIdsChanged]="refIdsChanged"
              [isFormBuilderView]="isFormBuilderView"
              [standardFieldsChanged]="standardFieldsChanged"
              [parentFields]="parentFields"
              (onValueChange)="valueChanged($event, component)"
              (componentDropped)="handleDropEvent($event)"
              (componentActionClick)="componentActionClick.emit($event)">
            </gc-form-component-display>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>