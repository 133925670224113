<yc-alert *ngIf="readOnly && totalNumberOfFieldsChanged > 0"
  [message]="'common:textNumberOfFieldsChangedDynamic' | ycI18n: '__numberChanged__ out of __total__ fields have been updated.': {
    numberChanged: totalNumberOfFieldsChanged,
    total: totalNumberOfFieldsOnForm
  }"
  [alertClass]="'info'">
</yc-alert> 

<div *ngIf="showTabs && !!conditionalVisibilityState">
  <yc-horizontal-nav-tabs
    [tabs]="tabs"
    [customWidthPercentage]="'85%'"
    [hideTabsIfOnlyOne]="true"
    [useRoutes]="false"
    [hasTabOverflow]="true"
    (onActiveTabChanged)="activeTabChanged($event)">
  </yc-horizontal-nav-tabs>
</div>

<div class="yc-layout-table"
  [ycBlockUi]="loading">
  <ng-container *ngIf="isAfterInit"
    [formGroup]="formGroupMap[tabIndex]">
    <gc-form-instance
      [formId]="formId"
      [editable]="editable"
      [isFormBuilderView]="isFormBuilderView"
      [notAutoSave]="notAutoSave"
      [hiddenCompKeys]="hiddenCompKeys"
      [parentFields]="parentFields"
      [masked]="masked"
      [labelMap]="labelMap"
      [refIdsChanged]="readOnly ? refIdsChanged : undefined"
      [standardFieldsChanged]="readOnly ? standardFieldsChanged : undefined"
      [tabFormGroup]="formGroupMap[tabIndex]"
      [showErrorSummary]="tabs[tabIndex].showErrorSummary"
      [readOnly]="readOnly"
      [isManagerForm]="isManagerForm"
      [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
      [translations]="translations"
      [formDefinition]="currentFormDefinition"
      (dropListsChanged)="handleDropListChange($event, tabIndex)"
      (onValueChange)="handleValueChange($event)"
      (componentActionClick)="componentActionClick.emit($event)"
      (componentDropped)="handleComponentDrop($event)">
    </gc-form-instance>
  </ng-container>

  <!-- Action Buttons -->

  <!-- Non Mobile -->
  <div class="d-none d-md-flex align-items-center pt-2"
    [class.justify-content-between]="!isFormBuilderView">
    <!-- Previous -->
    <div>
      <ng-template
        *ngTemplateOutlet="previousButton">
      </ng-template>
    </div>
    <div class="d-flex align-items-center">
      <!-- Next -->
      <ng-template
        *ngTemplateOutlet="nextButton">
      </ng-template>

      <!-- Submit -->
      <ng-template
        *ngTemplateOutlet="submitButton">
      </ng-template>
    </div>
  </div>

  <!-- Mobile -->
  <div class="d-flex d-md-none flex-column pt-5">
    <div class="d-flex align-items-center"
      [class.justify-content-between]="!isFormBuilderView">
      <!-- Previous -->
      <ng-template
        *ngTemplateOutlet="previousButton">
      </ng-template>
      <!-- Next -->
      <ng-template
        *ngTemplateOutlet="nextButton">
      </ng-template>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <!-- Submit -->
      <ng-template
        *ngTemplateOutlet="submitButton">
      </ng-template>
    </div>
  </div>
</div>


<!-- Templates -->
<ng-template #previousButton>
  <yc-button *ngIf="tabs.length > 1 &&
    tabIndex !== firstVisibleTabIndex"
    [link]="true"
    [langKey]="'common:textPrevious'"
    [defaultText]="'Previous'"
    (onClick)="previousTab()">
  </yc-button>
</ng-template>

<ng-template #nextButton>
  <yc-button *ngIf="tabs.length > 1 &&
    tabIndex !== lastVisibleTabIndex"
    [link]="true"
    [langKey]="'common:btnNext'"
    [defaultText]="'Next'"
    class="me-2"
    (onClick)="nextTab()">
  </yc-button>
</ng-template>

<ng-template #submitButton>
  <div *ngIf="!hideSubmitButton"
    class="ms-2 mt-4 mb-4">
    <yc-button
      [primary]="true"
      [disabled]="readOnly || disableSubmitButton"
      [langKey]="requireSignature ? 
        'common:textSignAndSubmit' :
        'common:btnSubmit'"
      [defaultText]="requireSignature ?
        'Sign and submit' :
        'Submit'"
      (onClick)="onSubmit()">
    </yc-button>
  </div>
</ng-template>
