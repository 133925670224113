import { Component, Input, OnInit } from '@angular/core';
import { ApplicantInfoForPDF, ApplicationInfoForPDF, FormComponentForPdf, FormInfoForPDF, OrgInfoForPDF } from '@core/typings/pdf.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ApplicationDownloadAnswerService } from '@features/application-download/application-download-answer.service';
import { Award } from '@features/awards/typings/award.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormDefinitionComponent, FormDefinitionForUi, FormStatuses } from '@features/configure-forms/form.typing';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { SimpleStringMap } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ArrayHelpersService } from '@yourcause/common/utils';

@Component({
  selector: 'gc-application-pdf',
  templateUrl: './application-pdf.component.html'
})
export class ApplicationPdfComponent implements OnInit {
  @Input() applicationId: number;
  @Input() isMasked: boolean;
  @Input() responsesMasked: boolean;
  @Input() applicants: ApplicantInfoForPDF[];
  @Input() isNomination: boolean;
  @Input() nominee: {
    name: string;
    email: string;
    address: string;
    phoneNumber: string;
  };
  @Input() orgInfo: OrgInfoForPDF;
  @Input() forms: FormInfoForPDF[];
  @Input() applicationInfo: ApplicationInfoForPDF;
  @Input() clientName?: string;
  @Input() logo: string = this.settings?.clientBranding?.logoUrl;
  @Input() defaultFormId: number;
  @Input() awards: Award[];
  @Input() isArchived: boolean;
  @Input() isForApplicantPortal: boolean;

  components: Record<string, FormComponentForPdf[]>;
  getFormAnswerMap: Record<number, (component: FormComponentForPdf) => any|any[]>;
  FormStatuses = FormStatuses;
  RefTypes = ReferenceFieldsUI.ReferenceFieldTypes;

  constructor (
    private settings: ClientSettingsService,
    private applicationDownloadAnswerService: ApplicationDownloadAnswerService,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private componentHelper: ComponentHelperService,
    private formHelper: FormHelperService
  ) { }

  getFormAnswer = (form: FormInfoForPDF) => (component: FormDefinitionComponent) => {
    const answer = this.applicationDownloadAnswerService.getAnswerFromForm(
      component,
      this.applicationInfo,
      form.referenceFields,
      form.decision,
      this.responsesMasked,
      form.reviewerRecommendedFundingAmount
    );

    return answer;
  };

  ngOnInit () {
    this.components = {};
    this.getFormAnswerMap = {};
    const formIdMap: SimpleStringMap<FormInfoForPDF[]> = {};
    let forms = this.arrayHelper.sortByAttributes(
      this.forms,
      'formId',
      'formSubmittedOn'
    );
    // Default form should be at the top
    const defaultFormIndex = forms.findIndex((form) => {
      return form.formId === this.defaultFormId;
    });
    if (defaultFormIndex > -1) {
      const defaultForm = forms[defaultFormIndex];
      forms = [
        ...forms.slice(0, defaultFormIndex),
        ...forms.slice(defaultFormIndex + 1)
      ];
      this.forms = [
        defaultForm,
        ...forms
      ];
    } else {
      this.forms = forms;
    }
    this.forms.forEach((form) => {
      const existing = formIdMap[form.formId];
      formIdMap[form.formId] = existing ?
        [
          ...existing,
          form
        ] :
        [form];
      this.componentHelper.applyTranslationsToComponents(
        form.formDefinition,
        form.translations,
        form.richTextTranslations
      );
      form.formDefinition.forEach((tab, index) => {
        const formResponseByTabKey = `${form.applicationFormId}_${index}`;
        const applicableComponents = this.formHelper.getApplicableComponentsForPdf(
          [{
            tabName: (tab as FormDefinitionForUi).tabName,
            components: (tab as FormDefinitionForUi).components,
            index,
            logic: null
          }],
          form.referenceFields
        );
        this.components[formResponseByTabKey] = applicableComponents;
      });
      this.getFormAnswerMap[form.applicationFormId] = this.getFormAnswer(form);
    });
    this.forms.forEach((form) => {
      const formsInSameWorkflowLevel = formIdMap[form.formId].filter(x => x.workflowLevelId === form.workflowLevelId);
      if (formsInSameWorkflowLevel.length > 1) {
        const index = formsInSameWorkflowLevel.findIndex((f) => {
          return f.applicationFormId === form.applicationFormId;
        });
        form.formHeader = `${form.formName} (${form.workflowLevelName}) (${
          this.i18n.translate(
            'common:textResponseNumberDynamic',
            {
              number: index + 1
            },
            'Response __number__'
          )
        })`;
      } else {
        form.formHeader = `${form.formName} (${form.workflowLevelName})`;
      }
    });
  }
}
