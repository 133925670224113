<yc-collapsible-section
  [header]="translations ?
    translations[formComponent.title] || formComponent.title :
    formComponent.title"
  [isCollapsed]="inFormBuilder ? false : formComponent.collapsed"
  [collapsible]="formComponent.collapsible">
  <ng-template #section>
    <div cdkDropList
      [cdkDropListSortingDisabled]="false"
      [class.editable]="editable"
      [cdkDropListDisabled]="!editable"
      [cdkDropListData]="formComponent.components"
      (cdkDropListDropped)="handleDropEvent($event)">

      <!-- No components -->
      <gc-form-builder-empty-container *ngIf="editable &&
        !formComponent.components?.length">
      </gc-form-builder-empty-container>


      <!-- Components -->
      <div *ngFor="let component of formComponent.components; trackBy: trackBy"
        cdkDrag
        [cdkDragData]="component"
        [class.mb-4]="!component.isHidden && !component.hiddenFromParent">
        <gc-form-component-display
          [depth]="depth"
          [inConfigModal]="inConfigModal"
          [formGroup]="formGroup"
          [editable]="editable"
          [component]="component | ycAs: $baseComponent"
          [formId]="parentFields.formId"
          [masked]="masked"
          [notAutoSave]="notAutoSave"
          [translations]="translations"
          [isManagerEditingApplicantForm]="isManagerEditingApplicantForm"
          [isManagerForm]="isManagerForm"
          [disabledOverride]="disabledOverride"
          [showErrorSummary]="showErrorSummary"
          [errorMessages]="errorMessages"
          [parentFields]="parentFields"
          [value]="component.value"
          [refIdsChanged]="refIdsChanged"
          [isFormBuilderView]="isFormBuilderView"
          [standardFieldsChanged]="standardFieldsChanged"
          (onValueChange)="valueChanged($event, component)"
          (componentActionClick)="componentActionClick.emit($event)"
          (componentDropped)="handleDropEvent($event)">
        </gc-form-component-display>
      </div>
    </div>
  </ng-template>
</yc-collapsible-section>
