import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseApplication } from '@core/typings/application.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormChangesWithCompKey, FormComponentGroup, FormDefinitionComponent, FormDefinitionForUi } from '@features/configure-forms/form.typing';
import { FormBuilderActionEvent } from '@features/forms/form-builder/form-builder.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { SimpleStringMap, TypeToken } from '@yourcause/common';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { ErrorForDisplay } from '../form-error-summary/form-error-summary.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'gc-form-instance',
  templateUrl: './form-instance.component.html',
  styleUrls: ['./form-instance.component.scss']
})
export class FormInstanceComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() formId: number;
  @Input() readOnly: boolean;
  @Input() masked: boolean;
  @Input() tabFormGroup: FormComponentGroup;
  @Input() hiddenCompKeys: string[];
  @Input() showErrorSummary: boolean;
  @Input() translations: SimpleStringMap<string>;
  @Input() formDefinition: FormDefinitionForUi;
  @Input() parentFields: Partial<BaseApplication>;
  @Input() notAutoSave: boolean;
  @Input() uploadRequestStatusText: string;
  @Input() editable: boolean;
  @Input() labelMap: Record<string, FormDefinitionComponent> = {};
  @Input() isManagerEditingApplicantForm: boolean;
  @Input() isManagerForm: boolean;
  @Input() refIdsChanged: number[];
  @Input() standardFieldsChanged: ReferenceFieldsUI.StandardFieldTypes[];
  @Input() isFormBuilderView: boolean;
  @Output() onValueChange = new EventEmitter<FormChangesWithCompKey>();
  @Output() componentDropped = new EventEmitter<CdkDragDrop<FormDefinitionComponent[]>>();
  @Output() componentActionClick = new EventEmitter<FormBuilderActionEvent>();
  @Output() dropListsChanged = new EventEmitter<CdkDropList[]>();

  @ViewChild('instanceDropList') dropList: CdkDropList;

  trackBy = (_: number, comp: FormDefinitionComponent) => comp.key;

  dropLists: {
    list: CdkDropList;
    depth: number;
  }[] = [];
  $baseComponent = new TypeToken<FormDefinitionComponent>();
  $dropLists = new TypeToken<CdkDropList[]>();
  forceErrorSummaryUpdate: boolean;
  afterInit = false;
  shouldFlatten = false;
  errorMessages: ErrorForDisplay[] = [];

  @HostListener('window:resize')
  setShouldFlatten () {
    this.shouldFlatten = window.innerWidth <= 768;
  }

  constructor (
    private arrayHelper: ArrayHelpersService,
    private formBuilderService: FormBuilderService
  ) {
    this.setShouldFlatten();
  }

  ngOnInit () {
    this.afterInit = true;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (this.afterInit && changes.showErrorSummary && this.showErrorSummary) {
      this.validChanged();
    }
  }

  ngAfterViewInit () {
    this.registerDropList(this.dropList, 0);
  }

  handleComponentDropped (drop: CdkDragDrop<FormDefinitionComponent[]>) {
    this.componentDropped.emit(drop);
  }

  registerDropList (list: CdkDropList, depth: number) {
    this.formBuilderService.patchDragDrop(list._dropListRef);
    this.dropLists.push({
      list,
      depth
    });
    this.reapplyDropListConnections();
  }

  deregisterDropList (listToRemove: CdkDropList) {
    this.dropLists = this.dropLists.filter(({ list }) => {
      return list !== listToRemove;
    });
    this.reapplyDropListConnections();
  }

  private reapplyDropListConnections () {
    const fullList: string[] = [];
    const sortedLists = this.arrayHelper.sort(this.dropLists, 'depth', false);

    const dropLists = sortedLists
      .map(({ list }) => {
        fullList.push(list.id);

        list.element.nativeElement.id = list.id;
        list.connectedTo = fullList;

        return list;
      });


    this.dropListsChanged.emit(dropLists);
  }

  valueChanged (details: FormChangesWithCompKey) {
    this.onValueChange.emit(details);
  }

  validChanged () {
    this.forceErrorSummaryUpdate = true;
    setTimeout(() => {
      this.forceErrorSummaryUpdate = false;
    });
  }
}
