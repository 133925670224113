export interface TokenPayload {
  email: string;
  clientIdentifier: string;
  multifactorAuthenticationCode?: string;
  rememberMe?: boolean;
}

export interface SSOToken {
  id_token: string;
  access_token: string;
  scope: string;
  session_state: string;
  state: string;
  code?: string;

  swapped_token: string;

  [x: string]: string;
}

export const Token_Refreshing_Storage_Key = 'Token_Is_Refreshing';

export interface TokenResponse {
  token: string;
  expiration: string;
  refreshToken: string;
  refreshTokenExpiration: string;
  bbidToken?: boolean;
  isBbgm?: boolean;
  svcId?: string;
  envId?: string;
  clientId?: number;
}

export interface SSOTokenResponse extends TokenResponse {
  subdomain: string;
}

export interface TokenContent {
  UserId: string;
  client_id: string;
  aud: string;
  email: string;
  exp: number;
  family_name: string;
  given_name: string;
  iss: string;
  jti: string;
  sub: string;
  typ: string;
  impersonated_by_user_id: string;
}

export enum BlackbaudSsoError {
  Unknown = 1,
  NoPlatformAccount = 2
}

export enum PortalTypes {
  Applicant = 'GrantPortal',
  GrantManager = 'GrantManager',
  Admin = 'AdminPortal'
}
