import { Component, OnInit, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { CurrencyService } from '@core/services/currency.service';
import { BaseApplication } from '@core/typings/application.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { TypeSafeFormBuilder } from '@yourcause/common/core-forms';
import { CurrencyValue } from '@yourcause/common/currency';
import { BaseFormComponent } from '../../base/base.component';

@Component({
  selector: 'gc-form-recommended-funding-amount',
  templateUrl: './form-recommended-funding-amount.component.html',
  styleUrls: ['./form-recommended-funding-amount.component.scss']
})
export class FormRecommendedFundingAmountComponent extends BaseFormComponent<CurrencyValue> implements OnInit {
  defaultCurrency = this.clientSettingsService.defaultCurrency;
  formattingData = this.currencyService.formattingData;
  afterInit = false;

  constructor (
    private clientSettingsService: ClientSettingsService,
    public formBuilder: TypeSafeFormBuilder,
    formBuilderService: FormBuilderService,
    public componentHelper: ComponentHelperService,
    private currencyService: CurrencyService,
    @Optional() formGroupDir: FormGroupDirective,
    @Optional() renderer: FormRendererComponent<BaseApplication>
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  ngOnInit () {
    super.ngOnInit();
    this.afterInit = true;
  }

  onAmountChange (amount = this.data.amountForControl) {
    this.control.markAsDirty();
    this.amountChanged(amount);
  }

  amountChanged (amount = this.data.amountForControl) {
    this.onValueChange.emit({
      value: {
        ...this.data,
        amountForControl: amount
      },
      updateFormGroup: true
    });
  }
}
