<yc-form-label
  [label]="label"
  [description]="description"
  [name]="compKey"
  [srOnly]="hideLabel"
  [customIcon]="showFieldChange ? customLabelIcon : ''"
  [customIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
  [customIconClass]="showFieldChange ? customLabelIconClass : ''">
</yc-form-label>

<div *ngFor="let row of data; let index = index; let last = last; trackBy: trackBy;">
  <ng-container *ngIf="itemOptions && itemOptions[index]">
    <div [class.last]="last"
      class="item-row"
      [class.display-value]="displayInKindValues"
      [class.no-value]="!displayInKindValues">
      <div class="d-flex flex-basis-95 flex-wrap align-items-end">
        <!-- Category -->
        <div *ngIf="showCategory" 
          class="col me-3">
          <yc-typeahead-select
            [items]="categoryOptions"
            [disabled]="isDisabled"
            [label]="'GLOBAL:textCategory' | ycI18n: 'Category'"
            [placeholder]="categoryPlaceholder"
            [(value)]="categoryMap[index]"
            [showClear]="false"
            (valueChange)="categoryChange($event, index)">
          </yc-typeahead-select>
        </div>
        
        <!-- Item Select -->
        <div class="col">
          <yc-typeahead-select
            [name]="row.itemIdentification"
            [(value)]="row.itemIdentification"
            [disabled]="isDisabled"
            [tabIndex]="index === 0 ? tabIndex : undefined"
            [placeholder]="'common:textSelectAnItem' | ycI18n: 'Select an item'"
            [items]="itemOptions[index]"
            [label]="'common:lblItem' | ycI18n: 'Item'"
            [srOnlyLabel]="false"
            [showClear]="false"
            (valueChange)="rowChanged()">
          </yc-typeahead-select>
        </div> 

        <!-- Item Count-->
        <div class="col">
          <yc-input
            [disabled]="isDisabled"
            [(value)]="row.count"
            [isNumber]="true"
            [name]="row.itemIdentification + '_count'"
            [min]="0"
            [label]="'GLOBAL:textUnitsRequested' | ycI18n: 'Units requested'"
            [srOnlyLabel]="false"
            [numberOfDecimals]="0"
            (valueChange)="rowChanged()">
          </yc-input>
        </div>
      
        <!-- Value per Item -->
        <div *ngIf="displayInKindValues"
          class="col">
          <yc-input
            [name]="row.itemIdentification + '_value'"
            [disabled]="true"
            [label]="'GLOBAL:textValuePerItem' | ycI18n: 'Value per item'"
            [value]="valueMap[row.itemIdentification] | gcMoney">
          </yc-input>
        </div>
        <!-- Total Value -->
        <div *ngIf="displayInKindValues"
          class="col">
          <yc-input
            [name]="row.itemIdentification + '_total'"
            [disabled]="true"
            [label]="'GLOBAL:textTotalValue' | ycI18n: 'Total value'"
            [value]="(valueMap[row.itemIdentification] * row.count) | gcMoney">
          </yc-input>
        </div>
      </div>

      <!-- Delete -->
      <yc-button *ngIf="!isDisabled && (data.length > 1)"
        class="flex-basis-5"
        [icon]="true"
        [fontAwesomeIcon]="'times'"
        [textDanger]="true"
        [tooltipText]="'common:textRemove' | ycI18n: 'Remove'"
        [ycTrackClick]="'Remove in kind item'"
        (onClick)="removeRow(index)">
      </yc-button>
    </div>
  </ng-container>
</div>

<!-- Add -->
<div class="mt-3">
  <yc-button *ngIf="allowMultiple && !isDisabled"
    [link]="true"
    [fontAwesomeIcon]="'plus'"
    [langKey]="data?.length > 0 ? 'common:btnSelectAnother' : 'GLOBAL:textAddAnItem'"
    [defaultText]="data?.length > 0 ? 'Select another' : 'Add an item'"
    (onClick)="addRow()">
  </yc-button>
</div>

<div *ngIf="showErrorSummary && errorMessages.length > 0"
  class="mb-4 text-danger">
  {{ errorMessages[0].errorMessage }}
</div>
