import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { AdHocReportingAPI, AdHocReportingAPI as API } from '@core/typings/api/ad-hoc-reporting.typing';
import { AudienceMember } from '@features/audience/audience.typing';
import { FormAudience } from '@features/configure-forms/form.typing';
import { PaginatedResponse } from '@yourcause/common';
import { TableDataDownloadFormat } from '@yourcause/common/files';

@Injectable({ providedIn: 'root' })
export class ReportingResources {
  constructor (
    private http: HttpRestService
  ) { }

  getFormComponents (formId: number) {
    return this.http.get<API.FormComponentSummary[]>(
      `api/datahub/forms/${formId}/schemas`
    );
  }

  getFormsForReports (
    programId: number,
    audience?: FormAudience
  ) {
    let endpoint = 'api/manager/reports/GetForms?programId=' +
      programId;

    if (audience) {
      endpoint += `&audienceType=${audience}`;
    }

    return this.http.get<API.FormDisplayModel[]>(endpoint);
  }

  updateReport (report: API.UpdateReportPayload) {
    return this.http.post<number>(
      'api/manager/reports/UpdateUserSavedReport',
      report
    );
  }

  createReport (report: API.CreateReportPayload) {
    return this.http.post<number>(
      'api/manager/reports/InsertUserSavedReport',
      report
    );
  }

  getReports () {
    return this.http.get<API.UserSavedReport[]>(
      'api/manager/reports/GetAllUserSavedReportsForCompany'
    );
  }

  getReportById (id: number) {
    return this.http.get<API.UserSavedReport>(
      'api/manager/reports/GetUserSavedReportById/' + id
    );
  }

  getSharedUsersForReport (id: number): Promise<AudienceMember[]> {
    const endpoint = `api/manager/reports/GetSavedReportUsers/${id}`;

    return this.http.get(endpoint);
  }

  getSchedulesForReport (reportId: number): Promise<API.ReportScheduleFromApi[]> {
    const endpoint = `api/manager/reports/GetScheduledReport/${reportId}`;

    return this.http.get(endpoint);
  }

  saveSharedUsersForReport (payload: API.SaveSharedReportUsersPayload) {
    const endpoint = 'api/manager/reports/SaveReportUsers';

    return this.http.post(endpoint, payload);
  }

  removeReportById (id: number) {
    return this.http.delete(
      'api/manager/reports/DeleteUserSavedReport/' + id
    );
  }

  getReadOnlyReport (
    paginationOptions: AdHocReportingAPI.AdvancedPaginationOptionsModel<any>,
    endpoint: string,
    reportIdForViewOnly: number,
    exportFileTypeId: TableDataDownloadFormat,
    includeBom = true,
    delimiter = ','
  ): string|any {
    if (!!exportFileTypeId) {
      return this.http.post<PaginatedResponse<any>>(
        'api/manager/reports/GetReadOnly' + endpoint + 'ReportDownload',
        {
          paginationOptions,
          reportId: reportIdForViewOnly,
          exportFileTypeId,
          includeBom,
          delimiter
        }
      );
    } else {
      return this.http.post<PaginatedResponse<any>>(
        'api/manager/reports/GetReadOnly' + endpoint + 'Report',
        {
          paginationOptions,
          reportId: reportIdForViewOnly
        }
      );
    }
  };

  getReportRows (
    paginationOptions: AdHocReportingAPI.AdvancedPaginationOptionsModel<any>,
    endpoint: string,
    formIds: number[],
    formDataFields: string[],
    baseFormId: number,
    userSavedReportColumnList: API.UserSavedReportColumn[] = [],
    reportIdForViewOnly: number,
    referenceFieldIds: number[] = [],
    referenceFieldTableId: number,
    fileExportTypeId: TableDataDownloadFormat
  ) {
    if (reportIdForViewOnly) {
      return this.getReadOnlyReport(
        paginationOptions,
        endpoint,
        reportIdForViewOnly,
        fileExportTypeId
      );
    } else {
      return this.http.post<PaginatedResponse<any>>(
        'api/manager/reports/' + endpoint,
        {
          paginationOptions,
          formIds,
          formDataFields,
          userSavedReportColumnList,
          baseFormId,
          referenceFieldIds,
          referenceFieldTableId
        }
      );
    }
  }

  sendReport (payload: API.SendReportPayload) {
    const endpoint = 'api/manager/reports/SendReport';

    return this.http.post(endpoint, payload);
  }

  addScheduledReport (payload: API.ScheduleReportPayload) {
    const endpoint = 'api/manager/reports/AddScheduledReport';

    return this.http.post(endpoint, payload);
  }

  deleteScheduledReport (scheduleId: number) {
    const endpoint = `api/manager/reports/deleteScheduledReport/${scheduleId}`;

    return this.http.delete(endpoint);
  }

  updateScheduledReport (payload: API.ScheduleReportPayload) {
    const endpoint = 'api/manager/reports/UpdateScheduledReport';

    return this.http.post(endpoint, payload);
  }

  getDataFeeds (sftpId: number) {
    const endpoint = 'api/manager/reports/DataFeedLogs';

    return this.http.post(endpoint, { sftpId });
  }
}
