import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { ContactSupport } from '@core/typings/ui/support.typing';
import { EmailExtensionValidator } from '@yourcause/common/form-control-validation';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { TypeSafeFormBuilder, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { YCModalComponent } from '@yourcause/common/modals';
import { PanelTypes } from '@yourcause/common/panel';
export interface ContactSupportForm extends ContactSupport.ContactSupportModalReturn {
  appId: number;
}

@Component({
  selector: 'gc-contact-support-modal',
  templateUrl: './contact-support-modal.component.html',
  styleUrls: ['./contact-support-modal.component.scss']
})
export class ContactSupportModalComponent extends YCModalComponent<ContactSupport.ContactSupportModalReturn> implements OnInit {
  prepopData: ContactSupport.ContactSupportModalReturn;
  formGroup: TypeSafeFormGroup<ContactSupportForm>;
  PanelTypes = PanelTypes;
  contactReasonPostAuthOptions: TypeaheadSelectOption<ContactSupport.ContactReasonFullList>[];

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  async ngOnInit () {
    this.formGroup = this.formBuilder.group<ContactSupportForm>({
      contactReason: [this.prepopData.contactReason, Validators.required],
      firstName: [this.prepopData.firstName, Validators.required],
      lastName: [this.prepopData.lastName, Validators.required],
      phoneNumber: [this.prepopData.phoneNumber, Validators.required],
      clientName: [this.prepopData.clientName, Validators.required],
      appId: this.prepopData.applicationId,
      programName: this.prepopData.programName,
      organizationName: this.prepopData.organizationName,
      taxId: this.prepopData.taxId,
      description: [this.prepopData.description, Validators.required],
      email: [this.prepopData.email, [
        Validators.required,
        EmailExtensionValidator
      ]]
    });
  }

  onCancel () {
    this.closeModal.emit();
  }

  onSubmit () {
    const values = {
      ...this.formGroup.value,
      applicationId: this.formGroup.value.appId
    };
    this.closeModal.emit(values);
    this.analyticsService.emitEvent({
      eventName: 'Submit support ticket',
      eventType: EventType.Click,
      extras: null
    });
  }
}
