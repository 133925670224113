import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { BaseApplication } from '@core/typings/application.typing';
import { FormFieldChangeIndicatorClass, FormFieldChangeIndicatorIcon } from '@features/configure-forms/form.typing';
import { IsValidTypes, ValidationTypes } from '@features/forms/component-configuration/component-configuration.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { InKindService } from '@features/in-kind/in-kind.service';
import { InKindCategoryItemStat, InKindRequestedItem } from '@features/in-kind/in-kind.typing';
import { UserService } from '@features/users/user.service';
import { TypeSafeFormBuilder, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { uniq } from 'lodash';
import { Subscription } from 'rxjs';
import { BaseFormComponent } from '../../base/base.component';

@Component({
  selector: 'gc-form-in-kind-amount-requested',
  templateUrl: './form-in-kind-amount-requested.component.html',
  styleUrls: ['./form-in-kind-amount-requested.component.scss']
})
export class FormInKindAmountRequestedComponent extends BaseFormComponent<InKindRequestedItem[]> implements OnInit, OnDestroy {
  @Input() items: string[] = [];
  @Input() displayInKindValues: boolean;
  @Input() maxItems: number;
  @Input() allowMultiple: boolean;
  @Input() validationErrorMessage: string;
  @Input() validationType: ValidationTypes;
  @Input() willBeValid: IsValidTypes;
  @Input() validationAmount: number;
  @Input() validationItem: string;
  @Input() showCategory: boolean;

  fetchedItems: InKindCategoryItemStat[];
  itemOptions: {
    [index: number]: TypeaheadSelectOption<string>[];
  } = {};
  categoryOptions: TypeaheadSelectOption<number>[];
  // categoryMap right side is categoryId, left side is item selector index from row of formControls
  categoryMap: {
    [itemIndex: number]: number;
  } = {};
  valueMap: Record<string, number> = {};
  categoryPlaceholder = this.i18n.translate(
    'common:textPleaseSelectACategory',
    {},
    'Please select a category'
  );
  sub = new Subscription();
  customLabelIcon = FormFieldChangeIndicatorIcon;
  customLabelIconTooltip = this.i18n.translate(
    'common:textThisFieldWasUpdatedByTheApplicant',
    {},
    'This field was updated by the applicant'
  );
  customLabelIconClass = FormFieldChangeIndicatorClass;

  constructor (
    private inKindService: InKindService,
    private userService: UserService,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private portal: PortalDeterminationService,
    public formBuilder: TypeSafeFormBuilder,
    public componentHelper: ComponentHelperService,
    public formBuilderService: FormBuilderService,
    @Optional() renderer: FormRendererComponent<BaseApplication>,
    @Optional() formGroupDir: FormGroupDirective
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  async ngOnInit () {
    super.ngOnInit();
    if (!this.data || !this.data.length) {
      this.data = [{
        itemIdentification: null,
        count: 0
      }];
    }
    this.items = this.items || [];
    const additionalItems: string[] = [];
    this.data.forEach((item) => {
      const found = this.items.find((i) => {
        return item.itemIdentification === i;
      });
      if (!found) {
        additionalItems.push(item.itemIdentification);
      }
    });
    this.fetchedItems = await this.inKindService.getItemsById(
      uniq(this.items.concat(additionalItems)),
      this.parentFields.programId,
      this.userService.currentUser.culture
    );
    this.valueMap = this.inKindService.getValueMap(this.fetchedItems);
    this.setupItems(false);
  }

  trackBy (_: number, row: InKindRequestedItem) {
    return row.itemIdentification;
  }

  categoryChange (categoryId: number, indexOfItemSelector: number) {
    this.categoryMap = {
      ...this.categoryMap,
      [indexOfItemSelector]: categoryId
    };
    this.setupItems(true);
  }

  getCategories () {
    const allCategories = this.portal.isManager ?
      this.inKindService.categoriesForForm :
      this.inKindService.categoriesForApplicant;

    return allCategories;
  }

  setupItems (clearItemSelection: boolean) {
    const availableItems: string[] = [];
    this.itemOptions = this.data.reduce((acc, selectedItem, index) => ({
      ...acc,
      [index]: this.fetchedItems.filter(fetchedItem => {
        let inUse = fetchedItem.inUse;
        if (selectedItem.itemIdentification === fetchedItem.identification) {
          inUse = true; // allow already selected items to persist
        }
        const previouslyPickedthisItem = fetchedItem.identification === selectedItem.itemIdentification;
        const pickedItemInAnotherLevel = this.data.some(_item => {
          return _item.itemIdentification === fetchedItem.identification;
        });
        const categories = this.getCategories();
        const selectedCategory = categories.find((x) => x.id === this.categoryMap[index]);
        if (previouslyPickedthisItem && !clearItemSelection) {
          this.categoryMap[index] = fetchedItem.categoryId;
        }
        const passCategoryCheck = (
          !this.showCategory || (previouslyPickedthisItem && !clearItemSelection)
        ) ?
          true :
          selectedCategory ? (fetchedItem.categoryId === selectedCategory.id) : false;

        return inUse &&
          (previouslyPickedthisItem || !pickedItemInAnotherLevel) &&
          passCategoryCheck;
      }).map<TypeaheadSelectOption<string>>((item => {
        if (!availableItems.includes(item.identification)) {
          availableItems.push(item.identification);
        }

        return {
          value: item.identification,
          label: item.name
        };
      }))
    }), {});
    if (availableItems.length === 1 && this.data.length === 1 && !this.data[0].itemIdentification) {
      // if one option is available and they have not made a selection, default to only available option
      this.data = [{
        itemIdentification: availableItems[0],
        count: 0
      }];
    }
    this.setCategoryOptions();
  }

  setCategoryOptions () {
    const allCategories = this.getCategories();
    const categoriesInUse = allCategories.filter((category) => {
      return this.fetchedItems.map((item) => item.categoryId).includes(category.id);
    });
    const categoryOptions = this.arrayHelper.sort(categoriesInUse.map((cat) => {
      return {
        value: cat.id,
        label: cat.name
      };
    }), 'label');

    this.categoryOptions = categoryOptions;
  }

  rowChanged () {
    this.dataChanged();
    this.setupItems(false);
  }

  dataChanged () {
    let isValid = true;
    if (this.willBeValid) {
      const passedCustomValidation = this.validate();
      isValid = isValid && (
        (this.willBeValid === IsValidTypes.Valid) ?
          passedCustomValidation :
          !passedCustomValidation
      );
    }
    if (this.comp) {
      let isValidForApi = true;
      this.data.forEach((item) => {
        if (!item.itemIdentification || !item.count) {
          isValidForApi = false;
        }
      });
      // Don't trigger change unless valid for api
      if (isValidForApi) {
        this.emitData();
      }
    }
  }

  emitData () {
    this.onValueChange.emit({
      value: this.data,
      updateFormGroup: true
    });
  }

  addRow () {
    const itemToAdd: InKindRequestedItem = {
      itemIdentification: null,
      count: 0
    };

    this.data = [
      ...this.data,
      itemToAdd
    ];
    this.dataChanged();

    this.setupItems(false);
  }

  removeRow (index: number) {
    this.data = [
      ...this.data.slice(0, index),
      ...this.data.slice(index + 1)
    ];
    this.dataChanged();

    this.setupItems(false);
  }


  // START VALIDATOR FNs

  validate () {
    switch (this.validationType) {
      case ValidationTypes.HasSelectedItem:
        return this.validateSelectedOneOf();
      case ValidationTypes.HasSelectedQuantity:
        return this.validateHasSelectedThisMany();
      case ValidationTypes.QuantityEqualTo:
      case ValidationTypes.QuantityGreaterThan:
      case ValidationTypes.QuantityLessThan:
        return this.validateTotalValueOf();
    }
  }

  validateSelectedOneOf () {
    return this.data.some(item => item.itemIdentification === this.validationItem);
  }

  validateTotalValueOf () {
    const totalValue = this.data.reduce((acc, selectedItem) => {
      return acc + (+selectedItem.count || 0);
    }, 0);

    if (this.validationType === ValidationTypes.QuantityEqualTo) {
      return this.validationAmount === totalValue;
    } else if (this.validationType === ValidationTypes.QuantityLessThan) {
      return totalValue < this.validationAmount;
    } else if (this.validationType === ValidationTypes.QuantityGreaterThan) {
      return totalValue > this.validationAmount;
    }

    return false;
  }

  validateHasSelectedThisMany () {
    return this.data.length === this.validationAmount;
  }

  // END VALIDATOR FNs

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}

