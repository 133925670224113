import { Injectable } from '@angular/core';
import { ApplicantSummaryApi } from '@core/typings/applicant.typing';
import { NonprofitDetail } from '@core/typings/organization.typing';
import { SpecialHandling, SpecialHandlingFromApi } from '@features/forms/form-renderer-components/standard-form-components/form-special-handling/form-special-handling.component';
import { NonprofitService } from '@features/nonprofit/nonprofit.service';
import { AddressFormatterService } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ApplicationFileService } from './application-file.service';

@Injectable({ providedIn: 'root' })
export class SpecialHandlingService {

  constructor (
    private nonprofitService: NonprofitService,
    private addressFormatter: AddressFormatterService,
    private applicationFileService: ApplicationFileService,
    private i18n: I18nService
  ) { }

  getSpecialHandling (
    handling: SpecialHandlingFromApi
  ): SpecialHandling {
    return {
      name: handling?.specialHandlingName || '',
      address1: handling?.specialHandlingAddress1 || '',
      address2: handling?.specialHandlingAddress2 || '',
      city: handling?.specialHandlingCity || '',
      state: handling?.specialHandlingStateProvinceRegion || '',
      postalCode: handling?.specialHandlingPostalCode || '',
      country: handling?.specialHandlingCountry || '',
      notes: handling?.specialHandlingNotes || '',
      reason: handling?.specialHandlingReason || '',
      fileUrl: handling?.specialHandlingFileUrl || ''
    };
  }

  async getOrgSpecialHandling (
    organizationId: number,
    nonprofitGuid: string,
    organizationName: string
  ) {
    let defaultName = '';
    let defaultAddress1 = '';
    let defaultAddress2 = '';
    let defaultCity = '';
    let defaultState = '';
    let defaultCountry = '';
    let defaultZip = '';
    let orgAddressString = '';
    let nonprofitDetail: NonprofitDetail;
    if (!!organizationId) {
      if (!!nonprofitGuid) {
        const response = await this.nonprofitService.getNonprofitAdditionalDataByGuid(
          nonprofitGuid
        );
        nonprofitDetail = response.nonprofitDetail;
        if (!!nonprofitDetail) {
          const address = this.nonprofitService.getNonprofitAddressFields(nonprofitDetail, true);
          orgAddressString = this.addressFormatter.format(address, true);
          defaultName = nonprofitDetail.name || organizationName || '',
          defaultAddress1 = address.address1 || '';
          defaultAddress2 = address.address2 || '';
          defaultCity = address.city || '';
          defaultState = address.stateProvRegCode || '';
          defaultCountry = address.country || '';
          defaultZip = address.postalCode || '';
        }
      }
    }

    return {
      defaultAddress: {
        name: defaultName,
        address1: defaultAddress1,
        address2: defaultAddress2,
        city: defaultCity,
        state: defaultState,
        postalCode: defaultZip,
        country: defaultCountry,
        notes: '',
        reason: '',
        fileUrl: ''
      },
      orgAddressString,
      nonprofitDetail
    };
  }

  async getDefaultSpecialHandling (
    organizationId: number,
    nonprofitGuid: string,
    organizationName: string,
    applicant: ApplicantSummaryApi
  ): Promise<{
    defaultAddress: SpecialHandling;
    orgAddressString: string;
    nonprofitDetail: NonprofitDetail;
  }> {
    if (!!organizationId) {
      return this.getOrgSpecialHandling(organizationId, nonprofitGuid, organizationName);
    } else if (!!applicant) {
      return {
        defaultAddress: {
          name: (applicant.firstName + ' ' + applicant.lastName) || '',
          address1: applicant.address || '',
          address2: applicant.address2 || '',
          city: applicant.city || '',
          state: applicant.state || '',
          postalCode: applicant.postalCode || '',
          country: applicant.country || '',
          notes: '',
          reason: '',
          fileUrl: ''
        },
        orgAddressString: '',
        nonprofitDetail: null
      };
    }

    return {
      defaultAddress: null,
      orgAddressString: '',
      nonprofitDetail: null
    };
  }

  hasSpecialHandling (specialHandling: SpecialHandling) {
    return !!(specialHandling?.name &&
      specialHandling?.address1 &&
      specialHandling?.city &&
      specialHandling?.state &&
      specialHandling?.postalCode &&
      specialHandling?.country);
  }

  isSameAsDefault (
    handling: SpecialHandling,
    defaultHandling: SpecialHandling,
    checkName = false
  ): boolean {
    const addressSame = handling?.address1 === defaultHandling?.address1 &&
      handling?.address2 === defaultHandling?.address2 &&
      handling?.city === defaultHandling?.city &&
      handling?.state === defaultHandling?.state &&
      handling?.country === defaultHandling?.country &&
      handling?.postalCode === defaultHandling?.postalCode;

    if (!checkName) {
      return addressSame;
    } else {
      return addressSame && handling?.name === defaultHandling?.name;
    }
  }

  isSpecialHandlingValid (handling: SpecialHandling) {
    if (handling) {
      const isOn = handling.name ||
        handling.address1 ||
        handling.city ||
        handling.state ||
        handling.postalCode;
      if (isOn) {
        return !!(handling.name &&
          handling.address1 &&
          handling.city &&
          handling.state &&
          handling.country &&
          handling.postalCode);
      }
    }

    return true;
  }

  getSpecialHandlingForSave (
    handlingToSave: SpecialHandling,
    defaultHandling: SpecialHandling
  ) {
    const isSameAsDefault = this.isSameAsDefault(
      handlingToSave,
      defaultHandling
    );

    return {
      specialHandlingName: isSameAsDefault ?
        '' :
        handlingToSave?.name ?? '',
      specialHandlingAddress1: isSameAsDefault ?
        '' :
        handlingToSave?.address1 ?? '',
      specialHandlingAddress2: isSameAsDefault ?
        '' :
        handlingToSave?.address2 ?? '',
      specialHandlingCity: isSameAsDefault ?
        '' :
        handlingToSave?.city ?? '',
      specialHandlingStateProvinceRegion: isSameAsDefault ?
        '' :
        handlingToSave?.state ?? '',
      specialHandlingCountry: isSameAsDefault ?
        '' :
        handlingToSave?.country ?? '',
      specialHandlingPostalCode: isSameAsDefault ?
        '' :
        handlingToSave?.postalCode ?? '',
      specialHandlingNotes: isSameAsDefault ?
        '' :
        handlingToSave?.notes ?? '',
      specialHandlingReason: isSameAsDefault ?
        '' :
        handlingToSave?.reason ?? '',
      specialHandlingFileUrl: isSameAsDefault ?
        '' :
        handlingToSave?.fileUrl ?? ''
    };
  }

  /**
   *
   * @param handling: special handling
   * @returns the special handling string
   */
  getSpecialHandlingAnswer (
    handling: SpecialHandling
  ) {
    let answer;
    if (handling && handling.address1) {
      answer = this.addressFormatter.format(handling);
      if (handling.reason) {
        const reasonText = this.i18n.translate('common:textReason', {}, 'Reason');
        answer = answer + `<br> ${reasonText}: ${handling.reason}`;
      }
      if (handling.notes) {
        const notesText = this.i18n.translate('GLOBAL:NOTES', {}, 'Notes');
        answer = answer + `<br> ${notesText}: ${handling.notes}`;
      }
      if (handling.fileUrl) {
        const file = this.applicationFileService.breakDownloadUrlDownToObject(
          handling.fileUrl
        );
        if (file?.fileName) {
          const seeFileName = this.i18n.translate(
          'common:textSeeFileNameDynamic',
          {
            fileName: file.fileName
          },
          'See __fileName__'
        );
          answer = answer + `<br> Supporting document: ${seeFileName}`;
        }
      }
    }

    return answer;
  }

  /**
   * 
   * @param handlingFromParent: Special Handling Data
   * @returns if special handling exists
   */
  parentHasHandling (
    handlingFromParent: SpecialHandling
  ) {
    return !!(
      handlingFromParent && (
        handlingFromParent.name ||
        handlingFromParent.address1 ||
        handlingFromParent.city ||
        handlingFromParent.state ||
        handlingFromParent.country ||
        handlingFromParent.postalCode
      )
    );
  }
}
