import { Component, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { AddressFormGroup } from '@core/components/address-block/address-block.component';
import { ApplicationFileService } from '@core/services/application-file.service';
import { SpinnerService } from '@core/services/spinner.service';
import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { BaseApplication } from '@core/typings/application.typing';
import { ApplicationAttachmentService } from '@features/application-view/application-attachments/application-attachments.service';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormComponentGroup, FormFieldChangeIndicatorClass, FormFieldChangeIndicatorIcon } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { TypeSafeFormBuilder } from '@yourcause/common/core-forms';
import { OnFileDownloadInfo, YcFile } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { BaseFormComponent } from '../../base/base.component';
import { FILE_LABEL, NAME_LABEL, NOTES_LABEL, REASON_LABEL, SPECIAL_HANDLING_DESC, SPECIAL_HANDLING_FIELDS_MAP, SPECIAL_HANDLING_REQUIRED_DESC } from './special-handling.constants';
interface SpecialHandlingFormGroup extends AddressFormGroup {
  handlingName?: string;
  handlingOn?: boolean;
  notes?: string;
  reason?: string;
  file?: YcFile<File>[];
}

export interface SpecialHandling {
  name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  notes?: string;
  fileUrl: string;
  reason: string;
}

export interface SpecialHandlingFromApi {
  specialHandlingName: string;
  specialHandlingAddress1: string;
  specialHandlingAddress2: string;
  specialHandlingCity: string;
  specialHandlingCountry: string;
  specialHandlingPostalCode: string;
  specialHandlingStateProvinceRegion: string;
  specialHandlingNotes: string;
  specialHandlingFileUrl: string;
  specialHandlingReason: string;
}

export const BlankSpecialHandling: SpecialHandling = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
  notes: '',
  fileUrl: '',
  reason: ''
};

@Component({
  selector: 'gc-form-special-handling',
  templateUrl: './form-special-handling.component.html',
  styleUrls: ['./form-special-handling.component.scss']
})
export class FormSpecialHandlingComponent extends BaseFormComponent<SpecialHandling> implements OnInit, OnDestroy {
  @Input() disabled: boolean;
  @Input() requireFileUpload: boolean;
  @Input() requireReason: boolean;
  @Input() specialHandlingInstructions: string;

  SPECIAL_HANDLING_DESC = SPECIAL_HANDLING_DESC;
  SPECIAL_HANDLING_REQUIRED_DESC = SPECIAL_HANDLING_REQUIRED_DESC;
  sub = new Subscription();
  invalidSpecialHandlingError = this.i18n.translate(
    'APPLY:textPleaseEnsureAllRequiredFieldsAreCompleted',
    {},
    'Please ensure all required fields are completed'
  );
  showSpecialHandling = this.clientSettingsService.clientSettings?.clientFeatures?.includes(APIAdminClient.ClientFeatureTypes.AllowApplicantSpecialHandling);
  NAME_LABEL = NAME_LABEL;
  FILE_LABEL = FILE_LABEL;
  NOTES_LABEL = NOTES_LABEL;
  REASON_LABEL = REASON_LABEL;
  invalidText: string;
  customLabelIcon = FormFieldChangeIndicatorIcon;
  customLabelIconTooltip = this.i18n.translate(
    'common:textThisFieldWasUpdatedByTheApplicant',
    {},
    'This field was updated by the applicant'
  );
  customLabelIconClass = FormFieldChangeIndicatorClass;

  constructor (
    @Optional() formGroupDir: FormGroupDirective,
    private clientSettingsService: ClientSettingsService,
    private i18n: I18nService,
    private applicationFileService: ApplicationFileService,
    private applicationAttachmentService: ApplicationAttachmentService,
    private spinnerService: SpinnerService,
    public formBuilder: TypeSafeFormBuilder,
    public formBuilderService: FormBuilderService,
    public componentHelper: ComponentHelperService,
    private logger: LogService,
    private notifier: NotifierService,
    @Optional() formRenderer: FormRendererComponent<BaseApplication>
  ) {
    super(formRenderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  get specialHandlingGroup () {
    return this.control.value as FormComponentGroup;
  }

  ngOnInit () {
    super.ngOnInit();
    let previousChanges = this.specialHandlingGroup.value;
    this.sub.add(this.specialHandlingGroup.valueChanges.subscribe((changes) => {
      if (!isEqual(changes, previousChanges)) {
        if (
          !changes.handlingOn &&
          changes.handlingOn !== previousChanges.handlingOn
        ) {
          previousChanges = changes;
          this.clearFormGroupValues();
          this.kickOffUpdate();
        } else {
          previousChanges = changes;
          this.kickOffUpdate();
        }
      }
    }));

    this.prepareForTranslate();
  }

  clearFormGroupValues () {
    this.specialHandlingGroup.setValue({
      handlingOn: false,
      handlingName: '',
      address1: '',
      address2: '',
      city: '',
      stateProvRegCode: '',
      countryCode: '',
      postalCode: '',
      notes: '',
      reason: '',
      file: []
    });
  }

  getHandlingFromFormGroup () {
    if (this.specialHandlingGroup) {
      const formVal = this.specialHandlingGroup.value;
      if (formVal.handlingOn) {
        const handling: SpecialHandling = {
          name: formVal.handlingName || '',
          address1: formVal.address1 || '',
          address2: formVal.address2 || '',
          city: formVal.city || '',
          state: formVal.stateProvRegCode || '',
          country: formVal.countryCode || '',
          postalCode: formVal.postalCode || '',
          notes: formVal.notes || '',
          reason: formVal.reason || '',
          fileUrl: !!formVal.file[0] ? formVal.file[0].fileUrl : ''
        };
        if (this.isEmpty(handling)) {
          return this.setDefaultHandling();
        } else {
          return handling;
        }
      }
    }

    return BlankSpecialHandling;
  }

  prepareForTranslate () {
    // This logic is to overwite the existing label and instructions to our new language of Alternate Address
    if (this.label === 'Special Handling') {
      this.label = 'Alternate Address';
      this.comp.label = this.label;
    }
    if (
      !this.specialHandlingInstructions ||
      this.specialHandlingInstructions === 'Select this option if this application requires payment to a special address or name. If an organization is receiving funds and has ACH set up then special handling will send payments by check and not ACH.'
    ) {
      this.specialHandlingInstructions = SPECIAL_HANDLING_DESC;
      this.comp.specialHandlingInstructions = SPECIAL_HANDLING_DESC;
    }

    this.translations['Alternate Address'] = this.translations['Alternate Address'] ||
      'Alternate Address';
    this.translations[this.specialHandlingInstructions] = this.translations[this.specialHandlingInstructions] || this.specialHandlingInstructions;
    this.translations[NOTES_LABEL] = this.translations[NOTES_LABEL] || NOTES_LABEL;
    this.translations[FILE_LABEL] = this.translations[FILE_LABEL] || FILE_LABEL;
    this.translations[REASON_LABEL] = this.translations[REASON_LABEL] || REASON_LABEL;

    if (Object.keys(this.translations).length === 0) {
      this.setDefaultTranslations();
    }
  }

  setDefaultTranslations () {
    this.translations = {
      [this.label]: this.label,
      [this.specialHandlingInstructions]: this.specialHandlingInstructions,
      [SPECIAL_HANDLING_REQUIRED_DESC]: SPECIAL_HANDLING_REQUIRED_DESC,
      ...SPECIAL_HANDLING_FIELDS_MAP
    };
  }

  setDefaultHandling () {
    if (this.parentFields?.defaultSpecialHandling) {
      const defaultHandling = this.parentFields.defaultSpecialHandling ||
        {} as SpecialHandling;
      const formValue: SpecialHandlingFormGroup = {
        handlingOn: true,
        handlingName: defaultHandling.name || '',
        address1: defaultHandling.address1 || '',
        address2: defaultHandling.address2 || '',
        city: defaultHandling.city || '',
        stateProvRegCode: defaultHandling.state || '',
        countryCode: defaultHandling.country || '',
        postalCode: defaultHandling.postalCode || '',
        notes: defaultHandling.notes || '',
        reason: defaultHandling.reason || '',
        file: []
      };
      // Set value will mark all controls as dirty,
      // but since in this scenario they just clicked the handling on checkbox
      // they should all be pristine
      setTimeout(() => {
        Object.keys(formValue).map((key) => {
          return key as keyof SpecialHandlingFormGroup;
        }).forEach((key) => {
          const control = this.specialHandlingGroup.get(key as any);
          control.setValue(formValue[key]);
          control.markAsPristine();
        });
      });

      return defaultHandling;
    }

    return BlankSpecialHandling;
  }

  kickOffUpdate () {
    const value = this.getHandlingFromFormGroup();
    if (this.comp && this.parentFields) {
      this.onValueChange.emit({
        value,
        updateFormGroup: false
      });
    }
  }

  getIsValid (isOn: boolean, handling: SpecialHandling): boolean {
    if (isOn) {
      return !!(handling &&
        handling.address1 &&
        handling.city &&
        handling.state &&
        handling.country &&
        handling.postalCode);
    }

    return true;
  }

  isEmpty (handling = {} as SpecialHandling) {
    return !handling.name &&
      !handling.address1 &&
      !handling.address2 &&
      !handling.city &&
      !handling.state &&
      !handling.postalCode &&
      !handling.notes &&
      !handling.fileUrl &&
      !handling.reason;
  }

  uploadFile = async (file: YcFile) => {
    if (
      !!this.parentFields.applicationId &&
      !!this.parentFields.applicationFormId
    ) {
      this.spinnerService.startSpinner();
      try {
        const _file = (file as YcFile<File>).file;
        const fileUrl = await this.applicationFileService.uploadFile(
          this.parentFields.applicationId,
          this.parentFields.applicationFormId,
          _file,
          file.fileName,
          null
        );
        if (!!fileUrl) {
          const fileDetail = this.applicationFileService.breakDownloadUrlDownToObject(fileUrl);
          file = new YcFile<File>(
            file.fileName,
            _file,
            fileUrl,
            +fileDetail.fileId
          );
        } else {
          file = null;
        }
      } catch (e) {
        this.logger.error(e);
        this.notifier.error(this.i18n.translate(
          'common:textErrorUploadingFile',
          {},
          'There was an error uploading the file'
        ));

        file = null;
      }
      this.spinnerService.stopSpinner();
    }

    return file;
  };

  downloadFile = async (info: OnFileDownloadInfo) => {
    this.spinnerService.startSpinner();
    if (info.openFileInsteadOfDownload) {
      await this.applicationAttachmentService.openReferenceFieldFromUrl(info.file?.fileUrl);
    } else {
      await this.applicationAttachmentService.downloadReferenceFieldFile(info.file?.fileUrl);
    }
    this.spinnerService.stopSpinner();
  }

  requiredSpecialHandlingValidator () {
    return (group: AbstractControl) => {
      const value = group.value[this.compKey];
      if (value?.handlingOn) {
        const address1 = value.address1;
        const city = value.city;
        const stateProvRegCode = value.stateProvRegCode;
        const countryCode = value.countryCode;
        const postalCode = value.postalCode;
        const reason = value.reason;
        const file = value.file;
        const requiredError = {
          required: {
            i18nKey: 'common:textThisInputIsRequired',
            defaultValue: 'This input is required'
          }
        };
        const returnVal = {} as any;
        if (!address1) {
          returnVal.address1 = requiredError;
        }
        if (!city) {
          returnVal.city = requiredError;
        }
        if (!stateProvRegCode) {
          returnVal.stateProvRegCode = requiredError;
        }
        if (!countryCode) {
          returnVal.countryCode = requiredError;
        }
        if (!postalCode) {
          returnVal.postalCode = requiredError;
        }
        if (!reason && this.requireReason) {
          returnVal.reason = requiredError;
        }
        if (!file && this.requireFileUpload) {
          returnVal.file = requiredError;
        }
        const hasErrors = Object.keys(returnVal).length > 0;

        return hasErrors ? returnVal : null;
      } else {
        return null;
      }
    };
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
