import { AbstractControl } from '@angular/forms';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { ValidatorMessageObj } from '@yourcause/common/form-control-validation';

export function DataSetRequireAllValidator (
  totalNumberOfRows: number,
  customErrorMessage: string,
  defaultErrorMessage: string
) {
  return (control: AbstractControl) => {
    const val = control.value;
    const errorObj: ValidatorMessageObj = {
      dataSetMinMaxError: {
        i18nKey: '',
        defaultValue: '',
        errorMessage: customErrorMessage || defaultErrorMessage
      }
    };

    const columns = val && val[0] ?
      val[0].columns :
      [];
    const numberOfRowsWithAnswers = columns
      .filter((column: ReferenceFieldAPI.ApplicationRefFieldResponse) => {
        return !!column.value || column.value === 0;
      }).length;

    const valid = totalNumberOfRows === numberOfRowsWithAnswers;

    return valid ? null : errorObj;
  };

}
