import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { BaseApplication } from '@core/typings/application.typing';
import { FormDefinitionComponent, FormValueChange } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { ErrorForDisplay } from '@features/forms/form-renderer/form-error-summary/form-error-summary.component';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { isEqual, isUndefined } from 'lodash';

@Component({
  template: ''
})
export class BaseFormComponent<T> implements OnInit {
  @Input({ required: true }) formGroup: TypeSafeFormGroup<any>;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() description = '';
  @Input() tooltipText = '';
  @Input() translations: Record<string, string>;
  @Input() defaultVal: string;
  @Input() hideLabel: boolean;
  @Input() tabIndex: number;
  @Input() showFieldChange: boolean;
  @Input() isFormBuilderView: boolean;

  protected _data: T;
  protected saveDisabled = false;
  protected _dirty = false;

  @Input()
  public get data (): T {
    return this._data;
  }
  public set data (value: T) {
    if (!isEqual(this._data, value)) {
      this._data = value;
    }
  }

  @Input() formComponent: FormDefinitionComponent;
  @Input() parentFields: Partial<BaseApplication>;
  @Input() isForSetValue: boolean;
  @Input() inConfigModal: boolean;
  @Input() emitInitialValidity: boolean;
  @Input() showErrorSummary: boolean;
  @Input() errorMessages: ErrorForDisplay[] = [];
  @Input() disabledOverride: boolean;
  @Input() disabled: boolean;
  @Output() dataChange = new EventEmitter<T>();

  @Output() onValueChange = new EventEmitter<FormValueChange>();

  compKey: string;

  constructor (
    public renderer: FormRendererComponent<BaseApplication>,
    public formGroupDir: FormGroupDirective,
    public formBuilder: TypeSafeFormBuilder,
    public formBuilderService: FormBuilderService,
    public componentHelper: ComponentHelperService
  ) { }

  get dirty (): boolean {
    return this._dirty;
  }
  set dirty (dirty: boolean) {
    this._dirty = dirty;
  }

  get comp () {
    return this.formComponent;
  }

  get control () {
    return this.formGroup?.get(this.compKey);
  }

  get inFormBuilder () {
    return this.formBuilderService.inFormBuilder;
  }

  get isDisabled () {
    if (
      !isUndefined(this.disabledOverride) &&
      this.disabledOverride !== null
    ) {
      return this.disabledOverride;
    } else {
      return this.disabled ||
        this.componentHelper.isCompDisabled(this.comp);
    }
  }

  get isRequired () {
    if (this.comp) {
      return this.comp.validate.required;
    }

    return false;
  }

  ngOnInit () {
    this.compKey = this.componentHelper.getAdaptedKeyFromComponentKey(
      this.comp.key,
      this.isForSetValue,
      this.inConfigModal
    );
    this.setDefaultTranslations();
  }

  setDefaultTranslations () {
    if (Object.keys(this.translations || {}).length === 0) {
      this.translations = {
        [this.label]: this.label,
        [this.description]: this.description,
        [this.placeholder]: this.placeholder,
        [this.tooltipText]: this.tooltipText
      };
    }

    this.label = this.translations[this.label] || this.label;
    this.description = this.translations[this.description] || this.description;
    this.placeholder = this.translations[this.placeholder] || this.translations.placeholder || this.placeholder;
    this.tooltipText = this.translations[this.tooltipText] || this.tooltipText;
  }

  getValueOnShow () {
    return this.data;
  }
}
