import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { AddEditWorkflow, AddEditWorkflowManagerPayload, SimpleWorkflowLevelUser, Workflow, WorkflowDetailFromApi, WorkflowLevelAudience, WorkflowManagerActions } from '@core/typings/workflow.typing';
import { WorkflowLevelFormApi } from '@features/configure-forms/form.typing';

@Injectable({ providedIn: 'root' })
export class WorkflowResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  getWorkflows (
    enforceDataSegmentation = false
  ): Promise<Workflow[]> {
    const endpoint = `api/manager/workflows?enforceDataSegmentation=${enforceDataSegmentation}`;

    return this.httpRestService.get(endpoint);
  }

  getWorkflow (
    workflowId: number,
    includeWorkflowLevelUsers = false
  ): Promise<WorkflowDetailFromApi>  {
    return this.httpRestService.get(
      'api/manager/workflows/' + workflowId + '?includeWorkflowLevelUsers=' +
        includeWorkflowLevelUsers
    );
  }

  getWorkflowForms (id: number): Promise<WorkflowLevelFormApi[]> {
    const endpoint = `api/manager/programs/${id}/WorkflowLevelForms`;

    return this.httpRestService.get(endpoint);
  }

  saveWorkflow (data: AddEditWorkflow): Promise<number> {
    return this.httpRestService.post('api/manager/workflows', data);
  }

  deleteWorkflow (workflowId: number) {
    return this.httpRestService.delete('api/manager/workflows/' + workflowId);
  }

  enableWorkflowLevel (workflowLevelId: number) {
    const endpoint = `api/manager/workflows/WorkflowLevels/Enable/${workflowLevelId}`;

    return this.httpRestService.get(endpoint);
  }

  disableWorkflowLevel (workflowLevelId: number) {
    const endpoint = `api/manager/workflows/WorkflowLevels/Disable/${workflowLevelId}`;

    return this.httpRestService.get(endpoint);
  }

  addUserToWorkflowLevel (
    workflowId: number,
    levelId: number,
    userId: number
  ) {
    return this.httpRestService.post(
      'api/manager/workflows/' + workflowId + '/levels/' + levelId +
        '/users/' + userId,
      {}
    );
  }

  deleteUserFromWorkflowLevel (
    workflowId: number,
    levelId: number,
    userId: number
  ) {
    return this.httpRestService.delete(
      'api/manager/workflows/' + workflowId + '/levels/' + levelId +
        '/users/' + userId
    );
  }

  addLevelToWorkflowLevelRoutes (
    workflowId: number,
    levelId: number,
    routeToLevelId: number
  ) {
    return this.httpRestService.post(
      'api/manager/workflows/' + workflowId + '/levels/' + levelId +
        '/routes/' + routeToLevelId,
      {}
    );
  }

  deleteLevelFromWorkflowLevelRoutes (
    workflowId: number,
    levelId: number,
    routeToLevelId: number
  ) {
    return this.httpRestService.delete(
      'api/manager/workflows/' + workflowId + '/levels/' + levelId +
        '/routes/' + routeToLevelId
    );
  }

  updateWorkflowDetails (id: number, name: string, description: string) {
    const endpoint = 'api/manager/workflows';

    return this.httpRestService.post(endpoint, {
      id,
      name,
      description
    });
  }

  updateWorkflowLevelUsers (
    workflowId: number,
    workflowLevelId: number,
    workflowLevelAudiences: WorkflowLevelAudience[],
    individualWorkflowLevelUsers: SimpleWorkflowLevelUser[]
  ) {
    const endpoint = 'api/manager/workflows/workflowlevelusers/createAndEdit';

    return this.httpRestService.post(endpoint, {
      workflowId,
      workflowLevelId,
      workflowLevelAudiences,
      individualWorkflowLevelUsers
    });
  }

  addOrUpdateWorkflowManager (payload: AddEditWorkflowManagerPayload) {
    const endpoint = 'api/manager/workflows/workflowManager/createAndEdit';

    return this.httpRestService.post(endpoint, payload);
  }

  removeWorkflowManager (workflowId: number, clientUserId: number) {
    const endpoint = 'api/manager/workflows/workflowManager/remove';

    return this.httpRestService.post(endpoint, {
      workflowId,
      clientUserId
    });
  }

  getMyWorkflowManagerRoles (): Promise<{
    workflowId: number;
    workflowActions: WorkflowManagerActions[];
  }[]> {
    const endpoint = 'api/manager/user/WorkflowManagerInfo';

    return this.httpRestService.get(endpoint);
  }

  getMyWorkflowLevels (): Promise<number[]> {
    const endpoint = 'api/manager/workflows/workflowlevels/dataSegmented';

    return this.httpRestService.get(endpoint);
  }

  saveBulkWflAudienceUpdates (
    workflowLevelAudiences: WorkflowLevelAudience[]
  ) {
    const endpoint = 'api/manager/workflows/bulkSaveWorkflowLevelAudiences';

    return this.httpRestService.post(endpoint, {
      workflowLevelAudiences
    });
  }

  copyWorkflow (
    workflowId: number,
    workflowName: string,
    workflowDescription: string
  ) {
    const endpoint = `api/manager/workflows/CopyWorkflow/${workflowId}`;

    return this.httpRestService.post<number>(endpoint, {
      name: workflowName,
      description: workflowDescription
    });
  }
}

