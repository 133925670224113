import { TableDataDownloadFormat } from '@yourcause/common/files';

export interface DataHubItem {
  name: string;
  description: string;
  type: number;
  status: number;
  queuedDate: string;
  reportFileName: string;
  reportFilePath: string;
  exceptionReportFileName: string;
  completionTimeMinutes: string;
  completionTimeSeconds: string;
}

export interface ExportModalResponse {
  name: string;
  description: string;
  programsIds?: number[];
  formId?: number;
  budgetIds?: number[];
  fromDate?: string;
  toDate?: string;
  paymentStatusIds?: number[];
  canSeeMaskedApplicantInfo?: boolean;
  nominationGrantFormId: number;
  nominationGrantFormRevisionId: number;
  programIds: number[];
  paymentBatchId: number;
  includeArchived: boolean;
  exportFileType: TableDataDownloadFormat;
  includeBom: boolean;
  delimiter: string;
}

export enum DataHubReportStatus {
  PENDING = 1,
  PROCESSING = 2,
  COMPLETED = 3,
  FAILED = 4
}

