import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { CurrencyService } from '@core/services/currency.service';
import { BaseApplication } from '@core/typings/application.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormAnswerValues, FormChangesWithCompKey, FormComponentGroup, FormComponentType, FormDecisionTypes, FormDefinitionComponent, FormValueChange } from '@features/configure-forms/form.typing';
import { FormBuilderActionEvent } from '@features/forms/form-builder/form-builder.typing';
import { FormBuilderComponent } from '@features/forms/form-builder/form-builder/form-builder.component';
import { ErrorForDisplay } from '@features/forms/form-renderer/form-error-summary/form-error-summary.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { FormLogicService } from '@features/forms/services/form-logic/form-logic.service';
import { InKindRequestedItem } from '@features/in-kind/in-kind.typing';
import { SimpleStringMap, TypeToken } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyValue } from '@yourcause/common/currency';
import { isEqual } from 'lodash';
import { Subscription } from 'rxjs';
import { SpecialHandling } from '../standard-form-components/form-special-handling/form-special-handling.component';

@Component({
  selector: 'gc-form-component-display',
  templateUrl: './form-component-display.component.html',
  styleUrls: ['./form-component-display.component.scss']
})
export class FormComponentDisplayComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) formGroup: FormComponentGroup;
  @Input() depth: number; // used to organize drop containers for the builder
  @Input() formId: number;
  @Input() editable: boolean;
  @Input() isFormBuilderView: boolean;
  @Input() component: FormDefinitionComponent;
  @Input() masked: boolean;
  @Input() disabledOverride: boolean;
  @Input() showErrorSummary: boolean;
  @Input() errorMessages: ErrorForDisplay[] = [];
  @Input() value: FormAnswerValues;
  @Input() parentFields: Partial<BaseApplication>;
  @Input() isManagerEditingApplicantForm: boolean;
  @Input() isManagerForm: boolean;
  @Input() translations: SimpleStringMap<string>;
  @Input() errorOverride: string;
  @Input() isForSetValue: boolean; // pass true if this input is used to capture default value
  @Input() lastCompOnPage: boolean;
  @Input() notAutoSave: boolean;
  @Input() inConfigModal: boolean;
  @Input() emitInitialValidity = false;
  @Input() refIdsChanged: number[];
  @Input() standardFieldsChanged: ReferenceFieldsUI.StandardFieldTypes[];
  @Output() onValueChange = new EventEmitter<FormChangesWithCompKey>();
  @Output() onValidChange = new EventEmitter<boolean>();
  @Output() componentDropped = new EventEmitter<CdkDragDrop<FormDefinitionComponent[]>>();
  @Output() componentActionClick = new EventEmitter<FormBuilderActionEvent>();

  hovered = false;
  type: FormComponentType;
  valueForField: FormAnswerValues;
  currency: string;
  forceDefaultCurrency: boolean;
  currencyOptions: TypeaheadSelectOption[] = [];
  canToggleCurrency = false;
  afterInit = false;
  showFieldChange = false;
  $number = new TypeToken<number>();
  $string = new TypeToken<string>();
  $decisionType = new TypeToken<FormDecisionTypes>();
  $specialHandling = new TypeToken<SpecialHandling>();
  $inKindItems = new TypeToken<InKindRequestedItem[]>();
  $currencyValue = new TypeToken<CurrencyValue>();
  isBBGM = this.clientSettingsService.isBBGM;
  sub = new Subscription();

  @HostListener('mouseenter')
  handleMouseEnter () {
    this.hovered = true;
  }

  @HostListener('mouseleave')
  handleMouseLeave () {
    this.hovered = false;
  }

  constructor (
    @Optional() private formBuilder: FormBuilderComponent,
    private formHelperService: FormHelperService,
    private formLogicService: FormLogicService,
    private componentHelper: ComponentHelperService,
    private currencyService: CurrencyService,
    private clientSettingsService: ClientSettingsService
  ) { }

  get isHidden () {
    return this.component.isHidden || this.component.hiddenFromParent;
  }

  get toolboxOpen () {
    return this.formBuilder?.toolboxOpen;
  }

  ngOnInit () {
    this.type = this.componentHelper.getAdaptedTypeFromComponentType(this.component.type);
    this.setShowFieldChanges();
    this.handleCurrencies();
    this.setValueForField();
    if (this.emitInitialValidity) {
      this.emitValidity();
    }
    this.sub.add(this.formGroup.statusChanges.subscribe(() => {
      this.emitValidity();
    }));

    this.afterInit = true;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.value) {
      this.handleCurrencies();
      if (!isEqual(changes.value.previousValue, changes.value.currentValue)) {
        this.setValueForField();
      }
    }
    if (this.afterInit) {
      if (changes.refIdsChanged || changes.standardFieldsChanged) {
        this.setShowFieldChanges();
      }
    }
  }

  emitValidity () {
    this.onValidChange.emit(!this.formGroup.invalid);
  }

  setShowFieldChanges () {
    this.showFieldChange = this.formHelperService.getHasFieldChanges(
      this.component?.type,
      this.component?.isHidden,
      this.refIdsChanged,
      this.standardFieldsChanged
    );
  }

  handleCurrencies () {
    const isCurrencyField = this.formLogicService.isCurrencyField(this.component.type);
    if (isCurrencyField) {
      if (this.type === 'amountRequested') {
        if (!this.isForSetValue) {
          this.forceDefaultCurrency = this.formHelperService.shouldForceDefaultCurrencyInAmountRequested(
            this.isManagerForm,
            this.isManagerEditingApplicantForm
          );
        }
      }
      const currency = (this.value as CurrencyValue)?.currency;
      this.currencyOptions = this.currencyService.getCurrencyOptionsForComponent(
        currency,
        this.component.useCustomCurrency,
        this.component.customCurrency
      );
      this.canToggleCurrency = this.formHelperService.canToggleCurrency(
        currency,
        this.disabledOverride,
        this.isForSetValue
      );
    }
  }

  handleComponentDropped (drop: CdkDragDrop<FormDefinitionComponent[]>) {
    this.componentDropped.emit(drop);
  }

  setValueForField () {
    this.valueForField = this.value;
  }

  valueChanged (change: FormValueChange) {
    this.formLogicService.setValueForComp(this.component, change.value);
    const changes = this.componentHelper.adaptToFormChanges(
      this.component,
      change.value,
      change.updateFormGroup
    );
    this.onValueChange.emit(changes);
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
