import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormComponentGroup, FormDefinitionComponent } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { I18nService } from '@yourcause/common/i18n';
export interface ErrorForDisplay {
  errorMessage: string;
  componentLabel: string;
  componentKey: string;
  componentType: string;
}

@Component({
  selector: 'gc-form-error-summary',
  templateUrl: './form-error-summary.component.html',
  styleUrls: ['./form-error-summary.component.scss']
})
export class FormErrorSummaryComponent implements OnChanges {
  @Input() tabFormGroup: FormComponentGroup;
  @Input() forceUpdate = false;
  @Input() hiddenCompKeys: string[];
  @Input() labelMap: Record<string, FormDefinitionComponent> = {};
  @Input() translations: Record<string, string> = {};
  @Output() onErrorsChange = new EventEmitter<ErrorForDisplay[]>();

  errorMessages: ErrorForDisplay[] = [];
  showMore = false;
  showMoreMinErrors = 5;

  constructor (
    private formBuilderService: FormBuilderService,
    private i18n: I18nService
  ) { }

  ngOnChanges (changes: SimpleChanges) {
    if (
      this.tabFormGroup &&
      changes.tabFormGroup || (changes.forceUpdate && this.forceUpdate)
    ) {
      this.setErrorMessages([]);
      Object.keys(this.tabFormGroup.controls).forEach((key) => {
        if (!this.hiddenCompKeys?.includes(key)) {
          const group = this.tabFormGroup.controls[key];
          if (group?.errors) {
            this.findControlErrors(group, key);
          }
        }
      });
    }
  }

  setErrorMessages (errorMessages: ErrorForDisplay[]) {
    this.errorMessages = errorMessages;
    this.onErrorsChange.emit(this.errorMessages);
  }

  findControlErrors (
    control: AbstractControl,
    controlKey: string
  ) {
    Object.keys(control.errors).forEach((errorKey) => {
      const error = control.errors[errorKey];
      this.handlePushingErrors(error, controlKey);
    });
  }

  handlePushingErrors (error: ValidationErrors, controlKey: string) {
    const component = this.labelMap[controlKey];
    let errorMessage = '';
    if (!!error?.errorMessage) {
      errorMessage = error.errorMessage;
    } else if (error?.i18nKey) {
      errorMessage = this.i18n.translate(error.i18nKey, {}, error.defaultValue);
    }

    this.setErrorMessages([
      ...this.errorMessages,
      {
        errorMessage,
        componentLabel: this.translations[component.label] || component.label,
        componentKey: controlKey,
        componentType: component.type
      }
    ]);
  }

  onGoToComponent (error: ErrorForDisplay) {
    this.formBuilderService.setComponentToViewOrEdit({
      isEdit: false,
      compType: error.componentType,
      compKey: error.componentKey
    });
  }
}
