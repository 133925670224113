import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormFieldCdtService } from '@features/form-fields/services/form-field-cdt.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { I18nService } from '@yourcause/common/i18n';
import { Subscription } from 'rxjs';
import { BaseComponentConfigSettingsComponent } from '../base-component-config-settings/base-component-config-settings.component';
import { SelectOption, TypeSafeFormBuilder, TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { MinMaxArrayValidator } from '@yourcause/common/form-control-validation';
interface VisibleCdtKeys {
  visibleCdtKeys: string[];
}

@Component({
  selector: 'gc-cdt-field-visible-options',
  templateUrl: './cdt-field-visible-options.component.html',
  styleUrls: ['./cdt-field-visible-options.component.scss']
})
export class CdtFieldVisibleOptionsComponent extends BaseComponentConfigSettingsComponent implements OnInit, OnDestroy {
  @Input() compType: string;
  @Input() hiddenCdtKeys: string[];
  @Output() isValidChange = new EventEmitter<boolean>();
  @Output() onChange = new EventEmitter<string[]>();

  formGroup: TypeSafeFormGroup<VisibleCdtKeys>;
  cdtOptions: SelectOption<string>[] = [];
  cdtKeysToHide: string[] = [];
  description = this.i18n.translate(
    'common:textSelectOptionsAvailableForForm',
    {},
    `Select the options to make available when completing the form. Deselecting a field removes it from the list and makes it unavailable for selection.`
  );
  searchByName = this.i18n.translate(
    'common:textSearchByName',
    {},
    'Search by name'
  );
  ready = false;
  sub = new Subscription();

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private componentHelper: ComponentHelperService,
    private formFieldHelperService: FormFieldHelperService,
    private i18n: I18nService,
    private formFieldCdtService: FormFieldCdtService
  ) {
    super();
  }

  async ngOnInit () {
    const refKey = this.componentHelper.getRefFieldKeyFromCompType(
      this.compType
    );
    const field = this.formFieldHelperService.getReferenceFieldByKey(refKey);
    const options = await this.formFieldCdtService.getCdtOptionsFromRefField(field);
    this.cdtOptions = options.filter((opt) =>  !opt.hidden);
    if (!this.hiddenCdtKeys) {
      this.cdtKeysToHide = [];
      this.onChange.emit(this.cdtKeysToHide);
    } else {
      this.cdtKeysToHide = [...this.hiddenCdtKeys];
    }
    const visibleCdtKeys = this.cdtOptions.filter((opt) => {
      return !this.cdtKeysToHide.includes(opt.value);
    }).map((opt) => {
      return opt.value;
    });
    const customErrorMessage = this.i18n.translate(
      'common:textPleaseSelectAtLeastOneItem',
      {},
      'Please select at least one item'
    );
    this.formGroup = this.formBuilder.group({
      visibleCdtKeys: [visibleCdtKeys, MinMaxArrayValidator('min', 1, customErrorMessage, '')]
    });
    this.sub.add(this.formGroup.valueChanges.subscribe(() => {
      this.emitValidity();
    }));
    this.emitValidity();
    this.ready = true;
  }

  optionsChanged () {
    const selectedKeys = this.formGroup.value.visibleCdtKeys;
    const unselectedKeys = this.cdtOptions.filter((opt) => {
      return !selectedKeys.includes(opt.value);
    }).map((opt) => {
      return opt.value;
    });
    this.onChange.emit(unselectedKeys);
  }

  emitValidity () {
    this.isValidChange.emit(this.formGroup.valid);
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
