import { Component, Input, OnChanges, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { ApplicationFileService } from '@core/services/application-file.service';
import { CurrencyService } from '@core/services/currency.service';
import { FormMaskingService } from '@core/services/form-masking.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { SpinnerService } from '@core/services/spinner.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { BaseApplication } from '@core/typings/application.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { UIExternalAPI } from '@core/typings/ui/external-api.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ApplicationAttachmentService } from '@features/application-view/application-attachments/application-attachments.service';
import { FormAnswerValues, FormAudience, FormFieldChangeIndicatorClass, FormFieldChangeIndicatorIcon } from '@features/configure-forms/form.typing';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { FormFieldTableAndSubsetService } from '@features/form-fields/services/form-field-table-and-subset.service';
import { ExternalAPISelection } from '@features/forms/component-configuration/external-api-selector-settings/external-api-selector-settings.component';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { TypeToken } from '@yourcause/common';
import { TypeSafeFormBuilder, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyRadioOptions, CurrencyValue } from '@yourcause/common/currency';
import { FileService, OnFileDownloadInfo, YcFile, maskedFileName } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { startOfDay } from 'date-fns';
import { isEqual, isUndefined } from 'lodash';
import { Subscription } from 'rxjs';
import { BaseFormComponent } from '../../base/base.component';
import { RegexUI } from '@yourcause/common/form-control-validation';

@Component({
  selector: 'gc-form-reference-field',
  templateUrl: './form-reference-field.component.html',
  styleUrls: ['./form-reference-field.component.scss']
})
export class FormReferenceFieldComponent extends BaseFormComponent<FormAnswerValues> implements OnInit, OnChanges, OnDestroy {
  @Input() inline = false;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() masked: boolean;
  @Input() requireDecimal: boolean;
  @Input() decimalLimit: number;
  @Input() showWordCount: boolean;
  @Input() showCharCount: boolean;
  @Input() inputMask: string;
  @Input() itemsShownBeforeScroll: number;
  @Input() rows: number;
  @Input() isManagerForm: boolean;
  @Input() hideWithoutParentVal: boolean;
  @Input() validationTotal: number;
  @Input() notAutoSave = false; // prevents save of files
  @Input() allOptionsMustHaveResponse: boolean;
  @Input() currencyOptions: TypeaheadSelectOption[] = [];
  @Input() useCustomCurrency: CurrencyRadioOptions;
  @Input() customCurrency: string;

  // External API
  @Input() displayType: AdHocReportingUI.DisplayTypes;
  @Input() relatedComponent: string;
  @Input() visibleToApplicants: boolean;
  @Input() visibleToManagers: boolean;
  @Input() dataUpdates: UIExternalAPI.DataUpdates;
  @Input() apiConfig: ExternalAPISelection;
  @Input() required: boolean;

  type: ReferenceFieldsUI.ReferenceFieldTypes;
  FieldTypes = ReferenceFieldsUI.ReferenceFieldTypes;
  field: ReferenceFieldAPI.ReferenceFieldDisplayModel;
  formFieldMask = this.formMaskingService.formFieldMask;
  formattingData = this.currencyService.formattingData;
  CurrencyRadioOptions = CurrencyRadioOptions;
  refKey: string;
  hasOptions = false;
  multiTextAnswers: string[];
  sub = new Subscription();
  manuallyPatchValue = false;
  maxFiles: number;
  initialCustomValidation: string;
  $stringOrStringArray = new TypeToken<string|string[]>();
  $address = new TypeToken<ReferenceFieldAPI.FormFieldAddressResponse>();
  $string = new TypeToken<string>();
  $number = new TypeToken<number>();
  $currencyValue = new TypeToken<CurrencyValue>();
  $tableResponseRowArray = new TypeToken<ReferenceFieldsUI.TableResponseRowForUi[]>();
  customLabelIcon = FormFieldChangeIndicatorIcon;
  customLabelIconTooltip = this.i18n.translate(
    'common:textThisFieldWasUpdatedByTheApplicant',
    {},
    'This field was updated by the applicant'
  );
  customLabelIconClass = FormFieldChangeIndicatorClass;
  isAfterInit = false;

  constructor (
    private i18n: I18nService,
    private formMaskingService: FormMaskingService,
    private applicationFileService: ApplicationFileService,
    public formBuilder: TypeSafeFormBuilder,
    private formHelperService: FormHelperService,
    public formBuilderService: FormBuilderService,
    private applicationAttachmentService: ApplicationAttachmentService,
    private spinnerService: SpinnerService,
    private currencyService: CurrencyService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private portal: PortalDeterminationService,
    private formFieldHelperService: FormFieldHelperService,
    public componentHelper: ComponentHelperService,
    public fileService: FileService,
    @Optional() formGroupDir: FormGroupDirective,
    @Optional() renderer: FormRendererComponent<BaseApplication>
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  get readOnly () {
    return this.disabledOverride === true;
  }

  get addRequiredAsterisk () {
    if (this.isRequired) {
      return true;
    }
    if (this.field?.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload) {
      return this.field.supportsMultiple ? this.min > 0 : false;
    }

    return false;
  }

  get min () {
    return this.comp.validate.min;
  }

  get max () {
    return this.comp.validate.max;
  }

  private get onManagerForm () {
    return this.isManagerForm ??
      (this.formBuilderService.currentFormBuilderFormAudience === FormAudience.MANAGER);
  }

  get isDisabled () {
    if (
      !isUndefined(this.disabledOverride) &&
      this.disabledOverride !== null
    ) {
      return this.disabledOverride;
    } else {
      return (
          this.onManagerForm &&
          this.field?.formAudience === FormAudience.APPLICANT
        ) ||
        this.componentHelper.isCompDisabled(this.comp) ||
        // disable if manager field is on applicant form
        (
          !this.onManagerForm &&
          this.field?.formAudience === FormAudience.MANAGER
        );
    }
  }

  get maskedFileUploads () {
    const fileNames = [... (this.data as YcFile[])].map((x: YcFile) => x.fileName);
    if (!fileNames.some(x => x.startsWith('*') && this.isMaskedView)) {
      return fileNames.map(x => {
        return maskedFileName + '.' + this.fileService.getFileExtension(x);
      }).join(', ');
    }

    return fileNames.join(', ');
  }

  get isMaskedView () {
    return this.portal.isManager && this.masked && this.isDisabled && this.field?.isMasked;
  }

  get isFileUpload () {
    return this.field.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload;
  }

  async ngOnInit () {
    super.ngOnInit();
    await this.prepField(true);

    this.sub.add(this.control.valueChanges.subscribe(() => {
      if (this.dirty !== this.control.dirty) {
        this.dirty = this.control.dirty;
      }
    }));

    if (this.comp.appliedDefaultVal) {
      setTimeout(() => {
        this.forceUpdate();
      });
    }
    this.isAfterInit = true;
  }

  ngOnChanges () {
    if (this.isAfterInit) {
      const field = this.formFieldHelperService.getReferenceFieldByKey(this.refKey);
      if (!isEqual(field, this.field)) {
        this.prepField(false);
      }
    }
  }

  async prepField (isInit: boolean) {
    this.refKey = this.extractReferenceFieldKey();
    this.field = this.formFieldHelperService.getReferenceFieldByKey(this.refKey);

    this.showCharCount = !this.readOnly && this.showCharCount;
    this.showWordCount = !this.readOnly && this.showWordCount;

    if (this.field.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload) {
      this.maxFiles = this.field?.supportsMultiple ? (this.max || 10) : 1;
    }

    const isMultiText = this.field.supportsMultiple &&
      [
        ReferenceFieldsUI.ReferenceFieldTypes.TextArea,
        ReferenceFieldsUI.ReferenceFieldTypes.TextField
      ].includes(this.field.type);
    // Subsets, currency, and tables do not use form group, so set control manually
    this.manuallyPatchValue = [
      ReferenceFieldsUI.ReferenceFieldTypes.Subset,
      ReferenceFieldsUI.ReferenceFieldTypes.Currency,
      ReferenceFieldsUI.ReferenceFieldTypes.Table
    ].includes(this.field.type) || isMultiText;
    this.initialCustomValidation = this.comp.validate.custom;
    this.saveDisabled = this.formHelperService.getSaveIsDisabled(
      this.field,
      this.onManagerForm
    );
    this.hasOptions = this.formFieldHelperService.doesTypeHaveOptions(this.field.type);
    if (
      !this.rows &&
      this.field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextArea
    ) {
      this.rows = 3;
    }
    this.setInputMask();

    if (isInit) {
      if (isMultiText) {
        this.multiTextAnswers = [ ...(this.data as string[]) ];
      }
      const isSubset = this.field.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset;
      if (isSubset) {
        const dataPoints = this.formFieldTableAndSubsetService.dataPointsMap[
          this.field.referenceFieldId
        ];
        if (!dataPoints) {
          await this.formFieldTableAndSubsetService.setDataPointsForSubset(this.field.referenceFieldId);
        }
      }
    }

    if (this.hasOptions) {
      this.formFieldHelperService.setParentPicklistValueMap(
        this.field.referenceFieldId,
        this.data as string|string[]
      );
    }
  }

  setDefaultTranslations () {
    super.setDefaultTranslations();

    this.suffix = this.translations[this.suffix] || this.suffix;
    this.prefix = this.translations[this.prefix] || this.prefix;
  }

  extractReferenceFieldKey () {
    return this.componentHelper.getRefFieldKeyFromCompType(this.comp.type);
  }

  setInputMask () {
    if (this.field.formatType) {
      if (this.field.formatType === RegexUI.RegexFormattingType.EIN) {
        this.inputMask = '00-0000000';
      } else {
        this.inputMask = null; // Formatting takes precedence over existing input mask, except for EIN
      }
    }

    // If field formatting exists, we handle the validation
    if (this.field.formatType) {
      if (this.comp?.inputMask) {
        this.comp.inputMask = '';
      }
      if (this.comp?.validate.pattern) {
        this.comp.validate.pattern = '';
      }
    }
  }

  onCurrencyAmountChange (amount: number) {
    this.control.markAsDirty();
    this.dataChanged(amount);
  }

  onCurrencyChange (currency: string) {
    this.data = {
      ...this.data as CurrencyValue,
      currency
    };
    this.dataChanged(this.data.amountForControl);
  }

  multiTextAnswerChanged () {
    this.dataChanged([ ...this.multiTextAnswers ]);
  }

  addTextRow () {
    this.multiTextAnswers = [
      ...this.multiTextAnswers,
      ''
    ];
    this.dataChanged([ ...this.multiTextAnswers ]);
  }

  removeTextRow (index: number) {
    this.multiTextAnswers = [
      ...this.multiTextAnswers.slice(0, index),
      ...this.multiTextAnswers.slice(index + 1)
    ];
    this.dataChanged([ ...this.multiTextAnswers ]);
  }

  trackBy (index: number) {
    return index;
  }

  getValueOnShow () {
    return this.data ?? this.defaultVal ?? this.control.value;
  }

  async filesChanged (files: YcFile[]) {
    this.dataChanged(files);
  }

  handleDownloadFile = async (info: OnFileDownloadInfo) => {
    this.spinnerService.startSpinner();
    if (info.openFileInsteadOfDownload) {
      await this.applicationAttachmentService.openReferenceFieldFromUrl(info.file?.fileUrl);
    } else {
      await this.applicationAttachmentService.downloadReferenceFieldFile(info.file?.fileUrl);
    }
    this.spinnerService.stopSpinner();
  }

  uploadFunc = async (uploadRequest: YcFile) => {
    const fileName = uploadRequest.fileName;
    this.spinnerService.startSpinner();
    const fileUrl = await this.applicationFileService.uploadFile(
      this.parentFields.applicationId,
      this.parentFields.applicationFormId,
      (uploadRequest as YcFile<File>).file,
      fileName,
      this.field.referenceFieldId ?? null
    );
    this.spinnerService.stopSpinner();
    if (!!fileUrl) {
      const details = this.applicationFileService.breakDownloadUrlDownToObject(
        fileUrl
      );
      let fileUploadId: number;
      if (details.fileId) {
        fileUploadId = +details.fileId;
      }

      const newValue = new YcFile(
        uploadRequest.fileName,
        uploadRequest.file,
        fileUrl,
        fileUploadId
      );

      return newValue;
    }

    return null;
  };

  forceUpdate () {
    const isCurrency = this.field.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency;
    if (isCurrency) {
      this.dataChanged((this.data as CurrencyValue).amountForControl);
    } else {
      this.dataChanged(this.data);
    }
  }

  async dataChanged (value: FormAnswerValues) {
    if (this.field.type === ReferenceFieldsUI.ReferenceFieldTypes.Date) {
      if (value) {
        // We only capture dates, so don't account for time
        value = startOfDay(new Date(value as string));
      }
    } else if (
      !this.notAutoSave &&
      this.field.type === ReferenceFieldsUI.ReferenceFieldTypes.FileUpload
    ) {
      // Only proceed if the file(s) have actually been uploaded
      const files = value as YcFile<File>[] || [];
      const filesNotReady = files.some((file) => {
        return !file.fileUploadId;
      });
      if (filesNotReady) {
        return;
      }
    } else if (this.field.type === ReferenceFieldsUI.ReferenceFieldTypes.Currency) {
      value = {
        amountForControl: value as number,
        amountEquivalent: value as number,
        amountInDefaultCurrency: value as number,
        currency: (this.data as CurrencyValue).currency
      };
    }

    this.data = value;

    this.onValueChange.emit({
      value,
      updateFormGroup: this.manuallyPatchValue
    });
    if (this.hasOptions) {
      // if this is a parent, fire a function on ref fields service that updates a map
      this.formFieldHelperService.setParentPicklistValueMap(
        this.field.referenceFieldId,
        value as string|string[]
      );
    }
  }

  ngOnDestroy () {
    this.sub.unsubscribe();
  }
}
