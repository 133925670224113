<ng-container *ngIf="!isNominationField"
  [ngSwitch]="displayType">
  <yc-input *ngSwitchCase="DisplayTypes.TextField"
    [name]="compKey"
    [disabled]="true"
    [tabIndex]="tabIndex"
    [value]="value | ycAs: $string"
    [description]="description"
    [label]="label"
    [srOnlyLabel]="hideLabel">
  </yc-input>
  
  <yc-textarea *ngSwitchCase="DisplayTypes.TextArea"
    [name]="compKey"
    [disabled]="true"
    [tabIndex]="tabIndex"
    [value]="value | ycAs: $string"
    [description]="description"
    [label]="label"
    [srOnlyLabel]="hideLabel">
  </yc-textarea>

  <ng-container *ngSwitchCase="DisplayTypes.Raw">
    <yc-form-label
      [label]="label"
      [description]="description"
      [srOnly]="hideLabel">
    </yc-form-label>
    <div [innerHTML]="value"></div>
  </ng-container>
</ng-container>

<ng-container *ngIf="isNominationField && !!component">
  <gc-form-reference-field
    [notAutoSave]="notAutoSave"
    [formGroup]="formGroup"
    [formComponent]="component"
    [data]="value"
    [masked]="masked"
    [label]="label"
    [hideLabel]="hideLabel"
    [disabledOverride]="true"
    [parentFields]="parentFields"
    [translations]="translations"
    [isManagerForm]="isManagerForm"
    [isFormBuilderView]="isFormBuilderView"
    [tabIndex]="tabIndex"
    [description]="description">
  </gc-form-reference-field>
</ng-container>


