import { Component, Input, OnInit } from '@angular/core';
import { SpinnerService } from '@core/services/spinner.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormAudience } from '@features/configure-forms/form.typing';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { AnalyticsService, EventType } from '@yourcause/common/analytics';
import { I18nService } from '@yourcause/common/i18n';
import { YCModalComponent } from '@yourcause/common/modals';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { FormFieldService } from '../services/form-field.service';
import { HeapService, TrackingPropertyNames, TrackingEventNames } from '@yourcause/common/heap';
import { RegexUI } from '@yourcause/common/form-control-validation';

@Component({
  selector: 'gc-create-edit-form-field-modal',
  templateUrl: './create-edit-form-field-modal.component.html',
  styleUrls: ['./create-edit-form-field-modal.component.scss']
})
export class CreateEditFormFieldModalComponent extends YCModalComponent<
  ReferenceFieldsUI.ModalReturn
> implements OnInit {
  @Input() existingReferenceField: ReferenceFieldAPI.ReferenceFieldDisplayModel;
  @Input() forceIsTableField = false;
  @Input() forceIsDataPoint = false;
  @Input() pendingKeyToBeCreated: string;
  @Input() addingFieldToForm = false;
  @Input() editingFieldOnForm = false;
  @Input() doNotAllowKeyChanges = false;
  @Input() defaultAudienceSelection: FormAudience = null;
  @Input() isViewOnly = false;
  @Input() saveText = this.i18n.translate(
    'common:btnSave',
    {},
    'Save'
  );
  @Input() secondarySaveText: string;

  modalHeader = '';
  existingKeys: string[];
  allowedKeys: string[];
  saving = false;
  field: ReferenceFieldAPI.ReferenceFieldBaseModel;
  isValid = false;
  isTableConfigValid = false;
  addingCategory: boolean;
  primaryButtonText: string;
  isTableConfigPage = false;
  isDataSetConfigPage = false;
  closeText = this.i18n.translate(
    'common:textClose',
    {},
    'Close'
  );
  hideModal = false;
  tableFields: ReferenceFieldsUI.TableFieldForUi[];
  popoverHeader = this.i18n.translate(
    'common:hdrUpdateFormatting',
    {},
    'Update formatting?'
  );
  popoverText = this.i18n.translate(
    'common:textAddFormattingConfirm',
    {},
    'Changing the formatting of this form field may result in differences between old a new responses. Do you want to continue?'
  );
  formatTypes = RegexUI.RegexFormattingType;
  tableImportNotAllowed: boolean;

  constructor (
    private formFieldService: FormFieldService,
    private i18n: I18nService,
    private spinnerService: SpinnerService,
    private analyticsService: AnalyticsService,
    private formFieldHelperService: FormFieldHelperService,
    private heapService: HeapService
  ) {
    super();
  }

  get isTable () {
    return this.field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Table;
  }

  get isDataSet () {
    return this.field?.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset;
  }

  get showPopover () {
    return this.field?.type === ReferenceFieldsUI.ReferenceFieldTypes.TextField &&
      this.formatIsUpdated;
  }

  get formatIsUpdated () {
    return this.existingReferenceField &&
      !this.addingFieldToForm &&
      (this.field.formatType !== this.existingReferenceField.formatType) &&
      !(this.field.formatType === this.formatTypes.NONE && !this.existingReferenceField.formatType);
  }

  get onFinalStep () {
    if (this.isTable) {
      return this.isTableConfigPage;
    } else if (this.isDataSet) {
      return this.isDataSetConfigPage;
    }

    return true;
  }

  get isSaveDisabled () {
    if (!this.isViewOnly) {
      return this.saving ||
        (
          this.isTableConfigPage || this.isDataSetConfigPage ?
            !this.isTableConfigValid :
            !this.isValid
        );
    }

    return false;
  }

  ngOnInit () {
    this.setUniqueKeys();
    this.setModalHeader();
    this.setPrimaryText();
  }

  setUniqueKeys () {
    this.existingKeys = this.formFieldHelperService.allReferenceFields.map((field) => {
      return (field.key || '');
    });
    if (this.pendingKeyToBeCreated) {
      this.existingKeys = [
        ...this.existingKeys,
        this.pendingKeyToBeCreated
      ];
    }
    this.allowedKeys = this.existingReferenceField?.key ?
      [this.existingReferenceField?.key] :
      [];
  }

  setModalHeader () {
    this.modalHeader = this.i18n.translate(
      this.existingReferenceField?.referenceFieldId ?
        'FORMS:hdrEditFormField' :
        'FORMS:hdrAddNewFormField',
      {},
      this.existingReferenceField?.referenceFieldId ?
        'Edit Form Field' :
        'Add New Form Field'
    );
  }

  setPrimaryText () {
    if (this.isTable) {
      if (!this.isTableConfigPage) {
        this.primaryButtonText = this.i18n.translate(
          'FORMS:textSelectTableFields',
          {},
          'Select table fields'
        );
      } else {
        this.primaryButtonText = this.isViewOnly ? this.closeText : this.saveText;
      }
    } else if (this.isDataSet) {
      if (!this.isDataSetConfigPage) {
        this.primaryButtonText = this.i18n.translate(
          'FORMS:textSelectFieldGroupOptions',
          {},
          'Select field group options'
        );
      } else {
        this.primaryButtonText = this.isViewOnly ? this.closeText : this.saveText;
      }
    } else {
      this.primaryButtonText = this.isViewOnly ? this.closeText : this.saveText;
    }
  }
  
  onFieldChange (field: ReferenceFieldAPI.ReferenceFieldBaseModel) {
    this.field = field;
    this.setPrimaryText();
  }

  async saveOrNavigateToTableConfig (isSecondarySave = false) {
    let eventName = '';
    
    if (this.isTableConfigPage || this.isDataSetConfigPage) {
      this.isTableConfigPage = false;
      this.isDataSetConfigPage = false;

      setTimeout(async () => {
        eventName = TrackingEventNames.SaveTableOrFieldGroup;
        await this.save(isSecondarySave);
      }, 0);
      
    } else {
      if (this.isTable || this.isDataSet) {
        eventName = TrackingEventNames.ShowTableOrFieldGroupConfig;
        this.isTableConfigPage = this.isTable;
        this.isDataSetConfigPage = this.isDataSet;
        this.ref.setClass('modal-full-size');
        this.setPrimaryText();
      } else {
        eventName = TrackingEventNames.SaveReferenceField;
        await this.save(isSecondarySave);
      }
    }

    // track event
    this.analyticsService.emitEvent({
      eventName: eventName,
      eventType: EventType.Click,
      extras: null
    });
    this.heapService.track(
      eventName,
      {
        [TrackingPropertyNames.FieldName]: this.field.name,
        [TrackingPropertyNames.FieldType]: this.field.type
      });
  }

  onPrimaryClick (
    popover: PopoverDirective
  ) {
    if (this.showPopover) {
      setTimeout(() => {
        popover.show();
      });
    } else {
      this.saveOrNavigateToTableConfig();
    }
  }

  onSecondaryClick () {
    this.saveOrNavigateToTableConfig(true);
  }

  onBack () {
    if (this.isTableConfigPage) {
      this.isTableConfigPage = false;
    } else if (this.isDataSetConfigPage) {
      this.isDataSetConfigPage = false;
    }
    this.ref.setClass('modal-xl');
    this.setPrimaryText();
  }

  async save (isSecondarySave = false) {
    if (this.isViewOnly) {
      this.closeModal.emit();
    } else {
      this.saving = true;
      this.spinnerService.startSpinner();
      const modalReturn = await this.formFieldService.formatCreateEditFieldModalReturn(
        this.field,
        this.tableFields,
        this.addingCategory,
        isSecondarySave
      );
      this.spinnerService.stopSpinner();
      this.closeModal.emit(modalReturn);
    }
  }
}
