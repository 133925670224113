import { Injectable } from '@angular/core';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { FileService } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { uniqBy } from 'lodash';
import * as parse from 'papaparse';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { SystemTagsResources } from './system-tags.resources';
import { SystemTagsState } from './system-tags.state';
import { SystemTags } from './typings/system-tags.typing';
import { PaginationOptions } from '@yourcause/common';
import { ValidatorExtras, ValidatorReturn, ServiceValidator, createTopLevelValidator, BaseValidatorExtras, IsNumber, Description, Required, Unique, IsOneOf, CSVBooleanFactory } from '@yourcause/common/form-control-validation';
import { Transform } from 'class-transformer';

@AttachYCState(SystemTagsState)
@Injectable({ providedIn: 'root' })
export class SystemTagsService extends BaseYCService<SystemTagsState> {
  readonly tagColorClassMap: Record<SystemTags.TagColor, string> = {
    [SystemTags.TagColor.Red]: 'alert alert-danger',
    [SystemTags.TagColor.Green]: 'alert alert-success',
    [SystemTags.TagColor.Blue]: 'alert alert-info',
    [SystemTags.TagColor.Yellow]: 'alert alert-warning'
  };
  bucketsWithTags$: Observable<SystemTags.TagBucketWithTags[]> = combineLatest([
    this.changesTo$('buckets'),
    this.changesTo$('allTags')
  ])
  .pipe(filter(([buckets, tags]) => !!buckets && !!tags))
  .pipe(map(([buckets, tags]) => {
    return this.arrayHelper.sort(buckets.map(bucket => ({
      ...bucket,
      activeTags: tags
        .filter(tag => tag.bucketId === bucket.id)
        .filter(tag => tag.enabled),
      inactiveTags: tags
        .filter(tag => tag.bucketId === bucket.id)
        .filter(tag => !tag.enabled)
    })), 'name');
  }));

   constructor (
    private logger: LogService,
    private i18n: I18nService,
    private arrayHelper: ArrayHelpersService,
    private systemTagsResources: SystemTagsResources,
    private notifier: NotifierService,
    private fileService: FileService,
    private confirmAndTakeAction: ConfirmAndTakeActionService
  ) {
    super();
  }

  get buckets () {
    return this.get('buckets');
  }

  get allTags () {
    return this.get('allTags');
  }

  get allTagsMap () {
    return this.get('allTagsMap');
  }

  get tagMap () {
    return this.get('tagMap');
  }

  async resolveSystemTags () {
    this.getBuckets();
    await this.setAllTags();
  }

  getTagOptionsForAppManager (isNomination: boolean) {
    const applicationTags = this.getTagsForBucket(
      isNomination ?
        SystemTags.Buckets.Nomination :
        SystemTags.Buckets.Application,
      true
    );
    const nonprofitTags = this.getTagsForBucket(
      SystemTags.Buckets.NonprofitProfile,
      true
    );
    const applicantTags = this.getTagsForBucket(
      SystemTags.Buckets.Applicant,
      true
    );
    const availableTags = uniqBy([
      ...applicationTags,
      ...nonprofitTags,
      ...applicantTags
    ], 'id').map((tag) => {
      return {
        label: tag.name,
        value: tag.id
      };
    });

    return this.arrayHelper.sort(availableTags, 'label');
  }

  findTag (tagId: number) {
    return this.allTags.find((tag) => {
      return tag.id === tagId;
    });
  }

  adaptColorForApi (color: SystemTags.TagColor) {
    switch (color) {
      case SystemTags.TagColor.Blue:
        return SystemTags.TagColorText.Blue;
      case SystemTags.TagColor.Green:
        return SystemTags.TagColorText.Green;
      case SystemTags.TagColor.Red:
        return SystemTags.TagColorText.Red;
      case SystemTags.TagColor.Yellow:
        return SystemTags.TagColorText.Yellow;
    }
  }

  adaptTagForUi (tag: SystemTags.TagDefinitionForApi): SystemTags.TagDefinitionForUi {
    return {
      ...tag,
      color: this.adaptColorForUi(tag.color)
    };
  }

  adaptColorForUi (color: SystemTags.TagColorText) {
    switch (color) {
      case SystemTags.TagColorText.Blue:
        return SystemTags.TagColor.Blue;
      case SystemTags.TagColorText.Green:
        return SystemTags.TagColor.Green;
      case SystemTags.TagColorText.Red:
        return SystemTags.TagColor.Red;
      case SystemTags.TagColorText.Yellow:
      default:
        return SystemTags.TagColor.Yellow;
    }
  }

  adaptTagForApi (
    tag: SystemTags.TagDefinitionForUi|SystemTags.TagImportForUi,
    forImport = false
  ): SystemTags.AddTagRequest|SystemTags.UpdateTagRequest|SystemTags.ImportTagRequest {
    const adapted = {
      id: tag.id,
      name: tag.name,
      color: this.adaptColorForApi(tag.color),
      description: 'description' in tag ? tag.description : '',
      tagBucketId: 'bucketId' in tag ? tag.bucketId : tag.bucket
    };
    if (forImport) {
      return {
        ...adapted,
        enabled: !(tag as SystemTags.TagImportForUi).inactive
      };
    }

    return adapted;
  }

  async setAllTags () {
    if (!this.allTags) {
      const allTags = await this.systemTagsResources.getAll();

      const adapted = allTags.map<SystemTags.TagDefinitionForUi>((tag) => {
        return this.adaptTagForUi(tag);
      });
      const allTagsMap = adapted.reduce((acc, tag) => {
        return {
          ...acc,
          [tag.id]: tag
        };
      }, {} as Record<string, SystemTags.TagDefinitionForUi>);
      this.set('allTagsMap', allTagsMap);
      this.set('allTags', adapted);
    }
  }

  async resetAllTags () {
    this.set('allTags', undefined);
    await this.setAllTags();
  }

  getBuckets () {
    const buckets = [{
      name: this.i18n.translate('tags:lblGlobal'),
      id: SystemTags.Buckets.Global
    }, {
      name: this.i18n.translate('common:hdrApplications'),
      id: SystemTags.Buckets.Application
    }, {
      name: this.i18n.translate('common:lblApplicantsSlashNominators', {}, 'Applicants/Nominators'),
      id: SystemTags.Buckets.Applicant
    }, {
      name: this.i18n.translate('common:hdrNominations'),
      id: SystemTags.Buckets.Nomination
    }, {
      name: this.i18n.translate('tags:lblNonprofitProfile', {}, 'Nonprofit profile'),
      id: SystemTags.Buckets.NonprofitProfile
    }, {
      name: this.i18n.translate('common:hdrPayments'),
      id: SystemTags.Buckets.Payment
    }, {
      name: this.i18n.translate('GLOBAL:textBudgets'),
      id: SystemTags.Buckets.Budget
    }];
    this.set('buckets', buckets);
  }

  formatPaginationOptions<T> (
    options: PaginationOptions<T>&{ tags?: number[] },
    columnName = 'tags'
  ): PaginationOptions<T> & { tags?: number[] } {
    let tags: number[] = options?.tags || [];
    tags = this.filterOutFilterColumns<T>(options, columnName, tags, 'filterColumns');
    tags = this.filterOutFilterColumns<T>(options, columnName, tags, 'orFilterColumns');

    tags = tags.filter((tag, index) => tags.indexOf(tag) === index);

    return {
      ...options,
      tags
    };
  }

  getTagOptions (bucket?: SystemTags.Buckets): TypeaheadSelectOption[] {
    return this.arrayHelper.sort((this.allTags || [])
      .filter(tag => bucket ? [bucket, SystemTags.Buckets.Global].includes(tag.bucketId) : true)
      .map(tag => ({
        label: tag.name,
        value: tag.id
      })), 'label');
  }

  private filterOutFilterColumns<T> (
    options: PaginationOptions<T>,
    columnName: string,
    tags: number[],
    prop: 'filterColumns'|'orFilterColumns'
  ) {
    options[prop] = options[prop]
      .filter(column => {
        if (column.columnName === columnName) {
          tags = [
            ...tags,
            ...column.filters
              .reduce((acc, { filterValue }) => {
                return [
                  ...acc,
                  filterValue
                ];
              }, [])
          ];

          return false;
        }

        return true;
      });

    return tags;
  }

  getTagsForBucket (
    bucketType: SystemTags.Buckets,
    includeDisabled = false
  ) {
    const tags = this.allTags.filter((tag) =>  {
      return (!includeDisabled ? tag.enabled : true) &&
        [bucketType, SystemTags.Buckets.Global].includes(tag.bucketId);
    });

    return this.arrayHelper.sort(tags, 'name');
  }

  async fetchTagsForRecord (
    tagType: SystemTags.Buckets,
    entityId: string|number
  ) {
    const tags = await this.systemTagsResources.getTags(tagType, entityId);
    const subTagMap = this.tagMap[tagType] || {};

    this.set('tagMap', {
      ...this.tagMap,
      [tagType]: {
        ...subTagMap,
        [entityId]: tags.map(tag => tag.tag.id)
      }
    });
  }

  getTagsForRecord (
    tagType: SystemTags.Buckets,
    entityId: string|number
  ) {
    return this.tagMap[tagType] ?
      this.tagMap[tagType][entityId] || null :
      null;
  }

  async getAndSetTagsForRecord (
    tagType: SystemTags.Buckets,
    entityId: string|number
  ) {
    let currentTagIds = this.getTagsForRecord(tagType, entityId);
    if (!currentTagIds) {
      await this.fetchTagsForRecord(tagType, entityId);
      currentTagIds = this.getTagsForRecord(tagType, entityId);
    }

    return currentTagIds;
  }

  getNewAndRemovedTags (
    currentTags: SystemTags.TagDefinitionForUi[],
    tagIds: number[]
  ) {
    return {
      removedTags: this.getRemovedTags(currentTags, tagIds),
      newTags: this.getNewTags(currentTags, tagIds)
    };
  }

  getNewTags (
    currentTags: SystemTags.TagDefinitionForUi[],
    tagIds: number[]
  ) {
    return tagIds.filter((potentialNewTag) => {
      return !currentTags.some(tag => tag.id === potentialNewTag);
    });
  }

  getRemovedTags (
    currentTags: SystemTags.TagDefinitionForUi[],
    tagIds: number[]
  ) {
    return currentTags.filter((existingTag) => {
      return !tagIds.includes(existingTag.id);
    }).map(tag => tag.id);
  }

  async handleSetTagsOnRecord (
    currentTagIds: number[],
    newTagIds: number[],
    entityId: number,
    tagType: SystemTags.Buckets,
    subTagType?: SystemTags.Buckets,
    subEntityId?: string|number,
    skipToastr = false
  ) {
    const currentTags = currentTagIds.map((id) => {
      return this.allTags.find(tag => tag.id === id);
    });
    const {
      newTags,
      removedTags
    } = this.getNewAndRemovedTags(
      currentTags,
      newTagIds
    );
    if (newTags.length > 0 || removedTags.length > 0) {
      await this.setTagsOnRecord(
        tagType,
        entityId,
        newTags,
        removedTags,
        subTagType,
        subEntityId,
        skipToastr
      );
    }
  }

  async setTagsOnRecord (
    tagType: SystemTags.Buckets,
    entityId: string|number,
    newTags: number[],
    removedTags: number[],
    subTagType?: SystemTags.Buckets,
    subEntityId?: string|number,
    skipToastr = false
  ) {
    try {
      if (subTagType && subEntityId) {
        // find the records that have been added to the sub entity
        const newTagsForSubEntity = newTags.filter(tagId => {
          return this.allTags.find(summary => {
            return summary.id === tagId && summary.bucketId === subTagType;
          });
        });

        const removedTagsForSubEntity = removedTags.filter(tagId => {
          return this.allTags.find(summary => {
            return summary.id === tagId && summary.bucketId === subTagType;
          });
        });

        if (
          (newTagsForSubEntity.length > 0) ||
          (removedTagsForSubEntity.length > 0)
        ) {
          await this.setTags(
            subTagType,
            subEntityId,
            newTagsForSubEntity,
            removedTagsForSubEntity
          );
          await this.fetchTagsForRecord(subTagType, subEntityId);

          newTags = newTags.filter(newTag => {
            return !newTagsForSubEntity.includes(newTag);
          });

          removedTags = removedTags.filter(removedTag => {
            return !removedTagsForSubEntity.includes(removedTag);
          });
        }
      }

      await this.setTags(
        tagType,
        entityId,
        newTags,
        removedTags
      );
      await this.fetchTagsForRecord(tagType, entityId);
      if (!skipToastr) {
        this.notifier.success(this.i18n.translate(
          'GLOBAL:textSuccessSetTagsOnRecord',
          {},
          'Successfully set the selected tags'
        ));
      }
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'GLOBAL:textErrorSettingTags',
        {},
        'There was an error setting the tags'
      ));
    }
  }

  async addRecord (tag: SystemTags.TagDefinitionForUi): Promise<number> {
    try {
      const body = this.adaptTagForApi(tag);
      const tagId = await this.systemTagsResources.addOrUpdateRecord(body);
      await this.resetAllTags();

      return tagId;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'GLOBAL:textErrorAddingTheTag',
        {},
        'There was an error adding the tag'
      ));

      return null;
    }
  }
  async updateRecord<T extends 'Promote'|'Enable'|'Disable'|'ChangeColor'> (
    tag: SystemTags.TagDefinitionForUi,
    action: T
  ): Promise<void> {
    if (action === 'ChangeColor') {
      const adaptedTag = {
        id: tag.id,
        name: tag.name,
        description: tag.description,
        tagBucketId: tag.bucketId,
        color: this.adaptColorForApi(tag.color)
      };
      await this.systemTagsResources.addOrUpdateRecord(adaptedTag);
    } else {
      await this.systemTagsResources.updateRecord(tag.id, action);
    }

    // changing the color does not need to reset the table
    if (action !== 'ChangeColor') {
      await this.resetAllTags();
    }
  }

  async removeRecord (tag: SystemTags.TagDefinitionForUi) {
    await this.systemTagsResources.removeRecord(tag.id);
    await this.resetAllTags();
  }

  async setTags (
    tagType: SystemTags.Buckets,
    entityId: string|number,
    newTags: number[],
    removedTags: number[]
  ) {
    const body = {
      relatedEntityId: entityId,
      tags: newTags.map(tag => ({
        tagId: tag,
        active: true
      })).concat(removedTags.map(tag => ({
        tagId: tag,
        active: false
      })))
    };

    return this.systemTagsResources.setTags(
      tagType,
      body
    );
  }

  async getCurrentTagsForPDF (
    entityId: string|number
  ) {
    const tagType = SystemTags.Buckets.Application;
    let currentTagIds = this.getTagsForRecord(
      tagType,
      entityId
    );
    if (!currentTagIds) {
      await this.fetchTagsForRecord(tagType, entityId);
      currentTagIds = this.getTagsForRecord(
        tagType,
        entityId
      );
    }

    return currentTagIds.map((id) => {
      return this.allTags.find(tag => tag.id === id)?.name;
    }).filter((name) => !!name);
  }


  async getTagsBulk (
    relatedEntityIds: number[],
    tagBucket: SystemTags.Buckets
  ) {
    const tags = await this.systemTagsResources.getTagsBulk(relatedEntityIds, tagBucket);
    const relatedEntitiesTagMap: Record<number, string[]> = {};
    relatedEntityIds.forEach((entityId) => {
      const relatedEntityTagsIds = tags.filter((tag) => {
        return tag.relatedEntityId === entityId;
      }).map((tag) => {
        return tag.tag.id;
      });
      relatedEntitiesTagMap[entityId] = relatedEntityTagsIds.map((id) => {
        return this.allTags.find(tag => tag.id === id)?.name;
      }).filter((name) => !!name);
    });

    return relatedEntitiesTagMap;
  }


  async handleBulkAddTags (
    tagIds: number[],
    relatedEntityIds: number[],
    tagBucket: SystemTags.Buckets
  ) {
    try {
      await this.systemTagsResources.bulkAddTags(
        tagIds,
        relatedEntityIds,
        tagBucket
      );
      this.notifier.success(this.i18n.translate(
        'GLOBAL:textSuccessSettingTags',
        {},
        'Successfully added the selected tags'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'GLOBAL:textErrorSettingTags',
        {},
        'There was an error setting the tags'
      ));
    }
  }

  /**
   * Gets and Downloads the Template Used for Importing Tags
   */
  getTemplateForDownload () {
    const csvOptions = this.getExportData();
    if (csvOptions.length > 0) {
      const csv = parse.unparse(csvOptions);
      this.fileService.downloadCSV(csv);
    } else {
      // Download blank template
      const input = 'name,bucket,color,inactive';

      this.fileService.downloadString(
        input,
        'text/csv',
        'template.csv'
      );
    }
  }

  /**
   * Get all the tags for export
   *
   * @returns all tags
   */
  getExportData (): SystemTags.TagImportForUi[] {
    return this.allTags.map((tag) => {
      return {
        id: tag.id,
        name: tag.name,
        inactive: !tag.enabled,
        bucket: tag.bucketId,
        color: tag.color
      };
    });
  }

  /**
   * Export All Tags
   */
  exportTags () {
    const tags = this.getExportData();
    const csv = parse.unparse(tags);
    this.fileService.downloadCSV(csv);
  }

  /**
   * Validate the required rows are present in the Import file
   *
   * @param contents: Rows from the import file
   * @returns errors related to required rows missing from the import file
   */
  validateRequiredRows (contents: SystemTags.TagImportForUi[]) {
    const mappedOptions: Record<string, SystemTags.TagImportForUi> = {};
    contents.forEach((option) => {
      if (option.id) {
        mappedOptions[option.id] = option;
      }
    });
    const missingTags: string[] = [];
    this.allTags.forEach((tag) => {
      if (!mappedOptions[tag.id]) {
        missingTags.push(tag.name);
      }
    });
    if (missingTags.length > 0) {
      let errorMessage = this.i18n.translate(
        'common:textAllExistingTagsMustBeImportedAlert2',
        {},
        'All existing tags must be included in the import. The following tag(s) are missing. If you no longer wish for these options to be active, just set inactive to "true".'
      ) + ` <ul>`;
      missingTags.forEach((key) => {
        errorMessage = errorMessage + '<li>' + key + '</li>';
      });

      errorMessage = errorMessage + '</ul>';

      return [{
        i18nKey: '',
        defaultValue: errorMessage
      }];
    }

    return [];
  }

  /**
   * Do Tag Import
   *
   * @param tagsForApi: Tags to Import
   */
  async doImportTags (tagsForApi: SystemTags.TagImportForApi[]) {
    await this.systemTagsResources.importTags(tagsForApi);
  }

  /**
   * Adapt and Filter Tags for Import
   *
   * @param tags: Tags to Adapt
   * @returns the adapted and filtered tags for import
   */
  adaptTagImportForApi (tags: SystemTags.TagImportForUi[]) {
    const tagsForApi: SystemTags.TagImportForApi[] = [];

    tags.forEach((tag) => {
      const existingTag = this.allTagsMap[tag.id];
      const adapted = this.adaptTagForApi(tag, true) as SystemTags.TagImportForApi;
      if (!!existingTag) {
        // Only include if changed
        const changed = this.didExistingTagChange(tag, existingTag);
        if (changed) {
          tagsForApi.push(adapted);
        }
      } else {
        // only include new tags that are marked as active
        if (!tag.inactive) {
          tagsForApi.push(adapted);
        }
      }
    });


    return tagsForApi;
  }

  /**
   * Did the Tag change on Import?
   *
   * @param tag: Tag to check if changed
   * @param existingTag: Existing Tag to check against
   * @returns if the tag was changed during import
   */
  didExistingTagChange (tag: SystemTags.TagImportForUi, existingTag: SystemTags.TagDefinitionForUi) {
    return tag.bucket !== existingTag.bucketId ||
      tag.color !== existingTag.color ||
      tag.inactive !== !existingTag.enabled ||
      tag.name !== existingTag.name;
  }

  /**
   * Handle Import Tags Process
   *
   * @param tags: Tags to Import
   * @returns if the import was successful
   */
  async handleImportTags (tags: SystemTags.TagImportForUi[]) {
    const tagsForApi = this.adaptTagImportForApi(tags);
    if (tagsForApi.length > 0) {
      const {
        passed
      } = await this.confirmAndTakeAction.genericTakeAction(
        () => this.doImportTags(tagsForApi),
        this.i18n.translate(
          'common:textSuccessImportTags',
          {},
          'Successfully imported tags'
        ),
        this.i18n.translate(
          'common:textErrorImportingTags',
          {},
          'There was an error importing tags'
        )
      );

      if (passed) {
        await this.resetAllTags();
      }
    } else {
      this.notifier.warning(this.i18n.translate(
        'common:textNoChangesFound',
        {},
        'No changes found'
      ));
    }
  }

  /**
   * Validate the Bucket Type on Tag Import
   *
   * @param _value: value to validate
   * @param extras: validation extras
   * @returns if error - error object, otherwise empty array
   */
  validateBucketType (bucketType: SystemTags.Buckets, extras: ValidatorExtras<any, any, any>): ValidatorReturn {
    const tagId = extras.ent.id;
    const isExistingRecord = !!tagId;
    if (isExistingRecord) {
      const foundTag = this.allTags.find((tag) => +tag.id === +tagId);
      if (!!foundTag) {
        // For existing tags, bucket cannot be changed unless it's changed to Global
        // Otherwise existing records tied to that tag would be invalid
        if (
          bucketType !== SystemTags.Buckets.Global &&
          foundTag.bucketId !== bucketType
        ) {
          return {
            i18nKey: 'GLOBAL:textCannotChangeBucketTypeOnExistingRecord',
            defaultValue: 'Cannot change bucket type on an existing record'
          };
        }
      }
    }

    return [];
  }
}

export const CannotChangeBuckeTypeForExistingValidator = ServiceValidator(
  SystemTagsService,
  'validateBucketType'
);

const ValidateRequiredRows = createTopLevelValidator<SystemTags.TagImportForUi, void, any>((_) => (
  records: SystemTags.TagImportForUi[], extras: BaseValidatorExtras<any, any>) => {
  const { injector } = extras;

  const systemTagService = injector.get(SystemTagsService);

  return systemTagService.validateRequiredRows(records);
});

@ValidateRequiredRows()
export class SystemTagImport {
  @IsNumber()
  @Description({
    i18nKey: 'common:textIdOfTheTagNotNeededNew2',
    defaultValue: 'ID of the tag. Not needed for new records.'
  })
  'id': number;

  @Required()
  @Unique()
  @Description({
    i18nKey: 'common:textNameOfTheTag',
    defaultValue: 'Name of the tag'
  })
  'name': string;

  @Required()
  @CannotChangeBuckeTypeForExistingValidator()
  @IsOneOf([
    SystemTags.Buckets.Global,
    SystemTags.Buckets.Application,
    SystemTags.Buckets.Nomination,
    SystemTags.Buckets.NonprofitProfile,
    SystemTags.Buckets.Payment,
    SystemTags.Buckets.Applicant,
    SystemTags.Buckets.Budget
  ], {
    message: {
      i18nKey: 'common:textPleaseEnterValidBucket',
      defaultValue: 'Please enter a valid bucket.',
      enumMapping: SystemTags.BucketEnumMapping
    }
  })
  @Description({
    i18nKey: 'common:textBucketTypeHelper',
    defaultValue: 'Bucket type',
    enumMapping: SystemTags.BucketEnumMapping
  })
  @Transform((val: string) => {
    if (!val) {
      return SystemTags.Buckets.Global;
    }

    return val;
  })
  'bucket': SystemTags.Buckets;

  @Required()
  @IsOneOf([
    SystemTags.TagColor.Blue,
    SystemTags.TagColor.Green,
    SystemTags.TagColor.Red,
    SystemTags.TagColor.Yellow
  ], {
    message: {
      i18nKey: 'common:textPleaseEnterValidTagColor',
      defaultValue: 'Please enter a valid tag color.',
      enumMapping: SystemTags.ColorEnumMapping
    }
  })
  @Description({
    i18nKey: 'tags:lblTagColor',
    defaultValue: 'Tag color',
    enumMapping: SystemTags.ColorEnumMapping
  })
  @Transform((val: string) => {
    if (!val) {
      return SystemTags.TagColor.Yellow;
    }

    return val;
  })
  'color': SystemTags.TagColor;

  @CSVBooleanFactory(false)()
  @Required()
  @Description({
    i18nKey: 'common:textIfTheTagShouldBeMarkedAsInactive',
    defaultValue: 'If the tag should be marked as inactive'
  })
  'inactive': boolean;
}

