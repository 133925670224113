import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { FormAudience, FormTypes } from '@features/configure-forms/form.typing';
import { FormFieldCategoryService } from '@features/form-fields/services/form-field-category.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { AdHocReportingMappingService } from '@features/reporting/services/ad-hoc-reporting-mapping.service';
import { TopLevelFilter, TypeToken, ValueComparisonService } from '@yourcause/common';
import { AccordionStyle } from '@yourcause/common/accordion';
import { CallMaker, CallMakerFactory } from '@yourcause/common/call-maker';
import { FormError } from '@yourcause/common/core-forms';
import { I18nService } from '@yourcause/common/i18n';
import { ArrayHelpersService, YCTwoWayEmitter } from '@yourcause/common/utils';
import { QuickAddService } from '../services/quick-add.service';
export interface ExistingRefField {
  key: string;
  type: string;
  referenceFieldTableId: number;
}

@Component({
  selector: 'gc-quick-add-fields',
  templateUrl: './quick-add-fields.component.html',
  styleUrls: ['./quick-add-fields.component.scss']
})
export class QuickAddFieldsComponent implements OnInit, OnChanges {
  @Input() quickAddType = ReferenceFieldsUI.QuickAddType.FORM_BUILDER;
  @Input() formId: number;
  @Input() existingRefFields: ExistingRefField[] = [];
  @Input() numberOfExistingFields: number;
  @Input() numberOfFieldsAllowed: number;
  @Input() formType: FormTypes;
  @Input() formAudience: FormAudience;
  @Input() componentsToAdd: ReferenceFieldsUI.QuickAddField[] = [];
  @Input() newFieldAddedTrigger: YCTwoWayEmitter<void, ReferenceFieldsUI.QuickAddField>;
  @Output() onComponentsChange = new EventEmitter<ReferenceFieldsUI.QuickAddField[]>();
  @Output() onValidityChange = new EventEmitter<boolean>();

  topLevelFilters: TopLevelFilter[] = [
    new TopLevelFilter(
      'text',
      '',
      '',
      this.i18n.translate(
        'common:textSearchByFieldNameOrKey',
        {},
        'Search by field name or key'
      ),
      {
        noBorderRadiusOnInput: true
      },
      undefined,
      [{
        column: 'label',
        filterType: 'cn'
      }, {
        column: 'key',
        filterType: 'cn'
      }, {
        column: 'name',
        filterType: 'cn'
      }]
    ),
    new TopLevelFilter(
      'typeaheadMultiEquals',
      'categoryId',
      [],
      this.i18n.translate('common:textFilterByCategory', {}, 'Filter by category'),
      {
        selectOptions: this.formFieldCategoryService.categoryOptionsWithOther,
        noBorderRadiusOnInput: true
      },
      undefined,
      undefined,
      true
    )
  ];
  AccordionStyle = AccordionStyle;
  componentBuckets: ReferenceFieldsUI.QuickAddBucket[];
  // this is the original set of component buckets and should always include all components available to add
  allComponents: ReferenceFieldsUI.QuickAddField[] = [];
  accordionGroups: (ReferenceFieldsUI.QuickAddBucket[])[];
  filteredComponents: ReferenceFieldsUI.QuickAddField[] = [];
  invalidDependentPicklists: ReferenceFieldsUI.InvalidDependentPicklist[] = [];
  isBBGM = this.clientSettingsService.isBBGM;
  refTypesMap = this.formFieldHelperService.getReferenceFieldTypeToLabelMap();
  $refType = new TypeToken<ReferenceFieldsUI.ReferenceFieldTypes>();
  $bucketType = new TypeToken<ReferenceFieldsUI.QuickAddFieldBucket>();
  BucketType = ReferenceFieldsUI.QuickAddFieldBucket;
  QuickAddType = ReferenceFieldsUI.QuickAddType;
  openMapByBucketType: Record<ReferenceFieldsUI.QuickAddFieldBucket, Record<number, boolean>> = {
    [ReferenceFieldsUI.QuickAddFieldBucket.ALL]: {},
    [ReferenceFieldsUI.QuickAddFieldBucket.APPLICANT]: {},
    [ReferenceFieldsUI.QuickAddFieldBucket.MANAGER]: {},
    [ReferenceFieldsUI.QuickAddFieldBucket.STANDARD]: {},
    [ReferenceFieldsUI.QuickAddFieldBucket.REPORT]: {}
  };
  FormAudience = FormAudience;
  filterResultsCallmaker: CallMaker<void, boolean> = CallMakerFactory.create(
    () => this.doFiltering(),
    500,
    false,
    true
  );
  tableKey = 'QUICK_ADD_TABLE';
  afterInit = false;
  labelColumnHeader = 'common:hdrLabel';
  noRecordsTableMessage = this.i18n.translate(
    'common:textQuickAddNoRecordsMessage',
    {},
    'Search or browse form fields to quickly add them to your form.'
  );
  noFieldsAvailableMessage = this.i18n.translate(
    'common:textNoFieldsAvailableToAdd',
    {},
    'No fields available to add'
  );
  hasFiltered = false;
  errorMap: Record<string, FormError> = {};

  constructor (
    private i18n: I18nService,
    private clientSettingsService: ClientSettingsService,
    private quickAddService: QuickAddService,
    private formFieldHelperService: FormFieldHelperService,
    private formFieldCategoryService: FormFieldCategoryService,
    private arrayHelper: ArrayHelpersService,
    private valueComparisonService: ValueComparisonService,
    private adHocMappingService: AdHocReportingMappingService
  ) { }

  ngOnInit () {
    this.setHelpers();
    this.prepComponentBuckets();
    this.updateValidity(true);
    this.registerTrigger();
    this.afterInit = true;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (this.afterInit) {
      if (changes.formId) {
        this.prepComponentBuckets();
      }
    }
  }

  registerTrigger () {
    if (this.newFieldAddedTrigger) {
      this.newFieldAddedTrigger.registerAction((newField) => {
        this.prepComponentBuckets();
        this.handleAddComponent(newField);
      });
    }
  }

  setHelpers () {
    if (this.quickAddType === ReferenceFieldsUI.QuickAddType.AD_HOC) {
      this.labelColumnHeader = 'reporting:lblColumnHeader';
      this.noRecordsTableMessage = this.i18n.translate(
        'common:textQuickAddNoRecordsMessageReporting',
        {},
        'Search or browse form fields to quickly add them to your report.'
      );
    }
  }

  onTopLevelFilterChange () {
    const textFilter = this.topLevelFilters[0];
    const categoryFilter = this.topLevelFilters[1];
    this.hasFiltered = !!textFilter.value || categoryFilter.value.length > 0;
    this.filterResultsCallmaker.invoke(() => this.topLevelFilters);
  }

  async doFiltering () {
    const filteredComponents = this.valueComparisonService.filterTopLevelFilters(
      this.topLevelFilters,
      this.allComponents,
      false,
      []
    );
    this.filteredComponents = this.arrayHelper.sort(filteredComponents, 'label');

    return true;
  }

  onLabelChange (label: string, key: string) {
    const error = this.checkForLabelErrors(label);
    if (!!error) {
      this.errorMap = {
        ...this.errorMap,
        [key]: error
      };
    } else if (!!this.errorMap[key]) {
      // If old error exists and it's now valid, clear the map
      delete this.errorMap[key];
    }
    this.updateValidity(false);
  }

  checkForLabelErrors (label: string): FormError {
    const missingLabel = !label;
    if (missingLabel) {
      return {
        i18nKey: 'common:textThisInputIsRequired',
        defaultValue: 'This input is required',
        value: null,
        errorMessage: ''
      };
    } else {
      if (this.quickAddType === ReferenceFieldsUI.QuickAddType.AD_HOC) {
        // Max of 200 characters for Ad Hoc
        // And Column Name Validation
        const isColNameValid = this.adHocMappingService.isColumnHeaderValid(label);
        if (!isColNameValid) {
          return {
            i18nKey: 'common:textCsvColumnCharactersNotAllowed',
            defaultValue: 'Cannot Contain: &<> or begin with one of =@{}<',
            value: null,
            errorMessage: ''
          };
        } else if (label.length > 200) {
          return {
            i18nKey: 'common:textCannotBeMoreThanMaxChars',
            defaultValue: 'Cannot be more than __max__ characters',
            value: null,
            context: {
              max: '200'
            },
            errorMessage: ''
          };
        }
      }
    }

    return null;
  }

  updateValidity (checkForLabelErrors: boolean) {
    const tooManyComponents = (this.componentsToAdd.length + this.numberOfExistingFields) > this.numberOfFieldsAllowed;
    const noComponentsToAdd = this.componentsToAdd.length <= 0;
    let errorMap: Record<string, FormError> = {};
    if (!!checkForLabelErrors) {
      this.componentsToAdd.forEach((comp) => {
        const error = this.checkForLabelErrors(comp.label);
        if (!!error) {
          errorMap[comp.key]= error;
        }
      });
      this.errorMap = errorMap;
    }
    const hasErrors = Object.keys(this.errorMap).length > 0;

    const invalid = tooManyComponents ||
      noComponentsToAdd ||
      hasErrors ||
      this.invalidDependentPicklists.length > 0;
    this.onValidityChange.emit(!invalid);
  }

  setComponentsToAdd (componentsToAdd: ReferenceFieldsUI.QuickAddField[]) {
    this.componentsToAdd = componentsToAdd;
    this.onComponentsChange.emit(componentsToAdd);
  }

  handleRemove (index: number)  {
    const componentsToAdd = [
      ...this.componentsToAdd.slice(0, index),
      ...this.componentsToAdd.slice(index + 1)
    ];
    this.setComponentsToAdd(componentsToAdd);
  }

  handleRemoveAction (
    field: ReferenceFieldsUI.QuickAddField,
    index: number
  ) {
    this.handleRemove(index);
    this.handlePicklistValidity(undefined, field);
    this.updateComponentBuckets();
  }

  handleAddComponent (field: ReferenceFieldsUI.QuickAddField) {
    // Make sure component hasn't already been added (double clicking "+")
    const exists = this.quickAddService.checkIfCompAlreadyExistsInColumnsToAdd(
      this.componentsToAdd,
      field.key,
      field.referenceFieldTableId
    );
    if (!exists) {
      const componentsToAdd = [
        ...this.componentsToAdd,
        field
      ];
      this.setComponentsToAdd(componentsToAdd);
      this.handlePicklistValidity(field);
      this.updateComponentBuckets();
      setTimeout(() => {
        const scrollBox = document.getElementById(this.tableKey + '_scroll');
        if (!!scrollBox) {
          scrollBox.scrollTop = scrollBox.scrollHeight;
        }
      });
    }
  }

  handlePicklistValidity (
    fieldToAdd?: ReferenceFieldsUI.QuickAddField,
    fieldToRemove?: ReferenceFieldsUI.QuickAddField
  ) {
    if (this.quickAddType === ReferenceFieldsUI.QuickAddType.FORM_BUILDER) {
      this.invalidDependentPicklists = this.quickAddService.handleDependentPicklistValidityForQuickAdd(
        this.componentsToAdd,
        this.invalidDependentPicklists,
        fieldToAdd,
        fieldToRemove
      );
    }
  }

  updateComponentBuckets () {
    this.prepComponentBuckets();
    if (this.hasFiltered) {
      this.filterResultsCallmaker.invoke(() => this.topLevelFilters, true);
    }
    this.updateValidity(true);
  }

  prepComponentBuckets () {
    const accordionGroups = this.quickAddService.getAccordionGroups(
      this.formId,
      this.existingRefFields,
      this.componentsToAdd,
      this.quickAddType,
      this.formType
    );
    const accordionsMinusAll = accordionGroups.filter((group) => {
      return group[0].type !== ReferenceFieldsUI.QuickAddFieldBucket.ALL;
    });
    this.accordionGroups = accordionsMinusAll;
    this.allComponents = accordionGroups.find((group) => {
      return group[0].type === ReferenceFieldsUI.QuickAddFieldBucket.ALL;
    })[0].componentGroups[0].components;
  }

  isOpenChange (
    isOpenMap: Record<string, boolean>,
    bucketType: ReferenceFieldsUI.QuickAddFieldBucket
  ) {
    this.openMapByBucketType = {
      ...this.openMapByBucketType,
      [bucketType]: isOpenMap
    };
  }

}
