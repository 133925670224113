import { AudienceMember } from '@features/audience/audience.typing';
import { GCDashboards } from '@features/dashboards/dashboards.typing';
import { NominationFormObject } from '@features/forms/services/component-helper/component-helper.service';
import { StartFromReportTemplate } from '@features/reporting/pages/manage-ad-hoc-report-modal/manage-ad-hoc-report-modal.component';
import { AdHocReportingDefinitions, RelatedObjectNames, RootObjectNames } from '@features/reporting/services/ad-hoc-reporting-definitions.service';
import { FilterColumn, FilterTypes, TableColumnDirective } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { DaysOfWeek, Frequency } from '@yourcause/common/date';
import { TableDataDownloadFormat } from '@yourcause/common/files';
import { AdHocReportingAPI } from '../api/ad-hoc-reporting.typing';
import { ReferenceFieldAPI } from '../api/reference-fields.typing';
import { UserInfo } from '../client-user.typing';
import { ReferenceFieldsUI } from './reference-fields.typing';

export namespace AdHocReportingUI {
  export interface FormComponentSummary extends AdHocReportingAPI.FormComponentSummary {
    referenceFieldKey?: string;
    referenceFieldTableId?: number;
  }
  export enum Usage {
    AD_HOC_BUILDER = 1,
    DASHBOARDS = 2,
    TOKENS = 3,
    REPORT_FIELD = 4,
    AD_HOC_QUICK_ADD = 5
  }

  export interface BaseColumnDefinition {
    i18nKey: string;
    display: string;
    column: string;
    class?: string;
    parentBucketName?: string;
    parentBucket?: string;
    isReferenceField?: boolean;
    isStandardField?: boolean;
    referenceFieldTableId?: number;
    parentTableDisplay?: string;
    supportsGrouping?: boolean;
    noSubGrouping?: boolean;
    supportsMinMaxSum?: boolean;
    defaultI18nKey?: string;
    defaultDisplay?: string;
    dashboardOnly?: boolean;
    dashboardAliasKey?: string;
    dashboardAliasDisplay?: string;
    noFiltering?: boolean;
    formIds?: number[]; // for form field categories to know which forms they come from
    canBeUsedAsReportField?: boolean;
    canBeUsedForRecordSummary?: boolean;
    customParentBucket?: RootObjectNames|RelatedObjectNames;
    notApplicableToNominations?: boolean;
  }

  export type BasePaginatedReportResponse<T, S extends keyof T> = {
    [P in S]: {
      [x: string]: string;
    };
  };

  export interface ReferenceFieldResponse {
    referenceFields: ReferenceFieldAPI.ApplicationRefFieldResponse[];
  }

  export interface ReferenceFieldResponseMap {
    [refFieldKey: string]: ReferenceFieldAPI.ApplicationRefFieldResponse;
  }

  export type PaginatedReportResponse<S, P extends keyof S> = BasePaginatedReportResponse<S, P> & ReferenceFieldResponse;

  export type PaginatedReportFieldResponse<S, P extends keyof S> = PaginatedReportResponse<S, P> & NominationFormResponse;


  export interface NominationFormResponse {
    [NominationFormObject]?: ReferenceFieldsUI.RefResponseMap;
    originalNominationResponse?: ReferenceFieldAPI.ApplicationRefFieldResponse[];
  }

  export type PaginatedReportResponseWithRefFieldMap<S, P extends keyof S> = PaginatedReportResponse<S, P>
    &{
      referenceFieldValueMap: Record<string, string>;
      referenceFieldDetailMap: ReferenceFieldResponseMap;
    };

  export type ReportResponseRow = PaginatedReportResponse<
    AdHocReportingDefinitions, RootObjectNames|RelatedObjectNames
  >;
  export type AdaptedReportResponseRow = PaginatedReportResponseWithRefFieldMap<
    AdHocReportingDefinitions, RootObjectNames|RelatedObjectNames
  >;
  export type ReportFieldResponseRow = PaginatedReportFieldResponse<
    AdHocReportingDefinitions, RootObjectNames|RelatedObjectNames
  >;
  export interface DefaultColumnDefinition extends BaseColumnDefinition {
    type: Exclude<FilterTypes, 'typeaheadSingleEquals'|'number'|'date'|'multi-list'|'currency'>;
  }

  export interface FileColumnDefinition extends BaseColumnDefinition {
    type: 'text';
    format: 'file';
  }

  export interface SelectColumnDefinition extends BaseColumnDefinition {
    type: 'typeaheadSingleEquals'|'multiValueList'|'list';
    format: 'label'|'value';
    filterOptions: TypeaheadSelectOption<any>[];
  }

  export interface TypeaheadSelectColumnDefinition extends BaseColumnDefinition {
    type: 'multi-list'|'multiListFuzzyText';
    format: 'label'|'value';
    filterOptions: TypeaheadSelectOption<any>[];
  }

  export type NumberFormats = 'number'|'decimal'|'id'|'percent'|'wholeNumberPercent';
  export interface NumberColumnDefinition extends BaseColumnDefinition {
    type: 'number';
    format: NumberFormats;
  }

  export interface CurrencyColumnDefinition extends BaseColumnDefinition {
    type: 'currency';
    formatSource?: string;
    format?: 'otherColumn'|'text';
  }

  export interface DateColumnDefinition extends BaseColumnDefinition {
    type: 'date';
    format: 'datetime'|'date';
  }

  export type ColumnDefinition = SelectColumnDefinition|NumberColumnDefinition|DefaultColumnDefinition|DateColumnDefinition|TypeaheadSelectColumnDefinition|CurrencyColumnDefinition|FileColumnDefinition;
  export interface ColumnImplementation {
    definition: ColumnDefinition;
    sortType: AdHocReportingAPI.SortTypes;
    filterColumn: FilterColumn<any>;
    visibleInReport: boolean;
    columnNameOverride: string;
    tableColumn?: TableColumnDirective<any>;
  }

  export interface BaseObject {
    i18nKey: string;
    display: string;
    property: string;
    columns: ColumnDefinition[];
    isRootObject: boolean;
    tokenInsertOnly?: boolean; // Only show this node when using token insert
  }

  export interface RootObject<T = string> extends BaseObject {
    type: AdHocReportingAPI.AdHocReportModelType;
    editEndpoint: string;
    readOnlyEndpoint: string;
    chartEndpoint: string;
    supportsSummaryWidgets: boolean;
    previewChartEndpoint: string;
    isRootObject: boolean;
    relatedObjects: T[];
    omitRootObject: boolean;
    recordOptions?: TypeaheadSelectOption[];
    noFormLogic?: boolean; // If the report type requires no form logic, like budget / fs
    additionalDashboardProperties?: Record<string, string&keyof GCDashboards.WidgetConfig>; /* values to add to the request, puts the key of the object and extracts the value (using the key of the widget config) */
  }

  export interface RelatedObject extends BaseObject {
    isRootObject: boolean;
  }

  export interface ColumnBucket {
    i18nKey: string;
    display: string;
    property: string;
    columns: ColumnImplementation[];
    allColumns: ColumnImplementation[];
  }


  export interface ManageModalFormData<T> {
    object: T;
    reportName: string;
    reportDescription: string;
    program: number;
    primaryFormId?: number;
    referenceFieldTableKey: string;
    forms: number[];
    startFromTemplate: StartFromReportTemplate;
  }

  export enum AdHocBuilderComponentMode {
    VIEW = 1,
    EDIT = 2,
    SCAFFOLD = 3
  }

  export interface AdHocReportSchedule {
    scheduleId: number;
    sftpId: number;
    isDataFeed: boolean;
    audienceId: number;
    showMaskedData: boolean;
    users: AudienceMember[];
    frequency: Frequency;
    hours: number; // 0 - 23
    week: number; // 1 - 12
    month: number; // 1 - 12
    dayOfMonth: number; // 1 - 28;
    dayOfWeek: DaysOfWeek; // 1 - 7
    isAM: boolean;
    expirationHours: number;
    exportFileTypeId: TableDataDownloadFormat;
    includeBom: boolean;
    delimiter: string;
    createdBy: UserInfo;
    updatedBy: UserInfo;
    createdDate: string;
    updatedDate: string;
  }

  // for formatting tooltips on graphs
  export interface FormatTooltipValuePayload {
    isPie: boolean;
    isDollarAmount: boolean;
    isPercent: boolean;
    rawValue: number;
    dataIndex: number;
    datasets: GCDashboards.ChartDataSet[];
    count: number;
    showCount: boolean;
    totalCount: number;
    totalValue: number;
    showPercentageOnly: boolean;
  }
  export enum DisplayTypes {
    TextField = 1,
    TextArea = 2,
    Raw = 3
  }

}
