<ng-container *ngIf="formGroup"
  [formGroup]="formGroup">
  <div>
    <yc-form-label
      [label]="label"
      [description]="description"
      [name]="compKey"
      [customIcon]="showFieldChange ? customLabelIcon : ''"
      [customIconTooltip]="showFieldChange ? customLabelIconTooltip : ''"
      [customIconClass]="showFieldChange ? customLabelIconClass : ''">
    </yc-form-label>

    <div class="mb-2 mt-3">
      <!-- Enable Attention? -->
      <yc-checkbox
        name="enableAttention"
        [(value)]="enableAttention"
        [disabled]="isDisabled"
        [optionLabel]="showAttentionFieldLabel"
        (valueChange)="toggleAttentionChange($event)">
      </yc-checkbox>
    </div>
    <!-- Attention input -->
    <div *ngIf="enableAttention">
      <yc-input
        [name]="compKey"
        [disabled]="isDisabled"
        [addRequiredAsterisk]="isRequired"
        [tabIndex]="tabIndex"
        (onChange)="dataChanged($event)">
      </yc-input>
    </div>
  </div>
</ng-container>