import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { BaseApplication } from '@core/typings/application.typing';
import { FormDecisionTypes } from '@features/configure-forms/form.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { TypeSafeFormBuilder, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { BaseFormComponent } from '../../base/base.component';

@Component({
  selector: 'gc-form-decision',
  templateUrl: './form-decision.component.html',
  styleUrls: ['./form-decision.component.scss']
})
export class FormDecisionComponent extends BaseFormComponent<FormDecisionTypes> implements OnInit {
  @Input() truthyValue: string;
  @Input() falsyValue: string;
  @Input() recuseValue: string;
  @Input() allowRecused = false;

  options: TypeaheadSelectOption<FormDecisionTypes>[];

  constructor (
    public formBuilder: TypeSafeFormBuilder,
    private formHelperService: FormHelperService,
    formBuilderService: FormBuilderService,
    public componentHelper: ComponentHelperService,
    @Optional() formGroupDir: FormGroupDirective,
    @Optional() renderer: FormRendererComponent<BaseApplication>
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }

  ngOnInit () {
    super.ngOnInit();
    this.ensureUpToDate();
    this.options = this.formHelperService.getDecisionOptions(
      this.allowRecused,
      this.recuseValue,
      this.truthyValue,
      this.falsyValue
    );
  }

  ensureUpToDate () {
    const decision = this.data as any;
    if (decision === true) {
      this.decisionChange(FormDecisionTypes.Approve);
    } else if (decision === false) {
      this.decisionChange(FormDecisionTypes.Decline);
    }
  }

  decisionChange (value: FormDecisionTypes) {
    this.onValueChange.emit({
      value,
      updateFormGroup: false
    });
  }
}
