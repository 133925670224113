import { FormAnswerValues, FormAudience, FormComponentGroup, FormDefinitionComponent, ReportFieldDataOptions } from '@features/configure-forms/form.typing';
import { SimpleStringMap } from '@yourcause/common';
import { CurrencyValue } from '@yourcause/common/currency';
import { YcFile } from '@yourcause/common/files';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ReferenceFieldAPI } from '../api/reference-fields.typing';
export const STANDARD_FIELDS_CATEGORY_ID = 'STANDARD_FIELDS_CATEGORY_ID';
export const REF_COMPONENT_TYPE_PREFIX = 'referenceFields-';
export const AttributesToMatchForMergeField: (keyof ReferenceFieldAPI.ReferenceFieldBaseModel)[] = [
  'type',
  'formAudience',
  'customDataTableGuid',
  'supportsMultiple',
  'parentReferenceFieldId',
  'isSingleResponse',
  'isEncrypted',
  'isMasked',
  'isTableField',
  'formatType',
  'isRichText'
];

export namespace ReferenceFieldsUI {

  export enum ReferenceFieldTypes {
    TextField = 'TextField',
    TextArea = 'TextArea',
    CustomDataTable = 'CustomDataTable',
    Number = 'Number',
    Checkbox = 'Checkbox',
    Date = 'Date',
    Radio = 'Radio',
    SelectBoxes = 'Selectboxes',
    FileUpload = 'FileUpload',
    ExternalAPI = 'ExternalAPI',
    Aggregate = 'Aggregate',
    Table = 'Table',
    Currency = 'Currency',
    Subset = 'Subset',
    DataPoint = 'DataPoint',
    Address = 'Address'
  }

  export type RefResponseType = (YcFile<File>[]|YcFile<Blob>[])|string|boolean|string[]|SimpleStringMap<boolean>|number|Date|TableResponseRowForUi[]|CurrencyValue|ReferenceFieldAPI.FormFieldAddressResponse;

  export type RefResponseMap = Record<string, FormAnswerValues>;

  export type RefResponseMapForAdapting = Record<string, ReferenceFieldAPI.ApplicationRefFieldResponse>;

  export interface TypeForForm {
    type: ReferenceFieldTypes;
    formAudience: FormAudience;
    name: string;
  }

  export interface DataPointResponseMap {
    [x: string]: DataPointForUI;
  }

  export interface DataPointForUI extends ReferenceFieldsUI.TableFieldForUi {
    value: FormAnswerValues;
  }

  export interface TableFieldForUi extends ReferenceFieldAPI.TableField {
    summarizeData: boolean;
    summarizeLabel: string;
    errors?: {
      [x: string]: {
        i18nKey: string;
        context?: Record<string, string>;
        defaultValue: string;
      };
    };
    referenceField: ReferenceFieldAPI.ReferenceFieldDisplayModel;
  }

  export interface TableFieldForCrudUi extends TableFieldForUi {
    component: FormDefinitionComponent;
    formGroup: FormComponentGroup;
  }

  export interface TableFieldForUiWithField extends TableFieldForUi {
    referenceField: ReferenceFieldAPI.ReferenceFieldDisplayModel;
  }

  export interface TableFieldForCrudModal extends TableFieldForUi {
    value: FormAnswerValues;
    type: ReferenceFieldTypes;
    customDataTableGuid: string;
  }

  export interface ModalReturn {
    field: ReferenceFieldAPI.ReferenceFieldBaseModel;
    tableFields: TableFieldForUi[];
    isSecondarySave: boolean;
  }

  export interface TableRow {
    rowId: number;
    tableFields: TableFieldForCrudModal[];
  }

  export interface TableResponseForUi {
    tableReferenceFieldId: number;
    rows: TableResponseRowForUi[];
  }

  export interface TableResponseRowForUi {
    rowId: number;
    columns: ReferenceFieldAPI.ApplicationRefFieldResponse[];
  }

  export interface TableResponseRowForUiMapped extends TableResponseRowForUi {
    responses: RefResponseMap;
    popover?: PopoverDirective;
  }

  export interface RowToUpdate {
    tableReferenceFieldId: number;
    update: ReferenceFieldAPI.TableChangeValues;
  }

  export interface TableImportRow {
    key: string;
    value: any;
    applicationReferenceFieldTableRow: number; // index
  };

  export interface AdaptRefChangesResponse {
    standardChangeValues: ReferenceFieldAPI.ApplicationRefFieldResponseForApi[];
    tableChangeValues: ReferenceFieldAPI.TableChangeResponse;
    tableChangeMap: ReferenceFieldsUI.RefResponseMap;
  }

  export interface InvalidDependentPicklist {
    fieldWithoutParent: ReferenceFieldAPI.ReferenceFieldDisplayModel;
    parentPicklist: ReferenceFieldAPI.ReferenceFieldDisplayModel;
  }

  export interface FileNeedingUploaded {
    referenceFieldId: number;
    key: string;
    file: YcFile<File>;
  }

  export enum QuickAddType {
    FORM_BUILDER = 1,
    AD_HOC = 2
  }

  export enum QuickAddFieldBucket {
    STANDARD,
    APPLICANT,
    MANAGER,
    REPORT,
    ALL
  }

  export interface QuickAddField {
    key: string;
    type: string;
    name: string;
    label: string;
    categoryId: number;
    bucketName: string;
    isRequired?: boolean;
    hideRequired?: boolean;
    tooltip: string;
    referenceField?: ReferenceFieldAPI.ReferenceFieldDisplayModel;
    referenceFieldTableId?: number;
    reportFieldConfigType?: ReportFieldDataOptions;
  }

  export enum StandardFieldTypes {
    CASH_AMOUNT_REQUESTED = 1,
    ALTERNATE_ADDRES = 2,
    DESIGNATION = 3,
    CASH_AMOUNT_REQUESTED_CURRENCY = 4,
    IN_KIND = 5,
    CARE_OF = 6
  }

  export interface QuickAddBucket {
    name: string;
    type: QuickAddFieldBucket|string;
    componentGroups: QuickAddGroup[];
  }

  export interface QuickAddGroup {
    name: string;
    components: ReferenceFieldsUI.QuickAddField[];
  }

  export enum RefFieldConversionTypes {
    TEXT_TO_NUMBER = 1,
    NUMBER_TO_TEXT = 2,
    TEXT_TO_DATE = 3,
    DATE_TO_TEXT = 4,
    MULTI_TO_SINGLE = 5,
    CURRENCY_TO_NUMBER = 6,
    NUMBER_TO_CURRENCY = 7,
    TO_ENCRYPTED = 8
  }

  export interface FormFieldConversionResponse {
    conversionCurrency: string;
    convert: boolean;
  }

  export interface FormFieldConversionFormGroup {
    conversionCurrency: string;
  }
}
