import { Component, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { BaseApplication } from '@core/typings/application.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { FormRendererComponent } from '@features/forms/form-renderer/form-renderer/form-renderer.component';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { TypeSafeFormBuilder } from '@yourcause/common/core-forms';
import { BaseFormComponent } from '../../base/base.component';

@Component({
  selector: 'gc-form-employee-sso-field',
  templateUrl: './form-employee-sso-field.component.html',
  styleUrls: ['./form-employee-sso-field.component.scss']
})
export class FormEmployeeSSOFieldComponent extends BaseFormComponent<string> {

  constructor (
    formBuilder: TypeSafeFormBuilder,
    formBuilderService: FormBuilderService,
    componentHelper: ComponentHelperService,
    @Optional() formGroupDir: FormGroupDirective,
    @Optional() renderer: FormRendererComponent<BaseApplication>
  ) {
    super(renderer, formGroupDir, formBuilder, formBuilderService, componentHelper);
  }
}
