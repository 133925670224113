<div class="pdf-wrapper">

  <!-- Application details -->
  <div class="full-page detail-page">
    <div class="logo-wrapper">
      <img [src]="logo | gcAddStaticResourceSAS">
    </div>

    <div class="page-header">
      <yc-i18n 
        [key]="isNomination ?
        'breadcrumbs:NominationDetail' :
        'breadcrumbs:ApplicationDetail'"
        [defaultValue]="isNomination ?
          'Nomination Detail' :
          'Application Detail'">
      </yc-i18n>
    </div>

    <!-- Application ID -->
    <div class="detail-section">
      <div class="detail-label">
        <yc-i18n 
          [key]="isNomination ?
            'GLOBAL:textNominationID' :
            'common:textApplicationId'"
          [defaultValue]="isNomination ?
            'Nomination ID' :
            'Application ID'">
        </yc-i18n>
      </div>
      <div class="detail-info">
        {{ applicationId }}
      </div>
    </div>

    <!-- Application Submitted Date -->
    <div class="detail-section">
      <div class="detail-label">
        <yc-i18n key="GLOBAL:textSubmitted"
          defaultValue="Submitted">
        </yc-i18n>
      </div>
      <div class="detail-info">
        <ng-container *ngIf="applicationInfo.submittedDate">
          {{ applicationInfo.submittedDate | ycStartOfDayDateFormatter }}
        </ng-container>
        <ng-container *ngIf="!applicationInfo.submittedDate">
          <yc-i18n
            key="GLOBAL:textNotSubmitted"
            defaultValue="Not submitted">
          </yc-i18n>
        </ng-container>
      </div>
    </div>

    <!-- Application Status -->
    <div class="detail-section">
      <div class="detail-label">
        <yc-i18n key="common:hdrStatus"
          defaultValue="Status">
        </yc-i18n>
      </div>
      <div class="detail-info">
        {{ applicationInfo.status }}
      </div>
    </div>

    <!-- Applicant Info -->
    <div class="detail-section">
      <div class="detail-label">
        <yc-i18n 
          [key]="isNomination ?
            'common:lblNominatorsOpt' :
            'common:lblApplicantsOpt'"
          [defaultValue]="isNomination ?
            'Nominator(s)' :
            'Applicant(s)'">
        </yc-i18n>
      </div>
      <div class="detail-info">
        <ng-container *ngIf="isMasked">
          <yc-i18n
            [key]="isNomination ?
              'GLOBAL:textNominatorInfoIsMasked' :
              'GLOBAL:textApplicantInfoIsMasked'"
            [defaultValue]="isNomination ?
              'Nominator information is masked' :
              'Applicant information is masked'">
          </yc-i18n>
        </ng-container>
        <ng-container *ngIf="!isMasked">
          <div *ngFor="let applicant of applicants">
            <div class="primary">
              {{ applicant.name }} ({{applicant.email}})
            </div>
            <div class="secondary">
              {{ applicant.addressString }}
              <br>
              {{ applicant.phone }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Nominee Info -->
    <div *ngIf="isNomination"
      class="detail-section">
      <div class="detail-label">
        <yc-i18n key="common:lblNominee"
          defaultValue="Nominee">
        </yc-i18n>
      </div>
      <div class="detail-info">
        <ng-container *ngIf="isMasked">
          <yc-i18n *ngIf="!isNomination"
            key="GLOBAL:textApplicantNameIsMasked"
            defaultValue="Applicant name is masked">
          </yc-i18n>
          <yc-i18n *ngIf="isNomination"
            key="GLOBAL:textNominatorNameIsMasked"
            defaultValue="Nominator name is masked">
          </yc-i18n>
        </ng-container>
        <ng-container *ngIf="!isMasked">
          <div class="applicant-block">
            <div class="primary">
              {{ nominee.name }} ({{ nominee.email }})
            </div>
            <div class="secondary">
              {{ nominee.address }}
              <span *ngIf="nominee.address && nominee.phoneNumber">
                | 
              </span>
              {{ nominee.phoneNumber }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Org Info -->
    <div *ngIf="orgInfo?.name"
      class="detail-section">
      <div class="detail-label">
        <yc-i18n
          key="common:lblOrganization"
          defaultValue="Organization">
        </yc-i18n>
      </div>
      <div class="detail-info">
        <div class="primary">
          {{ orgInfo.name }}
        </div>
        <div class="secondary">
          {{ orgInfo.registrationId | ein }}
          <br>
          {{ orgInfo.addressString }}
          <br>
          {{ orgInfo.phone }}
        </div>
      </div>
    </div>
  
    <!-- Program And Cycle -->
    <div class="detail-section">
      <div class="detail-label">
        <yc-i18n 
          key="common:textProgramAndCycle"
          defaultValue="Program and cycle">
        </yc-i18n>
      </div>
      <div class="detail-info">
        <div class="primary">
          {{ applicationInfo.programName }}
        </div>
        <div class="secondary">
          {{ applicationInfo.cycleName }}&nbsp;
        </div>
      </div>
    </div>

    <!-- Workflow Info-->
    <div *ngIf="applicationInfo.workflowLevelName && applicationInfo.workflowName"
      class="detail-section">
      <div class="detail-label">
        <yc-i18n
          key="common:textWorkflowAndCurrentWorkflowLevel"
          defaultValue="Workflow and current workflow level">
        </yc-i18n>
      </div>
      <div class="detail-info">
        <div class="primary">
          {{ applicationInfo.workflowName }}
        </div>
        <div class="secondary">
          {{ applicationInfo.workflowLevelName }}
        </div>
      </div>
    </div>

    <div class="detail-section">
      <div class="detail-label">
        <yc-i18n
          key="GLOBAL:lblTags"
          defaultValue="Tags">
        </yc-i18n>
      </div>
      <div class="detail-info">
        <ng-container *ngIf="applicationInfo.tags?.length">
          {{ applicationInfo.tags | ycJoinArray: ', ' }}
        </ng-container>
        <ng-container *ngIf="!applicationInfo.tags ||
          applicationInfo.tags?.length === 0">
          <yc-i18n 
            key="common:textNoTags"
            defaultValue="No tags">
          </yc-i18n>
        </ng-container>
      </div>
    </div>

    <!-- Forms -->
    <div class="detail-section">
      <div class="detail-label">
        <yc-i18n
          key="common:textForms"
          defaultValue="Forms">
        </yc-i18n>
      </div>
      <div class="detail-info">
        <ng-container *ngFor="let form of forms; let last = last;">
          <a [href]="'#' + form.applicationFormId + '-form'">
            {{ form.formName }}
            <ng-container *ngIf="form.workflowLevelName">
              ({{ form.workflowLevelName }})
            </ng-container>
            <ng-container *ngIf="form.formSubmittedBy">
              ({{ form.formSubmittedBy }})
            </ng-container>
          </a>
          <br *ngIf="!last">  
        </ng-container>
      </div>
    </div>
  </div>

  <!-- Awards -->
  <gc-award-pdf-page
    [awards]="awards"
    [isArchived]="isArchived"
    [isForApplicantPortal]="isForApplicantPortal">
  </gc-award-pdf-page>

  <!-- Form response  -->
  <div *ngFor="let form of forms"
    class="full-page form-page"
    [id]="form.applicationFormId + '-form'">
    <!-- Form Header -->
    <div class="page-header">
      <div class="d-flex flex-column">
        <div>
          {{ form.formHeader }}
        </div>
        <div *ngIf="form.formSubmittedOn &&
          form.formStatus === FormStatuses.Submitted"
          class="sub-header">
          <yc-i18n *ngIf="form.formSubmittedBy"
            key="common:textFormSubmittedByOnDynamic"
            defaultValue="Submitted by __submittedBy__ on __date__"
            [context]="{
              submittedBy: form.formSubmittedBy,
              date: form.formSubmittedOn | ycStartOfDayDateFormatter
            }">
          </yc-i18n>
          <yc-i18n *ngIf="!form.formSubmittedBy"
            key="GLOBAL:textSubmittedOnDynamic"
            [context]="{
              date: form.formSubmittedOn | ycStartOfDayDateFormatter
            }"
            defaultValue="Submitted on __date__">
          </yc-i18n>
        </div>
      </div>
      <div class="d-flex flex-column right-side-header">
        <div>
          <ng-container *ngIf="orgInfo?.name">
            {{ orgInfo.name }}
          </ng-container>
          <ng-container *ngIf="!orgInfo?.name">
            <ng-container *ngIf="isMasked">
              <yc-i18n *ngIf="!isNomination"
                key="GLOBAL:textApplicantNameIsMasked"
                defaultValue="Applicant name is masked">
              </yc-i18n>
              <yc-i18n *ngIf="isNomination"
                key="GLOBAL:textNominatorNameIsMasked"
                defaultValue="Nominator name is masked">
              </yc-i18n>
            </ng-container>
            <ng-container *ngIf="!isMasked">
              {{ applicants[0].name }}
            </ng-container>
          </ng-container>
        </div>
        <div>
          <ng-container *ngIf="orgInfo?.name">
            {{ orgInfo.registrationId | ein }}
          </ng-container>
          <ng-container *ngIf="!orgInfo?.name && !isMasked">
            {{ applicants[0].email }}
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Components -->
    <div *ngFor="let tab of form.formDefinition; let tabIndex = index;"
      class="tab-wrapper">
      <div *ngIf="form.formDefinition.length > 1"
        class="tab-name">
        {{ tab.tabName }}
      </div>
      <div class="form-response">
        <gc-application-pdf-component
          *ngFor="let component of tab.components"
          [getFormAnswerMap]="getFormAnswerMap"
          [form]="form"
          [component]="component | gcMapComponentForPDF: form.referenceFields">
        </gc-application-pdf-component>
      </div>
    </div>

    <!-- Signature -->
    <div *ngIf="form.signature?.userSignatureUrl"
      class="signature-block">
      <!-- Signature Image -->
      <div class="signature-container">
        <div class="image-container">
          <img
            [src]="form.signature.userSignatureUrl"
            [alt]="'common:textSavedSignature' | ycI18n: 'Saved signature'">
        </div>
      </div>
      <!-- Signed By -->
      <div class="signed-by-block">
        <yc-i18n
          key="common:textSignedByOn"
          [context]="{
            name: form.signature.signedByName,
            date: form.signature.signedDate | ycStartOfDayDateFormatter
          }"
          defaultValue="Signed by __name__ on __date__">
        </yc-i18n>
      </div>
    </div>

  </div>
</div>
